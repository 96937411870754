<script setup lang="ts">
import Button from 'primevue/button';
import { reactive } from 'vue';

const emits = defineEmits(['delete']);

withDefaults(
  defineProps<{ size: 'small' | 'large' }>(),
  { size: undefined },
);

const state = reactive({
  actionedDelete: false,
});
</script>

<template>
  <div>
    <Button
      v-if="!state.actionedDelete"
      @click="state.actionedDelete = true"
      :size="size"
      icon="pi pi-trash"
      text
      severity="danger"
      :label="$t('global.delete')"
    />
    <div v-else class="flex items-center">
      <i class="pi pi-trash mr-3" />
      {{ $t('global.confirm_delete') }}
      <Button
        class="mr-1"
        text
        :size="size"
        :label="$t('global.yes')"
        severity="danger"
        @click="emits('delete')"
      />
      <div>/</div>
      <Button
        text
        :size="size"
        :label="$t('global.no')"
        severity="secondary"
        @click="state.actionedDelete = false"
      />
    </div>
  </div>
</template>
