<script lang="ts" setup>
import Card from 'primevue/card';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import LineChart from '@/Components/Charts/LineChart.vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Utils/DataAggregators';
import Legend from '@/Components/Legend.vue';
import type { DateRange } from '@/Pages/Statistics/Sender/types';
import { format } from 'date-fns';
import { useFormatters } from '@/Utils/Formatting';

const { volume } = defineProps<{
  dateRange: DateRange;
  volume: TimelineStatisticsResource[];
}>();

const { formatDate } = useFormatters()

const dailyStatistics = new DailyStatisticsAggregator(volume).groupedData

const { translate } = useTranslate();

const chartData: ChartData<'line'> = {
  labels: Object.keys(dailyStatistics).map(date => formatDate(new Date(date), 'M-d')),
  datasets: [
    {
      label: translate('exposure-report.volume.legend.passing'),
      data: Object.values(dailyStatistics).map(stats => stats.total_compliant),
      borderColor: getCssVariable('--p-graphs-positive'),
    },
    {
      label: translate('exposure-report.volume.legend.forward'),
      data: Object.values(dailyStatistics).map(stats => stats.total_forwards),
      borderColor: getCssVariable('--p-graphs-fill'),
    },
    {
      label: translate('exposure-report.volume.legend.failing'),
      data: Object.values(dailyStatistics).map(stats => stats.total_failing),
      borderColor: getCssVariable('--p-graphs-negative'),
    },
  ],
};
</script>

<template>
  <Card class="w-full">
    <template #content>
      <div class="flex justify-between items-center">
        <div class="flex flex-col">
          <div
            class="text-lg font-semibold"
            v-text="$t('exposure-report.volume.title')"
          />
          <div class="lowercase">
            {{ format(dateRange.start_date, 'yyyy-LL-dd') }}
            {{ $t('global.to') }}
            {{ format(dateRange.end_date, 'yyyy-LL-dd') }}
          </div>
        </div>

        <div class="flex gap-4 p-3 bg-section-background rounded">
          <Legend
            :icon-size="2"
            :text="$t('exposure-report.volume.legend.passing')"
            :icon-class="['bg-graphs-positive']"
          />
          <Legend
            :icon-size="2"
            :text="$t('exposure-report.volume.legend.failing')"
            :icon-class="['bg-graphs-negative']"
          />
          <Legend
            :icon-size="2"
            :text="$t('exposure-report.volume.legend.forward')"
            :icon-class="['bg-graphs-fill']"
          />
        </div>
      </div>
      <LineChart class="h-80 mt-8" :data="chartData" />
    </template>
  </Card>
</template>
