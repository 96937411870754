<script setup lang="ts">
import {
  type DomainResource, SenderCategory, type SenderIpAddressStatisticsResource,
  SenderReportCategory, type SenderResource,
  type TimelineStatisticsResource,
} from '@/types/types.gen';

import { computed, reactive, watch } from 'vue';
import type { SenderCategoryMenuBarCategory } from '@/Pages/Statistics/Sender/types/menuBars';
import ComplianceReport from '@/Pages/Statistics/Sender/Partials/Reports/ComplianceReport.vue';
import DeliverabilityReport from '@/Pages/Statistics/Sender/Partials/Reports/DeliverabilityReport.vue';
import ReputationReport from '@/Pages/Statistics/Sender/Partials/Reports/ReputationReport.vue';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import { SenderDataAggregator, SenderIpAddressDataAggregator } from '@/Pages/Statistics/Sender/Utils/DataAggregators';

const props = defineProps<{
  senderCategory: SenderCategoryMenuBarCategory;
  reportCategory: SenderReportCategory;
  senders: SenderResource[];
  dailyStatistics: TimelineStatisticsResource[];
  aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[];
  domain: DomainResource;
  customerUuid: string;
}>();

const report = ({
  [SenderReportCategory.COMPLIANCE]: ComplianceReport,
  [SenderReportCategory.DELIVERABILITY]: DeliverabilityReport,
  [SenderReportCategory.REPUTATION]: ReputationReport,
  [SenderReportCategory.THREATS]: null,
})[props.reportCategory];

const filterGroups = (groups: GroupedStatistics[]) => groups
  .filter((group: GroupedStatistics) => props.senderCategory === 'All' || group.category === props.senderCategory)
  .filter((group: GroupedStatistics) => group.total_incidents > 0);
const filterSenders = () => filterGroups(
  props.senders.flatMap(
    sender => new SenderDataAggregator(sender, props.aggregatedTimelineStatistics)
      .groupedData,
  ),
);
const state = reactive({
  groups: filterSenders(),
  senders: props.senders,
});

watch(() => props.senderCategory, () => {
  state.groups = filterSenders();
});
watch(() => props.senders, () => {
  state.senders = props.senders;
});

const ipStatisticsPerSender = computed<Map<string, GroupedStatistics[]>>(() => {
  const groupedData = new SenderIpAddressDataAggregator(
    props.senders,
    props.aggregatedTimelineStatistics,
  ).groupedData;

  groupedData.forEach(
    (ipStatistics, senderUuid) => {
      groupedData.set(
        senderUuid,
        ipStatistics.filter(
          ipRow => props.senderCategory === 'All' || ipRow.category === props.senderCategory,
        ),
      );
    });

  return groupedData;
});

const updateCategory = (data: GroupedStatistics, senderCategory: SenderCategory) => {
  props.aggregatedTimelineStatistics.filter(({ sender_uuid }) => sender_uuid === data.sender_uuid)
    .forEach(statistic => statistic.category = senderCategory);

  state.groups = filterSenders();
};

</script>

<template>
  <Component
    :is="report"
    :groups="state.groups"
    :daily-statistics="dailyStatistics"
    :ip-statistics-per-sender="ipStatisticsPerSender"
    :domain="domain"
    :customer-uuid="customerUuid"
    @update-category="updateCategory"
  />
</template>
