<script setup lang="ts">
import Card from 'primevue/card';
import type { SenderComplianceStatistics } from '@/Pages/Statistics/Sender/types';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import Legend from '@/Components/Legend.vue';

const { data } = defineProps<{
  data: SenderComplianceStatistics;
}>();

const { translate } = useTranslate();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('senders.listing.labels.passing_dmarc'),
    translate('senders.listing.labels.failure'),
    translate('senders.listing.labels.forward'),
  ],
  datasets: [
    {
      data: [
        data.configured,
        data.unconfigured,
        data.partially_configured,
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
        getCssVariable('--p-graphs-warning'),
      ],
      borderWidth: 0,
    },
  ],
};

const total = data.configured + data.partially_configured + data.unconfigured;

const configuredPercentage = (data.configured / total) * 100;

</script>

<template>
  <Card class="w-1/2">
    <template #content>
      <div class="flex justify-between">
        <div class="flex flex-col gap-3">
          <div
            class="font-semibold text-lg"
            v-text="$t('exposure-report.sender-compliance.title')"
          >
          </div>
          <div class="flex flex-col">
            <Legend
              :icon-size="2"
              :text="`${$t('exposure-report.sender-compliance.chart.configured')} (${data.configured})`"
              :icon-class="['bg-graphs-positive']"
            />
            <Legend
              :icon-size="2"
              :text="`${$t('exposure-report.sender-compliance.chart.partially_configured')} (${data.partially_configured})`"
              :icon-class="['bg-graphs-warning']"
            />
            <Legend
              :icon-size="2"
              :text="`${$t('exposure-report.sender-compliance.chart.unconfigured')} (${data.unconfigured})`"
              :icon-class="['bg-graphs-negative']"
            />
          </div>
        </div>

        <DoughnutChart :data="chartData">
          <div class="flex flex-col">
            <div class="text-lg font-semibold">
              {{ configuredPercentage }}%
            </div>
            <div
              class="text-xs"
              v-text="$t('exposure-report.sender-compliance.chart.configured')"
            />
          </div>
        </DoughnutChart>
      </div>

    </template>
  </Card>
</template>
