<script setup lang="ts">
import {
  type TimelineStatisticsResource,
} from '@/types/types.gen';
import Card from 'primevue/card';
import AuthorisedTimelineChart from '@/Pages/Statistics/Dashboard/Charts/AuthorisedTimelineChart.vue';
import { useFormatters } from '@/Utils/Formatting';

const { statistics } = defineProps<{
  statistics: TimelineStatisticsResource[];
}>();

const formatters = useFormatters();

const categorizedVolume = statistics.reduce(
  (acc: { authorised_incidents: number; unauthorised_incidents: number }, item) => {
    acc.authorised_incidents += item.total_authorised_incidents;
    acc.unauthorised_incidents += item.total_unauthorised_incidents;
    return acc;
  },
  {
    authorised_incidents: 0,
    unauthorised_incidents: 0,
  },
);
</script>

<template>
  <Card class="w-full overflow-scroll">
    <template #content>
      <div class="flex justify-between gap-2">
        <div class="flex-row shrink-0">
          <div
            class="text-xl font-semibold mb-1.5"
            v-text="$t('dashboard.authorised-timeline.header')"
          />

          <div class="text-2xl">
            {{ formatters.formatNumber(categorizedVolume.authorised_incidents, '0.0a') }}
            {{ $t('dashboard.authorised-timeline.approved') }}
          </div>

          <div class="mb-1.5 mt-0.5">
            {{ formatters.formatNumber(categorizedVolume.unauthorised_incidents, '0.0a') }}
            {{ $t('dashboard.authorised-timeline.unapproved') }}
          </div>
        </div>

        <AuthorisedTimelineChart
          class="h-32"
          :data="statistics"
        />
      </div>
    </template>
  </Card>
</template>
