import type { HasTotals, StatisticsTotals } from '@/Pages/Statistics/Sender/types';

const totalCompliant = (stat: HasTotals) => stat.total_incidents
  - stat.total_complete_failures;

const totalPassing = (stat: HasTotals) => stat.total_complete_passes
  + stat.total_spf_fails_dkim_passes
  + stat.total_dkim_fails_spf_passes;

const totalFailing = (stat: HasTotals) => stat.total_complete_failures
  + stat.total_overrides_none
  + stat.total_overrides_quarantine
  + stat.total_overrides_reject;

const totalBlocked = (stat: HasTotals) => stat.total_overrides_quarantine
  + stat.total_overrides_reject;

const totalDelivered = (stat: HasTotals) => stat.total_complete_passes
  + stat.total_spf_fails_dkim_passes
  + stat.total_dkim_fails_spf_passes
  + stat.total_overrides_none
  + stat.total_forwards;

const totalDeliveredForward = (stat: HasTotals) => stat.total_complete_passes
  + stat.total_spf_fails_dkim_passes
  + stat.total_dkim_fails_spf_passes
  + stat.total_overrides_none
  + stat.total_forwards;

const totalNotDelivered = (stat: HasTotals) => stat.total_overrides_quarantine
  + stat.total_overrides_reject
  + stat.total_complete_failures;

const createStatisticsTotals = (stat: HasTotals): StatisticsTotals => ({
  total_spf_fails_dkim_passes: stat.total_spf_fails_dkim_passes,
  total_dkim_fails_spf_passes: stat.total_dkim_fails_spf_passes,
  total_permitted: stat.total_overrides_none,
  total_blocked: totalBlocked(stat),
  total_complete_failures: stat.total_complete_failures,
  total_compliant: totalCompliant(stat),
  total_passing: totalPassing(stat),
  total_complete_passes: stat.total_complete_passes,
  total_incidents: stat.total_incidents,
  total_forwards: stat.total_forwards,
  total_failing: totalFailing(stat),
  total_delivered: totalDelivered(stat),
  total_delivered_forward: totalDeliveredForward(stat),
  total_not_delivered: totalNotDelivered(stat),
});

export {
  totalCompliant,
  totalPassing,
  totalFailing,
  totalBlocked,
  totalDelivered,
  totalDeliveredForward,
  totalNotDelivered,
  createStatisticsTotals,
}
