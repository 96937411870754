<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { sumBy } from 'lodash';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import type { DoughnutChartSize } from '@/Components/types/types';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';

const { data } = defineProps<{
  data: StatisticsTotals[];
  size?: DoughnutChartSize;
}>();
const { translate } = useTranslate();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('senders.listing.labels.passing_dmarc'),
    translate('senders.listing.labels.failure'),
    translate('senders.listing.labels.forward'),
  ],
  datasets: [
    {
      data: [
        sumBy(data, 'total_passing'),
        sumBy(data, 'total_failing'),
        sumBy(data, 'total_forwards'),
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
        getCssVariable('--p-graphs-fill'),
      ],
      borderWidth: 0,
    },
  ],
};
</script>
<template>
  <DoughnutChart :size="size" :data="chartData">
    <slot />
  </DoughnutChart>
</template>
