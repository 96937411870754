<script setup lang="ts">
import InnerCard from '@/Components/InnerCard.vue';
import Button from 'primevue/button';
import type { DomainResource } from '@/types/types.gen';
import EnableDkimSettingsModal from '@/Pages/Domains/DkimSettings/Partials/EnableDkimSettingsModal.vue';
import { reactive } from 'vue';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const state = reactive({
  showModal: false,
});
</script>

<template>
  <div>
    <InnerCard class="mb-8">
      <template #content>
        <div
          class="mb-2 text-lg font-bold"
          v-text="$t('dkim-settings.enable.title')"
        />
        <p v-text="$t('dkim-settings.enable.description')" />
      </template>
      <template #footer>
        <Button
          @click="state.showModal = true"
          type="submit"
          class="mt-3"
          severity="secondary"
          :label="$t('dkim-settings.enable.action')"
        />
      </template>
    </InnerCard>

    <div class="mb-2 font-bold" v-text="$t('dkim-settings.info.title')" />
    {{ $t('dkim-settings.info.description') }}

    <EnableDkimSettingsModal
      v-if="state.showModal"
      @hide="state.showModal = false"
      :domain="domain"
    />
  </div>
</template>
