<script setup lang="ts">
import { type AlertsResource, AlertType } from '@/types/types.gen';
import { inject, reactive } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import SelectButton from 'primevue/selectbutton';
import config, { AlertCategory, type AlertConfig } from '@/Pages/Alerts/config';
import { useTranslate } from '@/Utils/Translations/useTranslate';
const { translate } = useTranslate();

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;

const state = reactive({
  activity_type: 'domain',
});

const getCategorizedAlertsConfig = () => {
  const categorizedAlerts: Record<string, AlertConfig[]> = config.reduce((alert, alertConfig) => {
    if (!alert[alertConfig.category] && alertConfig.group === state.activity_type) {
      alert[alertConfig.category] = [];
    }

    if (alertConfig.group === state.activity_type) {
      alert[alertConfig.category].push(alertConfig);
    }

    return alert;
  }, {} as Record<string, AlertConfig[]>);

  return categorizedAlerts;
};

const selectAlert = (type: AlertType) => {
  form.type = type;
  emits('completeStep', 'activity');
};

const getCategory = (category: string) => {
  return translate(`alerts.edit.activity.category.${(<AlertCategory>category)}`);
};

const selectedAlertClass = (type: string) => {
  if (form.type === type) {
    return 'bg-gray-100';
  }

  return 'bg-section-default';
};

</script>
<template>
  <div>
    <div class="font-medium">{{ $t('alerts.edit.activity.heading') }}</div>
    <div class="mt-4">
      <SelectButton
        v-model="state.activity_type"
        :allow-empty="false"
        :options="[
            { label: $t('global.domain'), value: 'domain' },
            { label: $t('global.customer'), value: 'customer' },
        ]"
        optionLabel="label"
        optionValue="value"
      />
    </div>

    <div class="alert-types">
      <div class="my-5" v-for="(alertsConfig, category) in getCategorizedAlertsConfig()" :key="category">
        <div v-if="category !== 'none'" class="text-sm mb-2">{{ getCategory(category) }}</div>

        <div v-for="(alertConfig, key) in alertsConfig" class="flex px-3 py-5 mb-2 rounded justify-between" :class="selectedAlertClass(alertConfig.type)" :key="key">
          <div class="pl-3">
            <div>{{ $t(`alerts.type.options.${alertConfig.type}`) }}</div>
            <div class="text-sm">{{ alertConfig.description }}</div>
          </div>
          <div class="flex flex-col">
            <Button @click="selectAlert(alertConfig.type)" class="m-auto mr-3 font-medium" :label="$t('alerts.edit.buttons.select')" link>{{ $t('alerts.edit.buttons.select') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
