import type { DomainResource } from '@/types/types.gen';
import { router } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import responseCallbacks from '@/Utils/ResponseCallbacks';

export default (
  domain: DomainResource,
  attributes: Partial<DomainResource>,
) => {
  router.patch(
    useRoute()('ui.domains.update', [domain.customer_uuid, domain.uuid]),
    { ...attributes },
    {
      ...responseCallbacks,
      preserveState: true,
    },
  );
};
