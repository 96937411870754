<script setup lang="ts">
import ChartCard from './ChartCard.vue';
import { useFormatters } from '@/Utils/Formatting';
import { sumBy } from 'lodash';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import DeliverableVolumeChart from '@/Pages/Statistics/Sender/Charts/DeliverableVolumeChart.vue';

const { formatNumber } = useFormatters();
const { timelineStatistics } = defineProps<{
  timelineStatistics: TimelineStatisticsResource[];
}>();

const deliverableAmount = sumBy(timelineStatistics, 'total_incidents');
</script>
<template>
  <ChartCard :title="$t('senders.listing.cards.deliverable_volume')">
    <template #lead>
      <h6 class="mt-2 text-2xl leading-none">{{ formatNumber(deliverableAmount) }}</h6>
    </template>
    <template #graph>
      <DeliverableVolumeChart class="h-32" :data="timelineStatistics" />
    </template>
  </ChartCard>
</template>
