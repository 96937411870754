<script setup lang="ts">
import Button from 'primevue/button';
import Drawer from 'primevue/drawer';
import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';
import { type InertiaForm, router } from '@inertiajs/vue3';
import { computed, inject, reactive, ref } from 'vue';
import InputText from 'primevue/inputtext';
import FormGroup from '@/Components/FormGroup.vue';
import Textarea from 'primevue/textarea';
import type { DomainResource } from '@/types/types.gen';
import type { SpfSettingsDirectivesForm } from '@/types/form';
import DeleteConfirmButton from '@/Components/DeleteConfirmButton.vue';
import { useRoute } from 'ziggy-js';

const form: InertiaForm<SpfSettingsDirectivesForm> = inject('directiveForm')!;

const { domain } = defineProps<{
  domain: DomainResource;
  header: string;
}>();

const emits = defineEmits(['submit']);

const state = reactive({
  useOwnDomain: false,
});

const visible = ref(true);

const valueLabel = computed(() => {
  switch (form.mechanism) {
    case 'mx':
      return 'Domain';
    case 'ip4':
    case 'ip6':
      return 'Address';
    case 'exists':
      return 'Macro';
    default:
      return 'Host';
  }
});

const deleteSpfDirective = () => {
  if (form.uuid) {
    router.delete(useRoute()('ui.spf-directives.destroy', {
      customer: domain.customer_uuid,
      domain: domain.uuid,
      spf_directive: form.uuid,
    }));
  }
};

</script>

<template>
  <Drawer
    v-model:visible="visible"
    @hide="
      router.get(
        route('ui.spf-settings.edit', {
          customer: domain.customer_uuid,
          domain: domain.uuid
        })
      )
    "
    :header="header"
    position="right"
    closeIcon="pi pi-sign-out"
    :pt="{
      root: {
        class: '!max-w-2xl !w-full !h-screen rounded'
      }
    }"
  >
    <div class="flex flex-col gap-4">
      <FormGroup
        :label="$t('spf.directives.mechanism.input')"
        :error="form.errors.mechanism"
      >
        <template v-slot:element="slotProps">
          <Select
            v-model="form.mechanism"
            v-bind="slotProps"
            :options="[
              { value: 'a', label: 'A Records' },
              { value: 'mx', label: 'MX Records' },
              { value: 'include', label: 'Include' },
              { value: 'ip4', label: 'IPv4 Address/Range' },
              { value: 'ip6', label: 'IPv6 Address/Range' },
              { value: 'exists', label: 'Exists Macro' }
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>

      <FormGroup
        :label="$t('spf.directives.qualifier.input')"
        :error="form.errors.qualifier"
      >
        <template v-slot:element="slotProps">
          <Select
            v-model="form.qualifier"
            v-bind="slotProps"
            :options="[
              { value: '+', label: 'Allow' },
              { value: '-', label: 'Fail' },
              { value: '~', label: 'Soft Fail' },
              { value: '?', label: 'Neutral (Not Recommended)' }
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>

      <FormGroup label="Mechanism domain">
        <template v-slot:element="slotProps">
          <ToggleSwitch v-bind="slotProps" v-model="state.useOwnDomain" />
        </template>
      </FormGroup>

      <FormGroup
        v-if="!state.useOwnDomain"
        :label="$t('spf.directives.value.input', { type: valueLabel })"
        :error="form.errors.value"
      >
        <template v-slot:element="slotProps">
          <InputText v-bind="slotProps" v-model="form.value" />
        </template>
      </FormGroup>

      <FormGroup
        :label="$t('spf.directives.description.input')"
        :error="form.errors.description"
      >
        <template v-slot:element="slotProps">
          <Textarea
            v-bind="slotProps"
            v-model="form.description"
            rows="5"
            cols="30"
          />
        </template>
      </FormGroup>
    </div>
    <template #footer>
      <div class="flex items-center justify-between gap-2">
        <DeleteConfirmButton
          v-if="form.uuid"
          @delete="deleteSpfDirective"
        />
        <form @submit.prevent="emits('submit')">
          <Button :label="$t('global.save')" type="submit" />
        </form>
      </div>
    </template>
  </Drawer>
</template>
