<script setup lang="ts">
import { useFormatters } from '@/Utils/Formatting';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';

const { formatNumber } = useFormatters();

const { items } = defineProps<{ items: PercentageBarItem[] }>();

const total = items.reduce((sum, item) => sum + item.value, 0);

const fields = items.map(percentageBar => ({
  label: percentageBar.label,
  value: percentageBar.value,
  percentage: Math.ceil((percentageBar.value / total) * 100),
  colour: percentageBar.colour,
}));

const maxPercentage = Math.max(...fields.map(field => field.percentage));

</script>

<template>
  <div class="flex w-full text-xs text-center text-white rounded overflow-hidden">
    <div
      v-for="(item, index) in fields"
      :key="index"
      :style="{ 'background': item.colour, 'width': `${item.percentage}%`}"
      v-tooltip="`${formatNumber(item.value)} ${item.label}`"
    >
      <div v-if="maxPercentage === item.percentage" class="mt-0.5 py-0.5">
        {{ item.percentage }}%
      </div>
    </div>
  </div>
</template>
