<script setup lang="ts">
import type {
  CustomerResource,
  DomainResource, DomainSenderStatisticsResource,
  SenderTotalsResource,
  TimelineStatisticsResource,
} from '@/types/types.gen';
import DatePicker from 'primevue/datepicker';
import AppLayout from '@/Layouts/AppLayout.vue';
import DomainOverviewCard from '@/Pages/Statistics/Dashboard/Partials/DomainOverviewCard.vue';
import AuthorisedTimelineCard from '@/Pages/Statistics/Dashboard/Partials/AuthorisedTimelineCard.vue';
import SenderTotalsCard from '@/Pages/Statistics/Dashboard/Partials/SenderTotalsCard.vue';
import DomainsCard from '@/Pages/Statistics/Dashboard/Partials/DomainsCard.vue';
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import { format } from 'date-fns';
import SendersComplianceCard from '@/Pages/Statistics/Dashboard/Partials/SendersComplianceCard.vue';
import type { DateRange } from '@/Pages/Statistics/Sender/types';

const { customer, dateRange } = defineProps<{
  dateRange: DateRange;
  customer: CustomerResource;
  domains: DomainResource[];
  timelineStatistics: TimelineStatisticsResource[];
  senderCategoriesTotals: SenderTotalsResource[];
  senderStatistics: DomainSenderStatisticsResource[];
  domainStatistics: TimelineStatisticsResource[];
}>();

const state = reactive<{
  dateRange: [Date, Date];
}>({
  dateRange: [new Date(`${(dateRange.start_date)}`), new Date(`${(dateRange.end_date)}`)],
});

const updateDateRange = ([startDate, endDate]: [Date, Date?]) => {
  // Will only have 2 dates after full range is selected
  if (!endDate) {
    return
  }

  router.visit(useRoute()('ui.dashboard.show', {
    customer: customer.uuid,
    start_date: format(startDate, 'yyyy-LL-dd'),
    end_date: format(endDate, 'yyyy-LL-dd'),
  }));
};

</script>

<template>
  <AppLayout :title="$t('dashboard.menu')">

    <div class="flex justify-between items-center mb-8">
      <div class="text-2xl font-semibold">{{ $t('dashboard.menu') }}</div>

      <DatePicker
        class="w-80"
        selectionMode="range"
        showIcon
        v-model="state.dateRange"
        @update:modelValue="updateDateRange($event)"
        iconDisplay="input"
        :maxDate="new Date()"
        hideOnRangeSelection
        pt:inputIconContainer:class="!-mt-3"
        dateFormat="yy-mm-dd"
        :fluid=true
      />
    </div>

    <div class="flex flex-col gap-8">

      <div class="flex gap-8 justify-between">
        <DomainOverviewCard
          :domains="domains"
        />

        <AuthorisedTimelineCard
          :statistics="timelineStatistics"
        />

        <SenderTotalsCard
          :totals="senderCategoriesTotals"
        />
      </div>

      <DomainsCard
        :customer="customer"
        :domains="domains"
        :statistics="domainStatistics"
      />

      <SendersComplianceCard
        :title="$t('dashboard.senders.approved')"
        :customer="customer"
        :statistics="senderStatistics"
      />
    </div>
  </AppLayout>
</template>
