<script setup lang="ts">
import type { InertiaForm } from '@inertiajs/vue3';
import Checkbox from 'primevue/checkbox';
import { type AlertsResource, AlertType } from '@/types/types.gen';
import { computed, inject, reactive } from 'vue';
import { toUpper } from 'lodash';
import Button from 'primevue/button';

const form: InertiaForm<AlertsResource> = inject('form')!;

const state = reactive({
  domainTypes: [
    'dmarc',
    'spf',
    'dkim',
    'bimi',
    'tls-rpt',
    'mta-sts',
  ],
});

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const enum frequencyHeadingsEnum {
  DNS_DELEGATION_VERIFICATION_CHANGE = 'dns-delegation-verification-change',
  DNS_DELEGATION_VERIFIED = 'dns-delegation-verified',
}

const frequencyHeading = computed((): frequencyHeadingsEnum => {
  return form.type === AlertType.DNS_DELEGATION_VERIFICATION_CHANGE
    ? frequencyHeadingsEnum.DNS_DELEGATION_VERIFICATION_CHANGE
    : frequencyHeadingsEnum.DNS_DELEGATION_VERIFIED;
});

const nextStep = () => {
  emits('completeStep', 'conditions');
};

</script>
<template>
  <div>
    <div class="font-medium mb-1">{{ $t('global.domain') }} / {{  $t(`alerts.type.options.${form.type}`) }}</div>
    <div>{{ $t(`alerts.edit.conditions.headings.${frequencyHeading}`) }}</div>

    <div class="mt-4 mb-2">{{ $t('alerts.edit.conditions.select_below') }}</div>
    <div>
      <div v-for="domainType of state.domainTypes" :key="domainType" class="flex items-center mb-2">
        <Checkbox v-model="form.conditions.domain_events.types" :inputId="'type_id_' + domainType" name="domain_events" :value="domainType" />
        <label
          :for="'type_id_' + domainType"
          class="ml-2"
          v-text="toUpper(domainType) + ' ' + $t('alerts.edit.conditions.setup-verification')"
        />
      </div>
    </div>
    <div class="mt-4">
      <div class="flex justify-end mr-5">
        <Button @click="nextStep()" severity="secondary" :label="$t('alerts.edit.buttons.next')" size="small" raised />
      </div>
    </div>
  </div>
</template>
