import {
  SenderCategory,
  type SenderIpAddressStatisticsResource,
  type SenderResource,
  ThreatLevel,
  type TimelineStatisticsResource,
} from '@/types/types.gen';
import type { GroupedStatistics, HasBlacklistings, HasTotals, StatisticsTotals } from '../types';
import { groupBy, sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import {
  totalCompliant,
  totalBlocked,
  totalDelivered,
  totalDeliveredForward,
  totalNotDelivered,
  totalFailing,
  totalPassing,
} from '@/Pages/Statistics/StatsHelpers';

export class SenderDataAggregator {
  constructor(
    private readonly sender: SenderResource,
    private readonly aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[],
  ) {}

  get groupedData(): GroupedStatistics[] {
    const groups: Record<string, GroupedStatistics> = {};
    const senderStatistics = this.senderStatistics;

    for (const ipAddressStatistics of senderStatistics) {
      const index = this.makeGroupIndex(ipAddressStatistics);

      groups[index] = !Object.prototype.hasOwnProperty.call(groups, index)
        ? this.makeGroupRow(ipAddressStatistics)
        : this.addToGroupRow(ipAddressStatistics, groups[index]);
    }

    return Object.values(groups);
  }

  private get senderStatistics(): Array<SenderIpAddressStatisticsResource> {
    return this.aggregatedTimelineStatistics.filter(
      ({ sender_uuid }) => sender_uuid === this.sender.uuid,
    );
  }

  private makeGroupIndex(statistics: SenderIpAddressStatisticsResource) {
    const organization = statistics.organization !== 'Private Network'
      ? statistics.organization
      : 'Unknown'

    return statistics.sender_uuid
      ?? statistics.asn
      ?? organization;
  }

  private makeGroupRow(
    statistics: SenderIpAddressStatisticsResource,
  ): GroupedStatistics {
    return {
      ...makeSummedIpAddressTotals([statistics]),
      uuid: statistics.uuid,
      sender_uuid: statistics.sender_uuid,
      category: statistics.category ?? SenderCategory.UNKNOWN,
      asn: Number(statistics.asn),
      organisation: this.sender.organization,
      service: this.sender.service,
      isp: statistics.organization,
      service_type: this.sender.type,
      has_threat: this.isThreat(statistics),
      total_sender_ip_addresses: 1,
      total_clean_sender_ip_addresses: statistics.is_blacklisted ? 0 : 1,
      country: statistics.country,
      country_name: statistics.country_name,
      host: statistics.host,
      ip_address: statistics.ip_address,
      blacklistings: { ...statistics.blacklistings },
      total_blacklistings: Object.keys(statistics.blacklistings).length,
    };
  }

  private addToGroupRow(
    statistics: SenderIpAddressStatisticsResource,
    group: GroupedStatistics,
  ) {
    const groupedData = makeSummedIpAddressTotals([statistics]);

    for (const key in groupedData) {
      const keyCast = key as keyof StatisticsTotals;
      group[keyCast] += groupedData[keyCast];
    }

    group.blacklistings = mergeBlacklistings(group, statistics);
    group.total_blacklistings = Object.keys(group.blacklistings).length;
    group.total_sender_ip_addresses += 1;
    group.total_clean_sender_ip_addresses += statistics.is_blacklisted ? 0 : 1,
    group.has_threat = group.has_threat || this.isThreat(statistics);

    return group;
  }

  private isThreat(statistics: SenderIpAddressStatisticsResource) {
    return Boolean(statistics.threat_level && statistics.threat_level !== ThreatLevel.LOW);
  }
}

export class DailyStatisticsAggregator {
  constructor(
    private readonly statistics: TimelineStatisticsResource[],
  ) {}

  get groupedData(): Record<string, StatisticsTotals> {
    const { formatDate } = useFormatters();

    return Object.entries(groupBy(this.statistics, 'date'))
      .reduce((prev, [date, stats]) => {
        prev[formatDate(date as unknown as Date)] = makeSummedIpAddressTotals(stats);

        return prev;
      }, {} as Record<string, StatisticsTotals>);
  }
}

export class SenderIpAddressDataAggregator {
  constructor(
    private readonly senders: SenderResource[],
    private readonly senderIpAddressStatisticsResources: SenderIpAddressStatisticsResource[],
  ) {
  }

  get groupedData(): Map<string, GroupedStatistics[]> {
    return new Map(
      this.senders.map((sender) => {
        const senderStatistics = this.senderIpAddressStatisticsResources.filter(({ sender_uuid }) => sender_uuid === sender.uuid);
        const groupedData = senderStatistics.map(statistics => new SenderDataAggregator(sender, [statistics]).groupedData[0]);

        return [sender.uuid, groupedData] as const;
      }),
    );
  }
}

function makeSummedIpAddressTotals(stats: (HasTotals | undefined)[]): StatisticsTotals {
  const hasStatsGuard = (stats: Array<HasTotals | undefined>): stats is Array<HasTotals> => {
    return stats.some(stat => !!stat);
  };

  const hasStats = hasStatsGuard(stats);

  return {
    total_incidents: sumBy(stats, 'total_incidents') ?? 0,
    total_compliant: hasStats
      ? sumBy(stats, totalCompliant)
      : 0,
    total_passing: hasStats
      ? sumBy(stats, totalPassing)
      : 0,
    total_complete_passes: sumBy(stats, 'total_complete_passes') ?? 0,
    total_spf_fails_dkim_passes: sumBy(stats, 'total_spf_fails_dkim_passes') ?? 0,
    total_dkim_fails_spf_passes: sumBy(stats, 'total_dkim_fails_spf_passes') ?? 0,
    total_forwards: sumBy(stats, 'total_forwards') ?? 0,
    total_complete_failures: sumBy(stats, 'total_complete_failures') ?? 0,
    total_failing: hasStats
      ? sumBy(stats, totalFailing)
      : 0,
    total_permitted: sumBy(stats, 'total_overrides_none') ?? 0,
    total_blocked: hasStats
      ? sumBy(stats, totalBlocked)
      : 0,
    total_delivered: hasStats
      ? sumBy(stats, totalDelivered)
      : 0,
    total_delivered_forward: hasStats
      ? sumBy(stats, totalDeliveredForward)
      : 0,
    total_not_delivered: hasStats
      ? sumBy(stats, totalNotDelivered)
      : 0,
  };
}

const mergeBlacklistings = (
  group: GroupedStatistics,
  statistics: SenderIpAddressStatisticsResource,
): HasBlacklistings['blacklistings'] => {
  // Merge together the blacklistings from each host of the group's blacklistings
  // and the current statistics' blacklistings
  return Object.entries(statistics.blacklistings)
    .reduce(
      (blacklistings, [listHost, listEntries]) => {
        if (!Object.prototype.hasOwnProperty.call(blacklistings, listHost)) {
          blacklistings[listHost] = [];
        }

        blacklistings[listHost] = [...new Set([...blacklistings[listHost], ...listEntries])];

        return blacklistings;
      }, group.blacklistings);
};
