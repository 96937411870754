<script setup lang="ts">
import * as EmailValidator from 'email-validator';
import ListBox from '@/Components/ListBox.vue';

defineProps<{
  emails: string[];
}>();

const emits = defineEmits<{
  (e: 'change', items: string[]): void;
}>()

</script>

<template>
  <ListBox
    :items="emails"
    :validate="EmailValidator.validate"
    :placeholder="$t('users.email.placeholder')"
    :validationMessage="$t('validation.email')"
    :editLabel="$t('users.email.edit')"
    @change="() => emits('change', emails)"
  />
</template>
