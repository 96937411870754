<script setup lang="ts">
import { Link, usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import { ref } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { MenuItem as PrimeViewMenuItem } from 'primevue/menuitem';
import type { Method } from '@inertiajs/core';
import Menubar from 'primevue/menubar';
import DarkModeToggler from '@/Layouts/Partials/DarkModeToggler.vue';
import ThemeToggler from '@/Layouts/Partials/ThemeToggler.vue';
import type { MenuRouterBindProps } from 'primevue/menu';

const { auth: { user: { customer_uuid } }, activeDomain } = usePage().props;

const route = useRoute();

const activeRoute = (wildcard: string) => Boolean(route().current(wildcard));

const { translate } = useTranslate();

const items = ref([
  {
    label: translate('dashboard.menu'),
    icon: 'pi pi-home',
    route: route('ui.dashboard.show', { customer: customer_uuid }),
    active: activeRoute('ui.dashboard.show'),
  },
  activeDomain
    ? {
        label: translate('global.reports'),
        active: activeRoute('ui.senders-statistics.index')
        || activeRoute('ui.tls-statistics.index')
        || activeRoute('ui.failure-reports.index'),
        items: [
          {
            label: translate('senders.menu'),
            icon: 'pi pi-chart-bar',
            route: route('ui.senders-statistics.index', { customer: customer_uuid, domain: activeDomain!.uuid }),
          },
          {
            label: translate('tls-statistics.menu'),
            icon: 'pi pi-chart-line',
            route: route('ui.tls-statistics.index', { customer: customer_uuid, domain: activeDomain!.uuid }),
          },
          {
            label: translate('failure-reports.menu'),
            icon: 'pi pi-chart-line',
            route: route('ui.failure-reports.index', { customer: customer_uuid, domain: activeDomain!.uuid }),
          },
        ],
      }
    : undefined,
  {
    label: translate('domains.menu'),
    icon: 'pi pi-server',
    route: route('ui.domains.index', customer_uuid),
    active:
      activeRoute('ui.domains.*') || activeRoute('*-settings.*'),
  },
  {
    label: translate('global.settings'),
    active: activeRoute('ui.alerts.*'),
    items: [
      {
        label: translate('alerts.menu'),
        icon: 'pi pi-bell',
        route: route('ui.alerts.index', { customer: customer_uuid }),
      },
      {
        label: translate('global.logout'),
        icon: 'pi pi-sign-out',
        route: route('logout'),
        method: 'post',
        as: 'button',
      },
    ],
  },
].filter(a => !!a));

type MenuItem = PrimeViewMenuItem & {
  route: string;
  method?: Method;
  active?: boolean;
  as?: 'button' | 'a';
};

</script>

<template>
  <Menubar :model="items" class="mb-8">
    <template #item="{ item, props, hasSubmenu } : {item: MenuItem, props: MenuRouterBindProps, hasSubmenu: boolean}">
      <Link v-if="item.route"  :href="item.route" :method="item.method ?? 'get'" :as="item.as ?? 'a'">
        <a v-ripple v-bind="props.action" :class="{ '!bg-primary-500/20 dark:bg-primary-900 rounded': item.active ?? false }">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </Link>
      <a v-else
         :class="{ '!bg-primary-500/20 dark:bg-primary-900 rounded': item.active ?? false }"
         v-ripple
         :href="item.url"
         :target="item.target"
         v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
      </a>
    </template>
    <template #end>
      <div class="flex gap-4 items-center">
        <DarkModeToggler />
        <ThemeToggler />
      </div>
    </template>
  </Menubar>
</template>
