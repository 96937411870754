import { ref } from 'vue';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';

const isModalOpen = ref(false);
const senderIpAddress = ref<GroupedStatistics | null>(null);

export const useIpStatisticsModal = () => {
  const openModal = (data: GroupedStatistics) => {
    isModalOpen.value = true;
    senderIpAddress.value = data;
  };

  const closeModal = () => {
    isModalOpen.value = false;
  };

  return {
    isModalOpen,
    senderIpAddress,
    openModal,
    closeModal,
  };
};
