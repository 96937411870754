<script setup lang="ts">
import ChartCard from './ChartCard.vue';
import { useFormatters } from '@/Utils/Formatting';
import { sumBy } from 'lodash';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import VolumeChart from '@/Pages/Statistics/Sender/Charts/VolumeChart.vue';

const { formatNumber } = useFormatters();
const { groups, timelineStatistics } = defineProps<{
  groups: GroupedStatistics[];
  timelineStatistics: TimelineStatisticsResource[];
}>();

const compliantAmount = sumBy(groups, 'total_incidents');
</script>
<template>
  <ChartCard :title="$t('senders.listing.cards.volume')">
    <template #lead>
      <h6 class="mt-2 text-2xl leading-none">{{ formatNumber(compliantAmount) }}</h6>
    </template>
    <template #graph>
      <VolumeChart class="h-32" :data="timelineStatistics" />
    </template>
  </ChartCard>
</template>
