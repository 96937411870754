<script setup lang="ts">
import SendersMenubar from './SendersMenubar.vue';
import { SenderReportCategory } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { SenderMenuBarLink } from '@/Pages/Statistics/Sender/types/menuBars';

const { translate } = useTranslate();
const { currentCategory } = defineProps<{ currentCategory: SenderReportCategory }>()

const menuBarCategories: SenderMenuBarLink[] = [
  [SenderReportCategory.COMPLIANCE, translate('senders.listing.menubar.compliance')],
  [SenderReportCategory.DELIVERABILITY, translate('senders.listing.menubar.deliverability')],
  [SenderReportCategory.REPUTATION, translate('senders.listing.menubar.reputation')],
  [SenderReportCategory.THREATS, translate('senders.listing.menubar.threats')],
];

</script>
<template>
  <SendersMenubar
    :items="menuBarCategories"
    :active-item="currentCategory"
    :pt="{item: {class: 'senders-index-menubar-item rounded-lg'}}"
    class="bg-white dark:bg-surface-800 senders-index-menubar"
  />
</template>
