<script setup lang="ts">
import ToggleButton from 'primevue/togglebutton';
import { usePreset } from '@primevue/themes';
import { ref, watch } from 'vue';
import hornet from '@/Themes/hornet';
import oem from '@/Themes/oem';

const checked = ref(true);

watch(checked, (newValue) => {
  usePreset(newValue ? hornet : oem);
});
</script>

<template>
  <ToggleButton
    v-model="checked"
    onLabel="OEM"
    offLabel="Hornet"
    onIcon="pi pi-palette"
    offIcon="pi pi-palette"
  />
</template>
