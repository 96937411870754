<script setup lang="ts">
import ChartCard from './ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import SendersChart from '@/Pages/Statistics/Sender/Charts/SendersChart.vue';

const { groups } = defineProps<{
  groups: GroupedStatistics[];
  timelineStatistics: TimelineStatisticsResource[];
}>();

const { formatNumber } = useFormatters();

const sendersCount = groups.length
const ipAddressesCount = sumBy(groups, 'total_sender_ip_addresses');
</script>
<template>
  <ChartCard
    :title="$t('senders.listing.cards.senders')"
  >
    <template #lead>
      <h6 class="mt-2 text-2xl">{{ formatNumber(sendersCount) }}</h6>
      <span>
        {{ formatNumber(ipAddressesCount)}} {{ $t('senders.listing.cards.ip_addresses') }}
      </span>
    </template>
    <template #graph>
      <SendersChart class="h-32" :data="timelineStatistics"/>
    </template>
  </ChartCard>
</template>
