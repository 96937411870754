<script setup lang="ts">
import { AlertFrequency, AlertScope, AlertType, type CustomerResource } from '@/types/types.gen';
import AlertForm from '@/Pages/Alerts/Partials/AlertForm.vue';

import AppLayout from '@/Layouts/AppLayout.vue';
import { useForm } from '@inertiajs/vue3';
import type { AlertCreateForm } from '@/Pages/Alerts/form';
import { provide } from 'vue';
import { useRoute } from 'ziggy-js';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import { useFormatters } from '@/Utils/Formatting';
import { fromUnixTime } from 'date-fns';

const { customer } = defineProps<{
  customer: CustomerResource;
  activity: null;
}>();

const formatter = useFormatters();
const form = useForm<AlertCreateForm>({
  type: AlertType.DOMAIN_CREATED,
  typeConfig: null,
  scope: AlertScope.CUSTOMER,
  channels: {
    mail: {
      enabled: true,
      recipients: [],
      settings: {
        from_name: '',
        enable_subject_tags: false,
      },
    },
  },
  frequency: AlertFrequency.IMMEDIATE,
  conditions: null,
  options: {
    link_expiry: null,
  },
  is_enabled: true,
  start_at: formatter.formatDate(fromUnixTime(Date.now())),
});
provide('form', form);

const submit = () => {
  const route = useRoute();
  form.post(
    route('ui.alerts.store', {
      customer: customer.uuid,
    }),
    responseCallbacks,
  );
};

</script>

<template>

  <AppLayout title="Alerts">

    <AlertForm @submit="submit" :customer="customer" />

  </AppLayout>

</template>
