<script setup lang="ts">
import { type StatisticsTotals } from '../types';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import PercentageBar from '@/Pages/Statistics/Sender/Charts/PercentageBar.vue';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';

const { translate } = useTranslate();
const { data } = defineProps<{ data: StatisticsTotals }>();

const chartData: PercentageBarItem[] = [
  {
    label: translate('senders.listing.labels.passing_dmarc'),
    value: data.total_passing,
    colour: getCssVariable('--p-graphs-positive'),
  },
  {
    label: translate('senders.listing.labels.forward'),
    value: data.total_forwards,
    colour: getCssVariable('--p-graphs-fill'),
  },
  {
    label: translate('senders.listing.labels.failure'),
    value: data.total_failing,
    colour: getCssVariable('--p-graphs-negative'),
  },
];
</script>

<template>
  <PercentageBar :items="chartData" />
</template>
