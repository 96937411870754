<script lang="ts" setup>

import { inject, onMounted, reactive, ref } from 'vue';
import type { DomainResource, ReportersResource } from '@/types/types.gen';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';
import { useRoute } from 'ziggy-js';
import axios from 'axios';
import Column from 'primevue/column';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import { FilterMatchMode } from '@primevue/core/api';
import { useFormatters } from '@/Utils/Formatting';
import { round, sumBy } from 'lodash';
import ShowMore from '@/Components/ShowMore.vue';
import CompliaceLegend from '@/Pages/Statistics/Sender/Partials/ComplianceLegend.vue';
import TableLoading from '@/Components/TableLoading.vue';
import { createStatisticsTotals } from '@/Pages/Statistics/StatsHelpers';

const { senderIpAddress } = useIpStatisticsModal();

const domain = inject<DomainResource>('domain');

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const reporters = ref<ReportersResource[]>([]);

const { formatNumber } = useFormatters();

const appendPercentage = (reporters: ReportersResource[], totalIncidents: number) => {
  return reporters.map((reporter: ReportersResource) => ({
    ...reporter,
    percentage: round((reporter.total_incidents / totalIncidents) * 100, 1),
    expanded: false,
  }));
}

onMounted(async () => {
  if (!domain || !senderIpAddress.value) return;

  const route = useRoute()('api.statistics.ip-address.reporters', {
    domain: domain.uuid,
    sender_ip_address: senderIpAddress.value.uuid,
  });

  try {
    const { data: result } = await axios.get(route);
    const totalIncidents = sumBy(result, 'total_incidents');

    reporters.value = appendPercentage(result, totalIncidents);
  } catch (e) {
    // no op
  }
});
</script>

<template>
    <DataTable
      v-model:filters="state.filters"
      :value="reporters"
      data-key="uuid"
      paginator
      :expanded-rows="reporters"
      @row-expand="(reporter) => reporter.expanded = true"
      @row-collapse="(reporter) => reporter.expanded = false"
      :global-filter-fields="['organisation']"
      :sort-order="-1"
      sort-field="total_incidents"
      :rows="10"
      :rowsPerPageOptions="[10, 20, 50]"
    >
      <template #empty>
        <div class="py-8">
          <TableLoading :total="10" :fields="5" />
        </div>
      </template>
      <template #header>
        <div class="flex items-center justify-between">
          <div class="flex flex-grow">
            <IconField>
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText
                :placeholder="$t('tables.search')"
                v-model="state.filters.global.value"
              />
            </IconField>
          </div>
          <div class="flex rounded-sm gap-4 p-3 bg-section-background">
            <CompliaceLegend />
          </div>
        </div>

      </template>
      <Column expander class="w-1/12"/>
      <Column
        sortable
        field="organisation"
        :header="$t('ip-address-modal.reporters.table.reporter')"
      />
      <Column
        sortable
        field="total_incidents"
        :header="$t('ip-address-modal.reporters.table.volume')"
      >
        <template #body="{data} : {data: ReportersResource}">
          {{ formatNumber(data.total_incidents) }}
        </template>
      </Column>

      <Column
        sortable
        field="percentage"
        :header="$t('ip-address-modal.reporters.table.percent_of_total')"
      >
        <template #body="{data}">
          {{ formatNumber(data.percentage) }}%
        </template>
      </Column>

      <Column
        :header="$t('ip-address-modal.reporters.table.compliance')"
      >
        <template #body="{ data }: {data: ReportersResource}">
          <CompliancePercentageBar
            :data="createStatisticsTotals(data)"
          />
        </template>
      </Column>

      <template #expansion="{data}: {data: ReportersResource}">
        <div class="bg-section-default p-8 flex flex-col gap-3">
          <div class="flex items-center gap-4">
            <div class=" flex shrink-0font-semibold"
               v-text="$t('ip-address-modal.reporters.table.envelope_to')"
            />
            <ShowMore :items="data.envelope_to" :initial-count="10" />
          </div>
          <div class="flex items-center gap-4">
            <div class="flex shrink-0 font-semibold"
               v-text="$t('ip-address-modal.reporters.table.envelope_from')"
            />
            <ShowMore :items="data.envelope_from" :initial-count="10" />
          </div>
          <div class="flex items-center gap-4">
            <div
              class="flex shrink-0 font-semibold"
              v-text="$t('ip-address-modal.reporters.table.header_from')"
            />
            <ShowMore :items="data.header_from" :initial-count="10" />
          </div>
        </div>
      </template>

    </DataTable>
</template>
