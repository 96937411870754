<script setup lang="ts">
import TabPanel from 'primevue/tabpanel';
import type { FailureReportResource } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { report } = defineProps<{ report: FailureReportResource }>()

const { translate } = useTranslate();

const properties = [
  {
    value: report.reporting_mta,
    label: translate('failure-reports.more-info.reporting_user_agent'),
  }, {
    value: report.reporting_mta,
    label: translate('failure-reports.more-info.dmarc_version'),
  }, {
    value: report.dkim_domain,
    label: translate('failure-reports.more-info.dkim_domain'),
  }, {
    value: report.dkim_identity,
    label: translate('failure-reports.more-info.dkim_identity'),
  }, {
    value: report.dkim_selector,
    label: translate('failure-reports.more-info.dkim_selector'),
  }, {
    value: report.dkim_canonicalized_body,
    label: translate('failure-reports.more-info.dkim_header'),
  }, {
    value: report.spf_dns,
    label: translate('failure-reports.more-info.spf_dns'),
  },
];

</script>

<template>
  <TabPanel value="1">
    <div class="flex flex-col mt-5 gap-2">
      <div v-for="(property, index) in properties" class="flex" :key="index">
        <div class="w-3/6 font-semibold" v-text="property.label"/>
        <div v-text="property.value"/>
      </div>
    </div>
  </TabPanel>
</template>
