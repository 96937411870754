<script setup lang="ts">
import { ref } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';
import SelectButton from 'primevue/selectbutton';
import CountryFlag from '@/Components/CountryFlag.vue';
import type { MenuItem } from '@/Pages/Statistics/IpAddress/types';

const { senderIpAddress } = useIpStatisticsModal();

defineProps<{
  selected: MenuItem;
}>();

const { translate } = useTranslate();

const emit = defineEmits<{
  (e: 'menu-item-changed', menuItem: MenuItem): void;
}>();

const items = ref([{
  label: translate('ip-address-modal.menu.overview'),
  value: <MenuItem>'overview',
}, {
  label: translate('ip-address-modal.menu.reporters'),
  value: <MenuItem>'reporters',
},
]);

const selectedMenuItem = ref<MenuItem>('overview');
</script>

<template>
  <div class="flex flex-col gap-2 mb-8" v-if="senderIpAddress">
    <div class="flex items-center gap-6">
      <div>
        <div class="text-2xl font-semibold"
           v-text="senderIpAddress.ip_address"
        />
        <div class="text-lg"
           v-text="senderIpAddress.organisation"
        />
      </div>
      <CountryFlag
        :code="senderIpAddress.country"
        class="text-4xl"
      />
    </div>
    <SelectButton
      :options="items"
      v-model="selectedMenuItem"
      @change="emit('menu-item-changed', selectedMenuItem)"
      class="mt-6"
      optionLabel="label"
      optionValue="value"
    />
  </div>
</template>
