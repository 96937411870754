<script setup lang="ts">
import { type StatisticsTotals } from '../types';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';
import PercentageBar from '@/Pages/Statistics/Sender/Charts/PercentageBar.vue';

const { translate } = useTranslate();
const { data } = defineProps<{ data: StatisticsTotals }>();

const chartData: PercentageBarItem[] = [
  {
    label: translate('senders.listing.labels.delivered'),
    value: data.total_delivered_forward,
    colour: getCssVariable('--p-graphs-positive'),
  },
  {
    label: translate('senders.listing.labels.overridden_as_delivered'),
    value: data.total_permitted,
    colour: getCssVariable('--p-graphs-color1'),
  },
  {
    label: translate('senders.listing.labels.overridden_as_not_delivered'),
    value: data.total_blocked,
    colour: getCssVariable('--p-graphs-warning'),
  },
  {
    label: translate('senders.listing.labels.failure'),
    value: data.total_complete_failures,
    colour: getCssVariable('--p-graphs-negative'),
  },
];
</script>

<template>
  <PercentageBar :items="chartData" />
</template>
