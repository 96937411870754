import './bootstrap';
import '../css/app.css';

import { createApp, h, type DefineComponent, onMounted } from 'vue';
import { createInertiaApp, router } from '@inertiajs/vue3';
import ConfirmationService from 'primevue/confirmationservice';
import { ZiggyVue, type Config } from 'ziggy-js';
import { Ziggy } from '@/ziggy';
import { TranslatorPlugin } from '@/Utils/Translations/TranslationPlugin';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import Theme from '@/Themes/theme';
import { FormattingPlugin } from '@/Utils/Formatting/FormattingPlugin';

const appName = import.meta.env.VITE_APP_NAME || 'Sendmarc';

let locale: string | undefined;
let browserLang: string | undefined;

if (typeof window !== 'undefined') {
  locale = window.navigator.language;
  browserLang = locale?.split('-')[0];
}

void createInertiaApp({
  title: title => `${title} - ${appName}`,
  resolve: (name) => {
    const pages = import.meta.glob<DefineComponent>('./Pages/**/*.vue', { eager: true });
    return pages[`./Pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    createApp({
      render: () => h(App, props),
      setup() {
        window.axios.interceptors.request.use((config) => {
          config.withCredentials = true;
          config.fetchOptions = {
            ...config.fetchOptions,
            mode: 'cors',
          };
          config.withXSRFToken = true;
          config.xsrfCookieName = 'XSRF-TOKEN';

          return config;
        });

        onMounted(() => {
          if (window.visitPath) {
            return router.visit(window.visitPath);
          }
        });
      },
    })
      .directive('tooltip', Tooltip)
      .directive('ripple', Ripple)
      .use(ConfirmationService)
      .use(ToastService)
      .use(TranslatorPlugin, browserLang)
      .use(FormattingPlugin, locale)
      .use(plugin)
      .use(ZiggyVue, Ziggy as Config)
      .use(PrimeVue, {
        theme: Theme,
      })
      .mount(el);
  },
  progress: {
    color: 'var(--p-primary-color)',
    showSpinner: true,
  },
});
