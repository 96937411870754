<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';

const props = defineProps<{
  code: string;
  name?: string;
}>();

const label = computed(() => {
  return `Flag for ${props.name ?? props.code}`;
})

const countryCodeEmoji = (code: string) => {
  if (!code.match(/^[A-Z]{2}$/i)) {
    return '';
  }

  return String.fromCodePoint(
    ...[...code.toUpperCase()]
      .map(c => c.charCodeAt(0) + 127397),
  );
}

// Regardless of browser or version, Windows computers do not support flags emojis
const checkEmojiRenderingSupport = () => {
  const ctx = document.createElement('canvas').getContext('2d');
  if (!ctx) {
    return false;
  }

  ctx.canvas.width = ctx.canvas.height = 1;
  ctx.fillText('🇿🇦', -4, 4);

  return ctx.getImageData(0, 0, 1, 1).data.some(pixel => pixel > 1);
}

onBeforeMount(() => {
  const classes = document.body.classList;

  // We've run this before, in another instance of the component
  if (classes.contains('emoji-flags-supported') || classes.contains('emoji-flags-unsupported')) {
    return classes.contains('emoji-flags-supported');
  }

  const supportsEmojiRendering = checkEmojiRenderingSupport();
  classes.add(
    supportsEmojiRendering ? 'emoji-flags-supported' : 'emoji-flags-unsupported',
  );
})
</script>

<template>
  <span
    v-if="name"
    v-tooltip.hover="name"
    role="img"
    :aria-label="label"
    class="emoji-flag"
  >
    {{ countryCodeEmoji(code) }}
  </span>

  <span v-else role="img" :aria-label="label" class="emoji-flag">
    {{ countryCodeEmoji(code) }}
  </span>
</template>

<style scoped>
@font-face {
    font-family: 'Twemoji Country Flags';
    unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
    src: url('/fonts/TwemojiCountryFlags.woff2') format('woff2');
}

.emoji-flags-unsupported .emoji-flag {
    font-family: "Twemoji Country Flags", sans-serif;
}
</style>
