<script setup lang="ts">
import Card from 'primevue/card';
import { useFormatters } from '@/Utils/Formatting';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import DeliverabilityPercentageBar from '@/Pages/Statistics/Sender/Charts/DeliverabilityPercentageBar.vue';

const { formatNumber } = useFormatters();

const { senderIpAddress } = useIpStatisticsModal();

</script>
<template>
  <div
      class="flex flex-col gap-8"
      v-if="senderIpAddress"
    >
      <div class="flex gap-8 justify-between">

        <Card class="w-full !bg-section-default">
          <template #title>
            {{ $t('ip-address-modal.overview.volume') }}
          </template>
          <template #content>
            <div class="text-lg"
              v-text="formatNumber(senderIpAddress.total_incidents)"
            />
          </template>
        </Card>

        <Card class="w-full !bg-section-default">
          <template #title>
            {{ $t('ip-address-modal.overview.compliance') }}
          </template>
          <template #content>
            <CompliancePercentageBar
              :data="senderIpAddress"
            />
          </template>
        </Card>

        <Card class="w-full !bg-section-default">
          <template #title>
            {{ $t('ip-address-modal.overview.deliverability') }}
          </template>
          <template #content>
            <DeliverabilityPercentageBar
              :data="senderIpAddress"
            />
          </template>
        </Card>

      </div>
      <Card class="w-full !bg-section-default">
        <template #content>
          <div class="grid grid-cols-1 gap-4">
            <div>
              <b v-text="$t('ip-address-modal.overview.detail.isp')" />
              <div v-text="senderIpAddress.isp" />
            </div>
            <div>
              <b v-text="$t('ip-address-modal.overview.detail.host')" />
              <div v-text="senderIpAddress.host" />
            </div>
            <div>
              <b v-text="$t('ip-address-modal.overview.detail.country')" />
              <div v-text="senderIpAddress.country_name" />
            </div>
            <div>
              <b v-text="$t('ip-address-modal.overview.detail.sender')" />
              <div class="flex flex-col gap-1">
                <div v-text="senderIpAddress.service ?? senderIpAddress.organisation" />
                <div class="text-sm" v-text="senderIpAddress.service_type"/>
              </div>
            </div>
            <div>
              <b v-text="$t('ip-address-modal.overview.detail.organisation')" />
              <div v-text="senderIpAddress.organisation" />
            </div>
          </div>
        </template>
      </Card>
  </div>
</template>
