<script setup lang="ts">
import { type DomainResource, type MtaStsSetupInstructionsResource } from '@/types/types.gen';
import Card from 'primevue/card';
import { reactive } from 'vue';
import MtaPolicySetupInstructions from '@/Pages/Domains/TlsRptSettings/Partials/Setup/MtaPolicySetupInstructions.vue';
import Button from 'primevue/button';

defineProps<{
  domain: DomainResource;
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
}>();

const state = reactive({
  showInstructions: window.location.hash === '#mta-policy',
});

const toggleInstructions = () => {
  state.showInstructions = !state.showInstructions;
  window.location.hash = state.showInstructions ? '#mta-policy' : '';
}
</script>

<template>
  <Card class="border border-surface">
    <template #content>
      <MtaPolicySetupInstructions
        v-if="state.showInstructions"
        @close="toggleInstructions"
        :domain="domain"
        :mta-sts-setup-instruction="mtaStsSetupInstruction"
      />
      <div v-else class="flex items-center justify-between">
        <div class="flex items-center">
          <i
            class="pi pi-verified mr-5 text-success-default !text-2xl"
          ></i>
          Policy Verified - Last verified on 2021-03-01 at 20:02
        </div>
        <Button
          @click="toggleInstructions"
          text
          :label="$t('configurations.instructions.view')"
          icon="pi pi-angle-right"
          iconPos="right"
          severity="secondary"
        />
      </div>
    </template>
  </Card>
</template>
