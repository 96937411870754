import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { App } from 'vue';

export const TranslatorPlugin = {
  install(app: App, lang?: string) {
    const { translate } = useTranslate(lang);

    app.config.globalProperties.$t = translate;
  },
};
