<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import BarChart from '@/Components/Charts/BarChart.vue';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Utils/DataAggregators';

const { translate } = useTranslate();
const { data } = defineProps<{ data: TimelineStatisticsResource[] }>();
const dailyStatistics = new DailyStatisticsAggregator(data).groupedData;

const chartData: ChartData<'bar'> = {
  labels: Object.keys(dailyStatistics),
  datasets: [
    {
      label: translate('senders.listing.labels.compliant'),
      data: Object.values(dailyStatistics).map(stats => stats.total_incidents),
      backgroundColor: getCssVariable('--p-graphs-positive'),
      borderRadius: 3,
    },
  ],
};
</script>

<template>
  <BarChart :data="chartData" />
</template>
