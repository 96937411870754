export const Translations = {
    "en.alerts.edit.activity.category.configuration": "Configuration",
    "en.alerts.edit.activity.category.mail-senders": "Mail Senders",
    "en.alerts.edit.activity.category.none": "None",
    "en.alerts.edit.activity.category.reports": "Reports",
    "en.alerts.edit.activity.category.status": "Status",
    "en.alerts.edit.activity.descriptions.customer-created": "Notify me when a new customer is added.",
    "en.alerts.edit.activity.descriptions.customer-exposure-report": "Schedule when you would like to receive the Exposure Report.",
    "en.alerts.edit.activity.descriptions.dmarc-reject-certificate": "Scheduled DMARC Compliance Certificate when DMARC Policy is set to Reject.",
    "en.alerts.edit.activity.descriptions.dns-delegation-verification-change": "Notify me when DNS delegation re-verification fails.",
    "en.alerts.edit.activity.descriptions.dns-delegation-verified": "Notify me when DNS delegation is verified.",
    "en.alerts.edit.activity.descriptions.domain-compliance-drop": "Notify me when the sender compliance of a domain drops below a threshold.",
    "en.alerts.edit.activity.descriptions.domain-created": "Notify me when a new domain is added.",
    "en.alerts.edit.activity.descriptions.domain-score-change": "Notify me when the DMARC Compliance Score has changed.",
    "en.alerts.edit.activity.descriptions.domain-settings-change": "Notify me when settings are changed.",
    "en.alerts.edit.activity.descriptions.sender-threat-level-change": "Notify me when Threat Levels increase for new or existing senders.",
    "en.alerts.edit.activity.descriptions.typically-authorized-sender-found": "Notify me when a new, commonly Approved Sender is detected.",
    "en.alerts.edit.activity.heading": "Select the activity you would like to monitor.",
    "en.alerts.edit.activity.title": "Activity",
    "en.alerts.edit.buttons.cancel": "Cancel",
    "en.alerts.edit.buttons.next": "Next",
    "en.alerts.edit.buttons.select": "Select",
    "en.alerts.edit.conditions.headings.dns-delegation-verification-change": "Notify me when DNS setup re-verification fails.",
    "en.alerts.edit.conditions.headings.dns-delegation-verified": "Notify where the verification status has changed",
    "en.alerts.edit.conditions.select_below": "Select Below:",
    "en.alerts.edit.conditions.setup-verification": "Setup Verification",
    "en.alerts.edit.email.customize": "Customize Email",
    "en.alerts.edit.email.heading": "Specify email recipients you would like to notify and customize the email.",
    "en.alerts.edit.email.recipients": "Recipients",
    "en.alerts.edit.email.subject-tags": "Enable subject line categorization prefixes",
    "en.alerts.edit.email.title": "Email",
    "en.alerts.edit.frequency.heading": "Choose how often you would like to receive this notification.",
    "en.alerts.edit.frequency.title": "Frequency",
    "en.alerts.frequency.options.daily": "Daily",
    "en.alerts.frequency.options.fortnightly": "Fortnightly",
    "en.alerts.frequency.options.hourly": "Hourly",
    "en.alerts.frequency.options.immediate": "Immediate",
    "en.alerts.frequency.options.monthly": "Monthly",
    "en.alerts.frequency.options.quarterly": "Quarterly",
    "en.alerts.frequency.options.six_monthly": "Six monthly",
    "en.alerts.frequency.options.two_monthly": "Two monthly",
    "en.alerts.frequency.options.weekly": "Weekly",
    "en.alerts.frequency.options.yearly": "Yearly",
    "en.alerts.frequency.title": "Frequency",
    "en.alerts.is_enabled.options.false": "Off",
    "en.alerts.is_enabled.options.true": "On",
    "en.alerts.is_enabled.title": "Status",
    "en.alerts.menu": "Alerts",
    "en.alerts.recipients.title": "Recipients",
    "en.alerts.scope.edit-form.heading": "Level of Monitoring",
    "en.alerts.scope.edit-form.values.customer": "Customer",
    "en.alerts.scope.edit-form.values.partner": "Partner",
    "en.alerts.scope.edit-form.values.system": "System",
    "en.alerts.scope.listing-title": ":scope Activity",
    "en.alerts.type.options.customer-created": "Customer Created",
    "en.alerts.type.options.customer-deleted": "Customer Deleted",
    "en.alerts.type.options.customer-exposure-report": "Exposure Report",
    "en.alerts.type.options.dmarc-reject-certificate": "DMARC Reject Certificate",
    "en.alerts.type.options.dns-delegation-verification-change": "Delegation Verification Changed",
    "en.alerts.type.options.dns-delegation-verified": "Delegation Verified",
    "en.alerts.type.options.domain-compliance-drop": "Domain Compliance Dropped",
    "en.alerts.type.options.domain-created": "Domain Created",
    "en.alerts.type.options.domain-deleted": "Domain Deleted",
    "en.alerts.type.options.domain-score-change": "Domain Score Changed",
    "en.alerts.type.options.domain-settings-change": "Domain Settings Changed",
    "en.alerts.type.options.sender-threat-level-change": "Sender Threat Level Changed",
    "en.alerts.type.options.typically-authorized-sender-found": "Typically Authorized Sender Found",
    "en.alerts.type.title": "Name",
    "en.bimi-settings.certificate.cn": "Certificate CN",
    "en.bimi-settings.certificate.expiry": "Certificate Expiry",
    "en.bimi-settings.certificate.input": "BIMI Verified Mark Certificate (File type: pem)",
    "en.bimi-settings.certificate.url": "Authority \/ Certificate URL",
    "en.bimi-settings.disable.action": "Disabled hosting",
    "en.bimi-settings.disable.description": "It is recommended that BIMI Hosting is enabled.",
    "en.bimi-settings.disable.modal.description": "By enabling BIMI Hosting you can ......",
    "en.bimi-settings.disable.modal.title": "Disable BIMI Record Hosting for :domain",
    "en.bimi-settings.disable.title": "BIMI Hosting is Currently Enabled",
    "en.bimi-settings.enable.action": "Enable BIMI Record Hosting",
    "en.bimi-settings.enable.description": "It is recommended that BIMI Hosting is enabled.",
    "en.bimi-settings.enable.modal.description": "By enabling BIMI Hosting you can ......",
    "en.bimi-settings.enable.modal.title": "Enable BIMI Record Hosting :domain",
    "en.bimi-settings.enable.title": "BIMI Hosting is Disabled",
    "en.bimi-settings.image.input": "BIMI Logo (File type: svg)",
    "en.bimi-settings.image.preview": "Logo Preview",
    "en.bimi-settings.image.size": "Logo size",
    "en.bimi-settings.image.url": "Logo Url",
    "en.bimi-settings.info.description": "BIMI, or Brand Indicators for Message Identification, is a standard that makes it easier to get your logo displayed next to your messages in the inbox, prevent fraudulent emails, and aide deliverability. BIMI works alongside DMARC, DKIM, and SPF to verify an email's authenticity and help with brand visibility.",
    "en.bimi-settings.info.title": "What is BIMI?",
    "en.configurations.bimi.description": "Update your DNS to allow the configuration of your domain\u2019s BIMI Public Keys.",
    "en.configurations.description": "To enable monitoring and management through Sendmarc,please implement the following changes in your domain\u2019s DNS settings.",
    "en.configurations.dkim.description": "Update your DNS to allow the configuration of your domain\u2019s DKIM Public Keys.",
    "en.configurations.dmarc.description": "Update your DNS to allow the configuration of your domain\u2019s DMARC policy and to capture reports on email authentication activity through our dashboard.",
    "en.configurations.dmarc.warning": "Take note of the required underscores (_) in the host and full-stops in the record value.",
    "en.configurations.instructions.add": "Add :rdType Record",
    "en.configurations.instructions.new": "Step 2: Add New :rdType Record",
    "en.configurations.instructions.remove": "Step 1: Remove Existing :rdType Record",
    "en.configurations.instructions.title": ":rdType Setup Instructions",
    "en.configurations.instructions.view": "View Setup Instructions",
    "en.configurations.mta-policy.description": "Update your DNS to allow the configuration of your domain\u2019s DMARC policy and to capture reports on email authentication activity.",
    "en.configurations.mta-policy.title": "MTA-STS Policy",
    "en.configurations.mta-record.description": "Update your DNS to allow the configuration of your domain\u2019s DMARC policy and to capture reports on email authentication activity.",
    "en.configurations.mta-record.title": "MTA-STS Record",
    "en.configurations.record.host": "Host",
    "en.configurations.record.type": "Record Type",
    "en.configurations.record.value": "Value",
    "en.configurations.share.title": "Share Setup Instructions",
    "en.configurations.spf.description": "Update your DNS to allow for the configuration of your domain\u2019s SPF record through our platform. Our platform ensures the validity of the record and makesuse of optimization technology which mitigates the risk of reaching DNS lookup limits as well as ensuring that your policy is not overly permissive.",
    "en.configurations.title": ":title Setup Instructions",
    "en.configurations.tls-rpt.description": "Update your DNS to allow the configuration of your domain\u2019s DMARC policy and to capture reports on email authentication activity.",
    "en.configurations.tls-rpt.title": "TLS-RPT",
    "en.customers.menu": "Customers",
    "en.customers.title": "Manage Customers",
    "en.dashboard.authorised-timeline.approved": "Approved",
    "en.dashboard.authorised-timeline.header": "Email Volume",
    "en.dashboard.authorised-timeline.unapproved": "Unapproved & Other",
    "en.dashboard.domain-summary.action": "View Domains",
    "en.dashboard.domain-summary.enforce": "Enforce",
    "en.dashboard.domain-summary.header": "Domains",
    "en.dashboard.domains.legend.compliant": "Compliant",
    "en.dashboard.domains.legend.failures": "Failures",
    "en.dashboard.domains.legend.view_all": "View All Domains",
    "en.dashboard.domains.table.approved": "Approved",
    "en.dashboard.domains.table.domain": "Domains",
    "en.dashboard.domains.table.senders": "Senders",
    "en.dashboard.domains.table.unapproved": "Unapproved",
    "en.dashboard.domains.table.view": "View Report",
    "en.dashboard.domains.table.volume": "Volume",
    "en.dashboard.menu": "Dashboard",
    "en.dashboard.sender-totals.action": "View Senders",
    "en.dashboard.sender-totals.approved": "Approved",
    "en.dashboard.sender-totals.header": "Senders",
    "en.dashboard.sender-totals.unapproved": "Unapproved",
    "en.dashboard.senders.approved": "Approved Senders by Volume",
    "en.dashboard.senders.compliance": "Compliance",
    "en.dashboard.senders.domain": "Domain",
    "en.dashboard.senders.volume": "Volume",
    "en.dkim-public-keys.create": "Add Public Key",
    "en.dkim-public-keys.description.input": "Description",
    "en.dkim-public-keys.selector.input": "Selector",
    "en.dkim-public-keys.type.input": "Record Type",
    "en.dkim-public-keys.update": "Update Public Key",
    "en.dkim-public-keys.value.input": "Value",
    "en.dkim-settings.disable.action": "Disabled DKIM Management",
    "en.dkim-settings.disable.description": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
    "en.dkim-settings.disable.modal.description": "All settings and configurations for this domain will be removed, this action cannot be undone.",
    "en.dkim-settings.disable.modal.title": "Disable DKIM Management for :domain",
    "en.dkim-settings.disable.title": "DKIM Public Key Hosting is Currently Enabled",
    "en.dkim-settings.enable.action": "Enable DKIM Management",
    "en.dkim-settings.enable.description": "It is recommended that DKIM management is enabled to assist you in managing your public keys.",
    "en.dkim-settings.enable.modal.description": "By enabling DKIM management you can manage your public keys with ease.",
    "en.dkim-settings.enable.modal.title": "Enable DKIM Management for :domain",
    "en.dkim-settings.enable.title": "DKIM Public Key Hosting is Disabled",
    "en.dkim-settings.import.confirm.header": "You are about to re-import the DKIM Public Keys for :domain",
    "en.dkim-settings.import.confirm.message": "Re-importing the DKIM Public Keys will overwrite your existing settings. Would you like to continue?",
    "en.dkim-settings.import.tooltip": "DKIM public keys can only be imported for domains that have reports",
    "en.dkim-settings.info.description": "Domain Keys Identified Mail is an email authentication method that allows a sender to digitally sign an email using a private key associated with their domain name, which can be verified by the recipient's mail server using a public key published in the sender's DNS records. This helps to verify the authenticity and integrity of the email, reducing the risk of spam and phishing attacks.",
    "en.dkim-settings.info.title": "What is DKIM?",
    "en.dkim-settings.policy.header": "Policy Settings",
    "en.dkim-settings.policy.input": "Outbound Signing Policy",
    "en.dkim-settings.policy.options.all": "All Emails",
    "en.dkim-settings.policy.options.some": "Some Emails",
    "en.dkim-settings.testing.input": "Testing Mode",
    "en.dmarc.alignment.dkim.input": "DKIM Alignment mode",
    "en.dmarc.alignment.dkim.options.r": "Strict",
    "en.dmarc.alignment.dkim.options.s": "Relaxed",
    "en.dmarc.alignment.heading": "Alignment Settings",
    "en.dmarc.alignment.spf.input": "SPF Alignment mode",
    "en.dmarc.labels.diff_sub_domain": "Apply different Sub-domain policy",
    "en.dmarc.percentage.input": "Percentage",
    "en.dmarc.policy.heading": "Policy Settings",
    "en.dmarc.policy.input": "Dmarc policy",
    "en.dmarc.policy.title": "Policy",
    "en.dmarc.raw.heading": "Raw Record",
    "en.dmarc.rua.emails.input": "Reports will be sent to",
    "en.dmarc.rua.heading": "Aggregate Reports",
    "en.dmarc.rua.interval.input": "Report Interval",
    "en.dmarc.rua.interval.options.1_day": "Once a Day",
    "en.dmarc.rua.interval.options.1_hour": "Every Hour",
    "en.dmarc.rua.interval.options.2_hours": "Every 2 Hours",
    "en.dmarc.rua.interval.options.4_hours": "Every 4 Hours",
    "en.dmarc.rua.interval.options.6_hours": "Every 6 Hours",
    "en.dmarc.ruf.confirm.accept": "Enable",
    "en.dmarc.ruf.confirm.header": "Enable failure reporting",
    "en.dmarc.ruf.confirm.message": "By enabling failure reporting, some personal information, such as email addresses or message content, may be exposed for diagnostic purposes",
    "en.dmarc.ruf.confirm.reject": "Disable",
    "en.dmarc.ruf.heading": "Failure Reports",
    "en.dmarc.ruf.options.input": "Failure Report Options",
    "en.dmarc.ruf.options.options.0": "SPF or DKIM Fail and Don't Align",
    "en.dmarc.ruf.options.options.1": "SPF and DKIM Fail and Don't Align",
    "en.dmarc.ruf.options.options.d": "DKIM fails regardless of alignment",
    "en.dmarc.ruf.options.options.s": "SPF fails regardless of alignment",
    "en.dmarc.ruf.status.input": "Report processing",
    "en.dmarc.ruf.status.options.disabled": "Disabled",
    "en.dmarc.ruf.status.options.enabled": "Enabled",
    "en.dmarc.ruf.status.options.specified": "Specific Recipients",
    "en.dmarc.subdomain_policy.input": "Sub-domain DMARC policy",
    "en.dmarc.title": "Configure Domain settings",
    "en.domains.create": "Add a New Domain",
    "en.domains.edit": "Edit Domain",
    "en.domains.input": "Domain",
    "en.domains.management.menu.certificate": "DMARC Compliance Certificate",
    "en.domains.management.menu.exposure_report": "Exposure Report",
    "en.domains.management.menu.logs": "View Logs",
    "en.domains.management.menu.set_as_active": "Set as Active",
    "en.domains.management.menu.set_as_parked": "Set as Parked",
    "en.domains.management.menu.set_primary": "Set as Primary Domain",
    "en.domains.menu": "Domains",
    "en.domains.report-modal.action": "Generate Link",
    "en.domains.report-modal.inputs.date_range": "Date Range",
    "en.domains.report-modal.inputs.expire_link_in": "Expire Link in",
    "en.domains.report-modal.title": "Domain Report",
    "en.domains.score.title": "Rating",
    "en.domains.settings": "Edit Settings",
    "en.domains.setup.action": "Generate Link",
    "en.domains.setup.input": "Expire Link In",
    "en.domains.setup.options.1_day": "One Day",
    "en.domains.setup.options.1_month": "One Month",
    "en.domains.setup.options.1_week": "One Week",
    "en.domains.setup.options.6_hours": "6 Hours",
    "en.domains.setup.options.12_hours": "12 Hours",
    "en.domains.setup.options.never": "Never",
    "en.domains.setup.title": "Link to Setup Instructions",
    "en.domains.title": "Manage Domains",
    "en.domains.type.input": "Domain Type",
    "en.domains.type.options.active": "Active",
    "en.domains.type.options.parked": "Parked",
    "en.domains.type.title": "Type",
    "en.exposure-report.sender-compliance.chart.configured": "Configured",
    "en.exposure-report.sender-compliance.chart.partially_configured": "Partially Configured",
    "en.exposure-report.sender-compliance.chart.unconfigured": "Illegitimate or Unconfigured",
    "en.exposure-report.sender-compliance.title": "Sender DMARC Readiness",
    "en.exposure-report.senders.title": "Top Senders - Compliance",
    "en.exposure-report.site-title": "Domain report for :domain",
    "en.exposure-report.title": "Domain Report",
    "en.exposure-report.volume.legend.failing": "Failing DMARC",
    "en.exposure-report.volume.legend.forward": "Forwards",
    "en.exposure-report.volume.legend.passing": "Passing DMARC",
    "en.exposure-report.volume.title": "Email Volume",
    "en.failure-reports.drawer.download": "Download Report",
    "en.failure-reports.drawer.header": "Failure Report",
    "en.failure-reports.drawer.menu.key_properties": "Key Properties",
    "en.failure-reports.drawer.menu.more_info": "More Info",
    "en.failure-reports.key-properties.authentication_failure": "Authentication Failure",
    "en.failure-reports.key-properties.date": "Date",
    "en.failure-reports.key-properties.delivery_results": "Delivery Results",
    "en.failure-reports.key-properties.feedback_type": "Feedback Type",
    "en.failure-reports.key-properties.incidents": "Number of Incidents",
    "en.failure-reports.key-properties.original_recipients": "Original Recipients",
    "en.failure-reports.key-properties.reporting_domains": "Reported Domains",
    "en.failure-reports.key-properties.reporting_mta": "Reporting MTA",
    "en.failure-reports.key-properties.return_path": "Return Path",
    "en.failure-reports.key-properties.source_ip": "Source IP Address",
    "en.failure-reports.menu": "Failure Reports",
    "en.failure-reports.more-info.dkim_domain": "DKIM Domain",
    "en.failure-reports.more-info.dkim_header": "DKIM Canonicalised Header",
    "en.failure-reports.more-info.dkim_identity": "DKIM Identity",
    "en.failure-reports.more-info.dkim_selector": "DKIM Selector",
    "en.failure-reports.more-info.dmarc_version": "DMARC Version",
    "en.failure-reports.more-info.reporting_user_agent": "Reporting User Agent",
    "en.failure-reports.more-info.spf_dns": "SPF DNS",
    "en.failure-reports.table_reports.authentication": "Authentication",
    "en.failure-reports.table_reports.date": "Date",
    "en.failure-reports.table_reports.ip_address": "IP Address",
    "en.failure-reports.table_reports.result": "Result",
    "en.failure-reports.table_reports.return_path": "Return Path",
    "en.global.add": "Add",
    "en.global.add_new": "Add new",
    "en.global.approved": "Approved",
    "en.global.back_to_settings": "Back to Settings",
    "en.global.cancel": "Cancel",
    "en.global.confirm_delete": "Confirm Delete",
    "en.global.copied": "Copied",
    "en.global.create": "Create",
    "en.global.created": "Created",
    "en.global.create_and_activate": "Create And Activate",
    "en.global.customer": "Customer",
    "en.global.delete": "Delete",
    "en.global.deleted": "Deleted",
    "en.global.disabled": "Disabled",
    "en.global.domain": "Domain",
    "en.global.enabled": "Enabled",
    "en.global.form.failed.description": "Error: Please fill out all required fields correctly.",
    "en.global.form.failed.title": "There were errors with your form",
    "en.global.form.success.title": "Your changes were saved.",
    "en.global.import_complete": "Import completed",
    "en.global.import_now": "Import Now",
    "en.global.logout": "Logout",
    "en.global.no": "No",
    "en.global.not_found": "Not found",
    "en.global.off": "Off",
    "en.global.on": "On",
    "en.global.partner": "Partner",
    "en.global.remove": "Remove",
    "en.global.reporting": "Reporting",
    "en.global.reports": "Reports",
    "en.global.save": "Save",
    "en.global.saved": "Saved",
    "en.global.save_exit": "Save and Exit",
    "en.global.settings": "Settings",
    "en.global.show_less": "Show less",
    "en.global.show_more": "Show more",
    "en.global.system": "System",
    "en.global.to": "To",
    "en.global.total": "Total",
    "en.global.update": "Update",
    "en.global.updated": "Updated",
    "en.global.yes": "Yes",
    "en.ip-address-modal.menu.dkim_result": "DKIM Result",
    "en.ip-address-modal.menu.overrides": "Overrides",
    "en.ip-address-modal.menu.overview": "Overview",
    "en.ip-address-modal.menu.reporters": "Reporters",
    "en.ip-address-modal.menu.result_summary": "Result summary",
    "en.ip-address-modal.menu.spf_result": "SPF Result",
    "en.ip-address-modal.overview.compliance": "Compliance",
    "en.ip-address-modal.overview.deliverability": "Deliverability",
    "en.ip-address-modal.overview.detail.country": "Country",
    "en.ip-address-modal.overview.detail.host": "HOST",
    "en.ip-address-modal.overview.detail.isp": "ISP",
    "en.ip-address-modal.overview.detail.organisation": "Organisation",
    "en.ip-address-modal.overview.detail.sender": "Sender",
    "en.ip-address-modal.overview.volume": "Volume",
    "en.ip-address-modal.reporters.table.compliance": "Compliance",
    "en.ip-address-modal.reporters.table.envelope_from": "Header From",
    "en.ip-address-modal.reporters.table.envelope_to": "Envelope To",
    "en.ip-address-modal.reporters.table.header_from": "Header From",
    "en.ip-address-modal.reporters.table.percent_of_total": "% of Total",
    "en.ip-address-modal.reporters.table.reporter": "Reporter",
    "en.ip-address-modal.reporters.table.volume": "Volume",
    "en.logs.drawer.header": ":type Logs",
    "en.logs.events.created": "created",
    "en.logs.events.deleted": "deleted",
    "en.logs.events.restored": "restored",
    "en.logs.events.updated": "updated",
    "en.logs.formatters.bimi_settings.title": "BIMI settings :event",
    "en.logs.formatters.default.title": ":title :event",
    "en.logs.formatters.dkim_public_keys.title": "DKIM public key :event",
    "en.logs.formatters.dkim_settings.title": "DKIM settings :event",
    "en.logs.formatters.dmarc_settings.title": "DMARC settings :event",
    "en.logs.formatters.domain.title": "Domain :event",
    "en.logs.formatters.mta_settings.title": "MTA-STS settings :event",
    "en.logs.formatters.spf_directives.title": "SPF directives :event",
    "en.logs.formatters.spf_settings.title": "SPF settings :event",
    "en.logs.formatters.tls_settings.title": "TLS-RPT settings :event",
    "en.mta.disable.action": "Disabled Hosting Only",
    "en.mta.disable.description": "It is recommended that TLS-RPT Reporting and MTA-STS Policy Hosting is enabled.",
    "en.mta.disable.modal.description": "disable mta hosting message ......",
    "en.mta.disable.modal.title": "Disable MTA-STS Policy Hosting for :domain",
    "en.mta.disable.title": "It is recommended that TLS-RPT Reporting and MTA-STS Policy Hosting is enabled.",
    "en.mta.enable.action": "Enable MTA-STS Hosting",
    "en.mta.enable.description": "It is recommended that MTA-STS Record and Policy Hosting is enabled.",
    "en.mta.enable.modal.description": "By Enable MTA-STS Hosting ......",
    "en.mta.enable.modal.title": "Enable MTA-STS Hosting for :domain",
    "en.mta.enable.title": "MTA-STS Hosting is Disabled",
    "en.mta.info.description": "MTA-STS allows you to manage encryption enforcement and reporting on your domain.",
    "en.mta.info.title": "What TLS-RPT and MTA-STS?",
    "en.mta.mx_servers": "Mx Servers",
    "en.mta.policy.input": "Enforcement Level",
    "en.mta.policy.options.enforce": "Enforced",
    "en.mta.policy.options.none": "None",
    "en.mta.policy.options.testing": "In Testing",
    "en.mta.title": "MTA-STS Record & Policy Hosting",
    "en.partners.menu": "Partners",
    "en.partners.title": "Manage partners",
    "en.scoring.blurb.high": "\n              You don't have effective controls in place to protect your domain from\n              impersonation and interception of email communication. This puts your\n              brand and email recipients at risk of attacks, reduces trust, and can\n              damage email deliverability.",
    "en.scoring.blurb.low": "\n              Email from your domain is highly secure from impersonation and\n              interception and recipients will see your branding in communication. These\n              measures build trust in your brand, protect recipients from attacks and\n              help ensure email is delivered effectively.",
    "en.scoring.blurb.moderate": "\n              You have some measures in place to protect recipients from malicious email\n              from your domain. Attention should be paid to areas where controls can be\n              improved. These measures build trust in your brand, protect recipients\n              from attacks and help ensure email is delivered effectively.\n            ",
    "en.scoring.header": "Domain Score",
    "en.scoring.impersonation": "Impersonation",
    "en.scoring.marketing": "Marketing",
    "en.scoring.note": "NOTE: This is your current domain score and may not reflect changes you\u2019ve made today.",
    "en.scoring.privacy": "Privacy",
    "en.scoring.rating.high": "High Risk",
    "en.scoring.rating.low": "Low Risk",
    "en.scoring.rating.moderate": "Moderate Risk",
    "en.senders.categories.all": "All Senders",
    "en.senders.categories.authorised_compliant": "Approved",
    "en.senders.categories.forwarder": "Forwarder",
    "en.senders.categories.suspicious": "Suspicious",
    "en.senders.categories.unauthorised": "Unapproved",
    "en.senders.categories.unknown": "None",
    "en.senders.category_updated.error": "Failed to update sender category",
    "en.senders.category_updated.success": "Stored update to sender category",
    "en.senders.listing.cards.blocked_volume": "Blocked Volume",
    "en.senders.listing.cards.compliance": "Compliance",
    "en.senders.listing.cards.deliverability": "Deliverability",
    "en.senders.listing.cards.deliverable_volume": "Deliverable Volume",
    "en.senders.listing.cards.ip_addresses": "IP Addresses",
    "en.senders.listing.cards.senders": "Senders",
    "en.senders.listing.cards.volume": "Volume",
    "en.senders.listing.labels.compliant": "Compliant",
    "en.senders.listing.labels.delivered": "Delivered",
    "en.senders.listing.labels.failing": "Failing",
    "en.senders.listing.labels.failure": "Failing DMARC",
    "en.senders.listing.labels.forward": "Forwards",
    "en.senders.listing.labels.overridden_as_delivered": "Overridden As Delivered",
    "en.senders.listing.labels.overridden_as_not_delivered": "Overridden As Not Delivered",
    "en.senders.listing.labels.passing_dkim": "Passing DKIM",
    "en.senders.listing.labels.passing_dkim_spf": "Passing DKIM & SPF",
    "en.senders.listing.labels.passing_dmarc": "Passing DMARC",
    "en.senders.listing.labels.passing_spf": "Passing SPF",
    "en.senders.listing.menubar.compliance": "Compliance",
    "en.senders.listing.menubar.deliverability": "Deliverability",
    "en.senders.listing.menubar.reputation": "Reputation",
    "en.senders.listing.menubar.threats": "Threats",
    "en.senders.listing.table.columns.blacklisted": "Blacklisted",
    "en.senders.listing.table.columns.blacklists": "Blacklists",
    "en.senders.listing.table.columns.category": "Category",
    "en.senders.listing.table.columns.clean": "Clean",
    "en.senders.listing.table.columns.compliance": "Compliance",
    "en.senders.listing.table.columns.country": "Country",
    "en.senders.listing.table.columns.deliverability": "Deliverability",
    "en.senders.listing.table.columns.delivered": "Delivered",
    "en.senders.listing.table.columns.failing": "Failing",
    "en.senders.listing.table.columns.forwards": "Forwards",
    "en.senders.listing.table.columns.host": "Host",
    "en.senders.listing.table.columns.ip": "IP",
    "en.senders.listing.table.columns.ip_addresses": "IP Addresses",
    "en.senders.listing.table.columns.not_delivered": "Not Delivered",
    "en.senders.listing.table.columns.passing": "Passing",
    "en.senders.listing.table.columns.sender": "Sender",
    "en.senders.listing.table.columns.volume": "Volume",
    "en.senders.menu": "Email senders",
    "en.senders.title": "Email senders for :domain",
    "en.sources.menu": "Email sources",
    "en.spf.all_mechanism_qualifier.input": "Top Level SPF Record All Term",
    "en.spf.all_mechanism_qualifier.options.+": "Allow unauthorized senders (Not Recommended)",
    "en.spf.all_mechanism_qualifier.options.-": "Fail unauthorized senders",
    "en.spf.all_mechanism_qualifier.options.?": "Neutral stance on unauthorized senders (Not Recommended)",
    "en.spf.all_mechanism_qualifier.options.~": "Soft fail unauthorized senders",
    "en.spf.directives.create": "Add a New Spf Directive",
    "en.spf.directives.description.input": "description",
    "en.spf.directives.mechanism.input": "Designated Sender Mechanism",
    "en.spf.directives.qualifier.input": "Mechanism Qualifier",
    "en.spf.directives.value.input": ":type Value",
    "en.spf.enable_flattening.input": "Enable Flattening",
    "en.spf.headers.directives": "Directives",
    "en.spf.import.confirm.header": "Import Existing SPF record",
    "en.spf.import.confirm.message": "You're about to import an existing SPF record. Are you sure you want to continue? This will delete any existing SPF directives you have listed here.",
    "en.tables.clear": "Clear",
    "en.tables.not_found": "No matching records found",
    "en.tables.search": "Keyword Search",
    "en.tls.disable.action": "Disabled Reporting & Hosting",
    "en.tls.disable.modal.description": "By enabling BIMI Hosting you can ......",
    "en.tls.disable.modal.title": "Disabled Reporting & Hosting :domain",
    "en.tls.enable.action": "Enable TLS-RPT Hosting & Reporting",
    "en.tls.enable.description": "It is recommended that TLS-RPT Reporting is enabled.",
    "en.tls.enable.modal.description": "By Enabling TLS-RPT Hosting Hosting you can ......",
    "en.tls.enable.modal.title": "Enable TLS-RPT Hosting & Reporting :domain",
    "en.tls.enable.title": "TLS-RPT Reporting is Disabled",
    "en.tls.rua_emails.input": "Report Will be Sent to",
    "en.tls.title": "TLS-RPT Reporting",
    "en.tls-statistics.header": "TLS-RPT reports for :domain",
    "en.tls-statistics.menu": "TLS-RPT reports",
    "en.tls-statistics.reporters.header": "Reports",
    "en.tls-statistics.reporters.table.policy_mode": "Policy Mode",
    "en.tls-statistics.reporters.table.policy_type": "Policy Type",
    "en.tls-statistics.reporters.table.reporter": "Reporter",
    "en.tls-statistics.reporters.table.total": "Total",
    "en.tls-statistics.timeline.failure": "Failed",
    "en.tls-statistics.timeline.header": "Timeline",
    "en.tls-statistics.timeline.success": "Success",
    "en.upload.add": "Add files",
    "en.upload.drop_zone": "Drag and drop file here to upload.",
    "en.upload.title": "Upload",
    "en.users.email.edit": "Edit Email",
    "en.users.email.placeholder": "Enter email address",
    "en.users.menu": "Users",
    "en.users.name.title": "Name",
    "en.users.title": "Manage Users",
    "en.validation.email": "Not a valid email address",
    "en.validation.invalid_attr": "The :attribute is not valid."
} as const;