<script setup lang="ts">
import ShowRawRecordButton from '@/Pages/Domains/DmarcSettings/Partials/ShowRawRecordButton.vue';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';

import type {
  CnameResource,
  DmarcSetupInstructionsResource,
} from '@/types/types.gen';

defineProps<{
  dnsRecord: CnameResource | null;
  setupInstructions: DmarcSetupInstructionsResource;
}>();

const rdType = 'DMARC';
</script>

<template>
  <SetupInstructionsCard
    :title="rdType"
    :description="$t('configurations.dmarc.description')"
  >
    <div v-if="dnsRecord">
      <div class="mb-1 font-bold"
         v-text="$t('configurations.instructions.remove', { rdType })"
      />
      <SetupInstructions
        host="_dmarc"
        :rd-type="dnsRecord.rdtype!"
        :value="dnsRecord.entries[0]"
        :show-copy-button="false"
      />
    </div>

    <div class="mb-1 mt-6 font-bold">
      <div v-if="dnsRecord"
         v-text="$t('configurations.instructions.new', { rdType })"
      />
      <div v-else
         v-text="$t('configurations.instructions.add', { rdType })"
      />
    </div>

    <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
      <SetupInstructions
        host="_dmarc"
        rd-type="CNAME"
        :value="setupInstructions.dmarc_host"
        show-copy-button
      />
      <ShowRawRecordButton
        :raw-record="setupInstructions.dmarc_raw_record"
      />
    </div>
    <div class="mt-2 text-sm"
       v-text="$t('configurations.dmarc.warning')"
    />
    <template #footer>
      <slot name="footer"/>
    </template>
  </SetupInstructionsCard>
</template>
