type ExtractKey<S extends string> = S extends `${string}.${infer Rest}`
  ? Rest
  : never;
export type ExtractLang<S extends string> = S extends `${infer Lang}.${string}`
  ? Lang
  : never;
export type TranslationKey<T> = {
  [K in keyof T]: ExtractKey<K & string>;
}[keyof T];

class TranslatorService<Translations extends Record<string, string>> {
  constructor(
    private readonly translations: Translations,
    private readonly locale?: string,
  ) {}

  public translate<Key extends TranslationKey<Translations>>(
    key: Key,
    replacements: undefined | Record<string, string> = undefined,
  ): string {
    if (typeof this.translations === 'undefined') {
      console.warn('Translations object is not defined.');
      return key;
    }

    const defaultLocale = 'en' as const;
    const translationKey = `${this.locale}.${key}`;
    const fallbackKey = `${defaultLocale}.${key}`;

    let translation: Translations[keyof Translations] | string
      = this.translations[translationKey] ?? this.translations[fallbackKey];

    if (replacements) {
      Object.keys(replacements).forEach((r) => {
        const replacementValue = replacements[r as keyof typeof replacements];
        if (replacementValue === undefined) {
          console.warn(`Replacement value for key "${r}" is undefined.`);
        }
        translation = translation.replace(`:${r}`, replacementValue);
      });
    }
    return translation;
  }
}

export default TranslatorService;
