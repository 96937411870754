<script setup lang="ts">
import type { AlertsResource, CustomerResource } from '@/types/types.gen';
import AppLayout from '@/Layouts/AppLayout.vue';
import { Link } from '@inertiajs/vue3';
import { route } from 'ziggy-js';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import InputIcon from 'primevue/inputicon';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import Button from 'primevue/button';
import Column from 'primevue/column';
import IconField from 'primevue/iconfield';
import { FilterMatchMode } from '@primevue/core/api';
import { reactive } from 'vue';
import Tag from 'primevue/tag';
import { capitalize } from 'lodash';

defineProps<{
  alerts: AlertsResource[];
  customer: CustomerResource;
}>();

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});
</script>
<template>
  <AppLayout title="Alerts">
    <!-- Edit alert example -->
    <Card class="w-full">
      <template #content>
        <DataTable
          v-model:filters="state.filters"
          :value="alerts"
          :global-filter-fields="['type']"
          data-key="uuid"
          :sort-order=1
          sort-field="type"
        >
          <template #empty>{{ $t('tables.not_found') }}</template>
          <template #header>
            <div class="flex justify-between">
              <IconField>
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  :placeholder="$t('tables.search')"
                  v-model="state.filters.global.value"
                />
              </IconField>

              <Link :href="route('ui.alerts.create', [customer.uuid])" preserve-state>
                <Button :label="$t('global.add_new')" icon="pi pi-plus" />
              </Link>
            </div>
          </template>
          <Column
            sortable
            field="type"
            :header="$t('alerts.type.title')"
            filterField="type"
            headerClass="w-3/6"
          >
            <template #body="{ data }: { data: AlertsResource }">
              <div class="flex-col content-center">
                <div>{{ $t('alerts.scope.listing-title', {scope: capitalize(data.scope)}) }}</div>
                <div class="font-medium leading-6 text-color">
                  {{ $t(`alerts.type.options.${data.type}`) }}
                </div>
              </div>
            </template>
          </Column>
          <Column :header="$t('alerts.frequency.title')" field="frequency">
            <template #body="{ data }: { data: AlertsResource }">
                <div class="font-medium leading-6 text-color">
                  {{ $t(`alerts.frequency.options.${data.frequency}`) }}
                </div>
            </template>
          </Column>
          <Column :header="$t('alerts.recipients.title')" field="recipients">
            <template #body="{ data }">
              <div class="font-medium leading-6 text-color">
                {{ data.channels.mail.recipients.length }}
              </div>
            </template>
          </Column>
          <Column :header="$t('alerts.is_enabled.title')" field="recipients">
            <template #body="{ data }">
              <Tag
                class="capitalize"
                :value="$t(data.is_enabled ? 'global.on' : 'global.off')"
                :severity="data.is_enabled ? 'success' : 'secondary'"
              />
            </template>
          </Column>
          <Column field="edit">
            <template #body="{ data }">
              <div class="flex items-center flex-row-reverse">
                  <Link :href="route('ui.alerts.edit', {customer: customer.uuid, alert: data.uuid})">
                    <Button
                      text
                      icon="pi pi-angle-right"
                    />
                  </Link>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </AppLayout>
</template>
