import { AlertType } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
const { translate } = useTranslate();

export enum AlertCategory {
  none = 'none',
  status = 'status',
  configuration = 'configuration',
  mail_senders = 'mail-senders',
  reports = 'reports',
}

export interface AlertConfig {
  type: AlertType;
  category: AlertCategory;
  group: string;
  description: string;
  hasConditions: boolean;
}

const config: AlertConfig[] = [
  {
    type: AlertType.CUSTOMER_CREATED,
    category: AlertCategory.none,
    group: 'customer',
    description: translate('alerts.edit.activity.descriptions.customer-created'),
    hasConditions: false,
  },
  {
    type: AlertType.DOMAIN_CREATED,
    category: AlertCategory.none,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.domain-created'),
    hasConditions: false,
  },
  {
    type: AlertType.DOMAIN_SCORE_CHANGE,
    category: AlertCategory.status,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.domain-score-change'),
    hasConditions: false,
  },
  {
    type: AlertType.DNS_DELEGATION_VERIFICATION_CHANGE,
    category: AlertCategory.status,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.dns-delegation-verification-change'),
    hasConditions: true,
  },
  {
    type: AlertType.DNS_DELEGATION_VERIFIED,
    category: AlertCategory.status,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.dns-delegation-verified'),
    hasConditions: true,
  },
  {
    type: AlertType.DOMAIN_SETTINGS_CHANGE,
    category: AlertCategory.configuration,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.domain-settings-change'),
    hasConditions: true,
  },
  {
    type: AlertType.DOMAIN_COMPLIANCE_DROP,
    category: AlertCategory.mail_senders,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.domain-compliance-drop'),
    hasConditions: false,
  },
  {
    type: AlertType.TYPICALLY_AUTHORIZED_SENDER_FOUND,
    category: AlertCategory.mail_senders,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.typically-authorized-sender-found'),
    hasConditions: false,
  },
  {
    type: AlertType.SENDER_THREAT_LEVEL_CHANGE,
    category: AlertCategory.mail_senders,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.sender-threat-level-change'),
    hasConditions: false,
  },
  {
    type: AlertType.DMARC_REJECT_CERTIFICATE,
    category: AlertCategory.reports,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.dmarc-reject-certificate'),
    hasConditions: false,
  },
  {
    type: AlertType.CUSTOMER_EXPOSURE_REPORT,
    category: AlertCategory.reports,
    group: 'domain',
    description: translate('alerts.edit.activity.descriptions.customer-exposure-report'),
    hasConditions: false,
  },
];

export default config;
