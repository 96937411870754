<script setup lang="ts">

import Column from 'primevue/column';
import { useFormatters } from '@/Utils/Formatting';
import Legend from '@/Components/Legend.vue';
import type { ReportEmits, ReportProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import DeliverabilityCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverabilityCard.vue';
import DeliverableVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverableVolumeCard.vue';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import IndexTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexTable.vue';
import DeliverabilityPercentageBar from '@/Pages/Statistics/Sender/Charts/DeliverabilityPercentageBar.vue';
import IndexSubTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexSubTable.vue';
import BlockedVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/BlockedVolumeCard.vue';

const { formatNumber } = useFormatters();

defineProps<ReportProps>();
defineEmits<ReportEmits>();
</script>

<template>
  <div class="flex flex-row gap-5">
    <DeliverabilityCard :data="groups"/>
    <DeliverableVolumeCard :groups="groups" :timeline-statistics="dailyStatistics"/>
    <BlockedVolumeCard :groups="groups" :timeline-statistics="dailyStatistics"/>
  </div>
  <IndexTable
    @update-category="(data, category) => $emit('update-category', data, category)"
    :customer-uuid="customerUuid"
    :domain="domain"
    :groups="groups"
    :ip-statistics-per-sender="ipStatisticsPerSender"
  >
    <template #legend>
      <Legend :icon-size="2" :text="$t('senders.listing.labels.delivered')" :icon-class="['bg-graphs-positive']" />
      <Legend :icon-size="2" :text="$t('senders.listing.labels.overridden_as_delivered')" :icon-class="['bg-graphs-color1']" />
      <Legend :icon-size="2" :text="$t('senders.listing.labels.overridden_as_not_delivered')" :icon-class="['bg-graphs-warning']" />
      <Legend :icon-size="2" :text="$t('senders.listing.labels.failure')" :icon-class="['bg-graphs-negative']" />
    </template>

    <template #columns>
      <Column class="w-1/6" :header="$t('senders.listing.table.columns.deliverability')">
        <template #body="{data}: {data: GroupedStatistics}">
          <DeliverabilityPercentageBar :data="data" />
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.volume')" field="total_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_incidents) }}
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.delivered')" field="total_delivered" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_delivered) }} <br>
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.not_delivered')" field="total_not_delivered" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_not_delivered) }} <br>
        </template>
      </Column>
    </template>

    <template #sub-table="{data: {sender_uuid}}: {data: GroupedStatistics}">
      <IndexSubTable :statistics="ipStatisticsPerSender.get(sender_uuid)!">
        <template #legend>
          <Legend :icon-size="2" :text="$t('senders.listing.labels.delivered')" :icon-class="['bg-graphs-positive']" />
          <Legend :icon-size="2" :text="$t('senders.listing.labels.overridden_as_delivered')" :icon-class="['bg-graphs-color1']" />
          <Legend :icon-size="2" :text="$t('senders.listing.labels.overridden_as_not_delivered')" :icon-class="['bg-graphs-warning']" />
          <Legend :icon-size="2" :text="$t('senders.listing.labels.failure')" :icon-class="['bg-graphs-negative']" />
        </template>

        <template #columns>
          <Column :header="$t('senders.listing.table.columns.volume')" sort-field="total_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_incidents) }}
            </template>
          </Column>
          <Column sort-field="total_delivered_forward" :sortable="true">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-positive']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_delivered_forward) }}
            </template>
          </Column>
          <Column sort-field="total_permitted" :sortable="true">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-color1']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_permitted) }}
            </template>
          </Column>
          <Column sort-field="total_blocked" :sortable="true">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-warning']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_blocked) }}
            </template>
          </Column>
          <Column sort-field="total_complete_failures" :sortable="true">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-negative']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_complete_failures) }}
            </template>
          </Column>
        </template>
      </IndexSubTable>
    </template>
  </IndexTable>
</template>
