<script setup lang="ts">
import type { TlsReporterStatisticsResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import MtaPolicyTag from '@/Pages/Tls/Partials/MtaPolicyTag.vue';
import { getCssVariable } from '@/Utils/Styling';
import { useFormatters } from '@/Utils/Formatting';
import Tag from 'primevue/tag';
import PercentageBar from '@/Pages/Statistics/Sender/Charts/PercentageBar.vue';

const { reporters } = defineProps<{ reporters: TlsReporterStatisticsResource[] }>();
const { formatNumber } = useFormatters()

type ReporterWithTotal = TlsReporterStatisticsResource & { total: number };

const reporterTotals: ReporterWithTotal[] = reporters.map(reporter => ({
  ...reporter,
  total: reporter.total_failures + reporter.total_passes,
}));

</script>
<template>
  <DataTable
    :value="reporterTotals"
    sortField="report_organization_name"
    sortOrder="1"
    paginator
    :rows="10"
    :rowsPerPageOptions="[10, 20, 50]"
  >
  <Column
    field="report_organization_name"
    :header="$t('tls-statistics.reporters.table.reporter')"
    :sortable="true"
    class="p-col-12 p-md-4"
  >
    <template #body="{ data }: {data: ReporterWithTotal}">
      {{ data.report_organization_name }}
    </template>
  </Column>

  <Column
    field="policy_mode"
    :header="$t('tls-statistics.reporters.table.policy_mode')"
    :sortable="true"
  >
    <template #body=" { data } : {data: ReporterWithTotal}">
      <MtaPolicyTag :policy="data.policy_mode" />
    </template>
  </Column>

    <Column
      field="policy_type"
      :header="$t('tls-statistics.reporters.table.policy_type')"
      :sortable="true"
    >
      <template #body=" { data }: {data : ReporterWithTotal}">
        <Tag v-if="data.policy_type === 'tlsa'" value="TLS" severity="info" />
        <Tag v-else-if="data.policy_type === 'sts'" value="STS" severity="severity" />
        <Tag v-else-if="data.policy_type === 'no-policy-found'" :value="$t('global.not_found')" severity="danger" />
        <Tag v-else value="🤷‍" severity="secondary" />
      </template>
    </Column>

    <Column
      field="total"
      :header="$t('tls-statistics.reporters.table.total')"
      :sortable="true"
    >
      <template #body=" { data }: {data : ReporterWithTotal}">
        {{ formatNumber(data.total) }}
      </template>
    </Column>

    <Column class="w-1/3">
      <template #body=" { data } : { data: ReporterWithTotal}">
        <PercentageBar
           :key="data.report_organization_name"
           :items="[
            {
              label: $t('tls-statistics.timeline.success'),
              value: data.total_passes,
              colour: getCssVariable('--p-success-default'),
            },
            {
              label: $t('tls-statistics.timeline.failure'),
              value: data.total_failures,
              colour: getCssVariable('--p-danger-default'),
            }
        ]"/>
      </template>
    </Column>
  </DataTable>
</template>
