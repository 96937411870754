import TranslatorService, {
  type TranslationKey,
  type ExtractLang,
} from '@/Utils/Translations/TranslatorService';
import { Translations } from './Translations';

type KeyOfTranslations = keyof typeof Translations;

type TranslationValue<S extends TranslationKey<typeof Translations>> = Extract<
  `${ExtractLang<KeyOfTranslations>}.${S}`,
  KeyOfTranslations
>;

type ReplacementVariables<S extends TranslationKey<typeof Translations>> =
  ExtractVariablesFromKey<typeof Translations, TranslationValue<S>>;

type ExtractVariables<S> = S extends `${string}:${infer Var} ${infer Rest}`
  ? Var | ExtractVariables<Rest>
  : S extends `${string}:${infer Var}`
    ? Var
    : never;

type ExtractVariablesFromKey<T, K extends keyof T> = ExtractVariables<T[K]>;

let translatorService: TranslatorService<typeof Translations>;

export function useTranslate(lang?: string) {
  if (!translatorService) {
    translatorService = new TranslatorService(Translations, lang);
  }

  const translate = <S extends TranslationKey<typeof Translations>>(
    key: S,
    replacements:
      | undefined
      | Record<Exclude<ReplacementVariables<S>, '' | 'type'>, string> = undefined,
  ): string => {
    return translatorService.translate(key, replacements);
  };

  return { translate };
}
