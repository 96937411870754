<script setup lang="ts">
import directives from '@/Pages/Domains/SpfSettings/directives';
import { Link, router } from '@inertiajs/vue3';
import DataTable, { type DataTableRowReorderEvent } from 'primevue/datatable';
import Column from 'primevue/column';
import { inject, ref } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3/';
import type { DomainResource, SpfDirectiveResource } from '@/types/types.gen';
import Button from 'primevue/button';
import { useRoute } from 'ziggy-js';
import { useToast } from 'primevue/usetoast';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useConfirm } from 'primevue/useconfirm';

const form: InertiaForm<{
  all_mechanism_qualifier: string;
  directives: SpfDirectiveResource[];
  enable_flattening: boolean;
}> = inject('form')!;

const { domain } = defineProps<{ domain: DomainResource }>();

const onRowReorder = (event: DataTableRowReorderEvent) => {
  form.directives = event.value;
};

const toast = useToast();
const { translate } = useTranslate();
const confirm = useConfirm();
const isVisible = ref(false);

const importSpfSettings = () => {
  // TODO: Check if spf is verified, if verified
  confirm.require({
    message: translate('spf.import.confirm.message'),
    header: translate('spf.import.confirm.header'),
    acceptLabel: translate('global.import_now'),
    rejectLabel: translate('global.no'),
    accept: () => {
      const route = useRoute();
      router.get(
        route('ui.spf-directives.import', {
          customer: domain.customer_uuid,
          domain: domain.uuid,
        }), {}, {
          onFinish: () => {
            toast.add({
              detail: translate('global.import_complete'),
              life: 3000,
              severity: 'success',
            });
          },
        },
      );
    },
    onShow: () => {
      isVisible.value = true;
    },
    onHide: () => {
      isVisible.value = false;
    },
  });
};
</script>

<template>
  <div class="flex items-center gap-3 pb-5">
    <Link
      :href="
        route('ui.spf-directives.create', {
          customer: domain.customer_uuid,
          domain: domain.uuid
        })
      "
    >
      <Button
        size="small"
        severity="secondary"
        icon="pi pi-plus"
        :label="$t('spf.directives.create')"
      />
    </Link>
    <Button
      size="small"
      outlined
      severity="secondary"
      icon="pi pi-file-import"
      label="import"
      @click="importSpfSettings"
    />
  </div>
  <DataTable
    :value="form.directives"
    :reorderableColumns="true"
    @rowReorder="onRowReorder"
    tableStyle="min-width: 50rem"
    class="border border-surface rounded overflow-hidden last:border-none"
  >
    <Column rowReorder headerStyle="width: 3rem" />
    <Column header="Order">
      <template #body="{ index }">
        {{ index + 1 }}
      </template>
    </Column>
    <Column header="Directives">
      <template #body="{ data }">
        {{ directives.humanDirective(data) }}
      </template>
    </Column>
    <Column>
      <template #body="{ data }">
        <div class="flex flex-row-reverse">
          <Link
            :href="
              route('ui.spf-directives.edit', {
                customer: domain.customer_uuid,
                domain: domain.uuid,
                spf_directive: data.uuid
              })
            "
          >
            <Button
              icon="pi pi-pencil"
              v-tooltip.left="$t('global.update')"
              severity="contrast"
              text
              rounded
              aria-label="Star"
            />
          </Link>
        </div>
      </template>
    </Column>
  </DataTable>
</template>
