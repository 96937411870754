<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import { isHighRisk, isModerateRisk } from '@/Pages/Domains/Score/ScoreUtils';

const { score } = defineProps<{
  score: number;
}>();
const { translate } = useTranslate();

const result = ((score) => {
  let label, colour;

  if (isHighRisk(score)) {
    label = translate('scoring.rating.high');
    colour = getCssVariable('--p-graphs-negative');
  } else if (isModerateRisk(score)) {
    label = translate('scoring.rating.moderate');
    colour = getCssVariable('--p-graphs-warning');
  } else {
    label = translate('scoring.rating.low');
    colour = getCssVariable('--p-graphs-positive');
  }

  return { label, colour };
})(score);

const chartData: ChartData<'doughnut'> = {
  datasets: [
    {
      data: [
        score,
        100 - score,
      ],
      backgroundColor: [
        result.colour,
        getCssVariable('--p-graphs-background'),
      ],
      borderWidth: 0,
    },
  ],
};
</script>
<template>
  <DoughnutChart :data="chartData">
    <div class="text-center">
      <div class="font-semibold">{{ score }}%</div>
      <div class="text-sm">{{ result.label }}</div>
    </div>
  </DoughnutChart>
</template>
