<script setup lang="ts">
import ChartCard from './ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import ComplianceChart from '@/Pages/Statistics/Sender/Charts/ComplianceChart.vue';

const { data } = defineProps<{ data: StatisticsTotals[] }>();

const { formatNumber, formatPercentage } = useFormatters();

const totalIncidents = sumBy(data, 'total_incidents');
const totalPassing = sumBy(data, 'total_passing');
</script>
<template>
  <ChartCard
    :title="$t('senders.listing.cards.compliance')"
  >
    <template #lead>
      <h6 class="mt-2 text-2xl">{{ formatNumber(totalPassing) }}</h6>
      <span class="text-lg">
        / {{ formatNumber(totalIncidents)}}
      </span>
    </template>
    <template #graph>
      <ComplianceChart :data="data">
        <template #default>
          <div class="absolute top-1/2 w-full text-center">
            <h6 class="-mt-6 font-bold">
              {{ formatPercentage((totalPassing / totalIncidents) * 100) }}
            </h6>
            <small class="mt-0">
              {{ $t('senders.listing.labels.compliant') }}
            </small>
          </div>
        </template>
      </ComplianceChart>
    </template>
  </ChartCard>
</template>
