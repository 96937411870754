<script setup lang="ts">

import Column from 'primevue/column';
import { useFormatters } from '@/Utils/Formatting';
import Legend from '@/Components/Legend.vue';
import type { ReportEmits, ReportProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import IndexTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexTable.vue';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import IndexSubTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexSubTable.vue';

const { formatNumber } = useFormatters();

defineProps<ReportProps>();
defineEmits<ReportEmits>();
</script>

<template>
  <div class="flex flex-row gap-5">
    <!-- Card Charts go here -->
  </div>
  <IndexTable
    @update-category="(data, category) => $emit('update-category', data, category)"
    :customer-uuid="customerUuid"
    :domain="domain"
    :groups="groups"
    :ip-statistics-per-sender="ipStatisticsPerSender"
    default-sort-field="blacklistings_count"
  >
    <template #legend>
      <Legend :icon-size="2" :text="$t('senders.listing.labels.passing_dkim_spf')" :icon-class="['bg-graphs-positive']" />
      <Legend :icon-size="2" :text="$t('senders.listing.labels.failing')" :icon-class="['bg-graphs-negative']" />
      <Legend :icon-size="2" :text="$t('senders.listing.labels.forward')" :icon-class="['bg-graphs-fill']" />
    </template>

    <template #columns>
      <Column class="w-1/6" :header="$t('senders.listing.table.columns.compliance')">
        <template #body="{data}: {data: GroupedStatistics}">
          <CompliancePercentageBar :data="data" />
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.blacklists')" sort-field="blacklistings_count" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_blacklistings) }}
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.volume')" sort-field="total_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_incidents) }}
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.clean')" sort-field="total_clean_sender_ip_addresses" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_clean_sender_ip_addresses) }} <br>
        </template>
      </Column>
    </template>

    <template #sub-table="{data: {sender_uuid}}: {data: GroupedStatistics}">
      <IndexSubTable :statistics="ipStatisticsPerSender.get(sender_uuid)!">
        <template #columns>
          <Column :header="$t('senders.listing.table.columns.blacklists')" sort-field="blacklistings_count" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_blacklistings) }}
            </template>
          </Column>
          <Column :header="$t('senders.listing.table.columns.volume')" sort-field="total_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_incidents) }}
            </template>
          </Column>
          <Column :header="$t('senders.listing.table.columns.blacklisted')" sort-field="" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ `${formatNumber(data.total_blacklistings)}???`  }} <br>
            </template>
          </Column>
          <Column :header="$t('senders.listing.table.columns.clean')" sort-field="total_clean_sender_ip_addresses" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_clean_sender_ip_addresses) }} <br>
            </template>
          </Column>
        </template>
      </IndexSubTable>
    </template>
  </IndexTable>
</template>
