<script setup lang="ts">
import ToggleButton from 'primevue/togglebutton';
import { onMounted, ref, watch } from 'vue';

const checked = ref(Boolean(localStorage.getItem('darkMode')));

watch(checked, (newVal) => {
  document.documentElement.classList.toggle('dark');
  localStorage.setItem('darkMode', newVal ? '1' : '');
});

onMounted(() => {
  if (checked.value) {
    document.documentElement.classList.add('dark');
  }
});

</script>

<template>
  <ToggleButton
    v-model="checked"
    onLabel="Dark"
    offLabel="Light"
    onIcon="pi pi-moon"
    offIcon="pi pi-sun"
  />
</template>
