<script setup lang="ts">
import { SenderCategory } from '@/types/types.gen';
import SelectButton from 'primevue/selectbutton';
import { ref } from 'vue';
import type { SenderCategoryMenuBarCategory } from '@/Pages/Statistics/Sender/types/menuBars';

const { currentCategory } = defineProps<{ currentCategory: SenderCategoryMenuBarCategory }>();

const selected = ref<SenderCategoryMenuBarCategory>(currentCategory);

const emit = defineEmits<{
  (e: 'menu-item-changed', menuItem: SenderCategoryMenuBarCategory): void;
}>();

</script>

<template>
  <SelectButton
    :allow-empty="false"
    v-model="selected"
    @change="emit('menu-item-changed', selected)"
    :options="[
      { label: $t('senders.categories.all'), value: 'All' },
      { label: $t('senders.categories.authorised_compliant'), value: SenderCategory.AUTHORISED_COMPLIANT },
      { label: $t('senders.categories.forwarder'), value: SenderCategory.FORWARDER },
      { label: $t('senders.categories.unauthorised'), value: SenderCategory.UNAUTHORISED },
      { label: $t('senders.categories.suspicious'), value: SenderCategory.SUSPICIOUS },
      { label: $t('senders.categories.unknown'), value: SenderCategory.UNKNOWN },
    ]"
    optionLabel="label"
    optionValue="value"
  />
</template>
