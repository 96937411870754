<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useRoute } from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import type { DomainResource } from '@/types/types.gen';
import { ref } from 'vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const emits = defineEmits(['hide']);

const disableBimiSettings = () => {
  const route = useRoute()('ui.bimi-settings.update', {
    customer: domain.customer_uuid,
    domain: domain.uuid,
  });

  router.put(
    route,
    {
      is_delegation_enabled: false,
    },
    {
      ...responseCallbacks,
      preserveState: false,
    },
  );
};

const visible = ref(true);
</script>

<template>
  <Dialog
    v-model:visible="visible"
    @hide="emits('hide')"
    modal
    :header="
      $t('bimi-settings.disable.modal.title', { domain: domain.domain_name })
    "
    class="w-2/6"
  >
    {{ $t('bimi-settings.disable.modal.description') }}

    <template #footer>
      <div class="flex items-center gap-4">
        <Button
          @click="visible = false"
          link
          :label="$t('global.cancel')"
          type="submit"
        />

        <form @submit.prevent="disableBimiSettings">
          <Button
            severity="danger"
            :label="$t('bimi-settings.disable.action')"
            type="submit"
          />
        </form>
      </div>
    </template>
  </Dialog>
</template>
