<script setup lang="ts" >
import Drawer from 'primevue/drawer';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';
import DrawerHeader from '@/Pages/Statistics/IpAddress/Partials/DrawerHeader.vue';
import Reporters from '@/Pages/Statistics/IpAddress/Partials/Reporters.vue';
import Overview from '@/Pages/Statistics/IpAddress/Partials/Overview.vue';
import { ref } from 'vue';
import type { MenuItem } from '@/Pages/Statistics/IpAddress/types';

const { isModalOpen, senderIpAddress } = useIpStatisticsModal();

const selectedMenuItem = ref<MenuItem>('overview');

</script>

<template>
  <Drawer
    v-if="senderIpAddress"
    v-model:visible="isModalOpen"
    position="right"
    closeIcon="pi pi-sign-out"
    class="!w-full"
  >
    <div class="mx-auto max-w-screen-2xl">
      <DrawerHeader
        :selected="selectedMenuItem"
        @menu-item-changed="(menuItem) => selectedMenuItem = menuItem"
      />
      <Overview
        v-if="selectedMenuItem === 'overview'"
      />
      <Reporters
        v-if="selectedMenuItem === 'reporters'"
      />
    </div>
  </Drawer>
</template>
