<script setup lang="ts">
import {
  type DomainResource,
  type SenderIpAddressStatisticsResource,
  type SenderReportCategory,
  type SenderResource,
  type TimelineStatisticsResource,
} from '@/types/types.gen';
import type { PaginatedResponse } from '@/types/http';
import AppLayout from '@/Layouts/AppLayout.vue';
import DatePicker from 'primevue/datepicker';
import Select from 'primevue/select';
import { provide, reactive } from 'vue';
import { router, usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';
import type { DateRange } from './types';
import { format } from 'date-fns';
import type { SenderCategoryMenuBarCategory } from '@/Pages/Statistics/Sender/types/menuBars';
import SenderReportsMenuBar from '@/Pages/Statistics/Sender/Partials/Menubars/SenderReportsMenuBar.vue';
import SenderCategoryMenubar from '@/Pages/Statistics/Sender/Partials/Menubars/SenderCategoryMenubar.vue';
import ReportRouter from '@/Pages/Statistics/Sender/Partials/Reports/ReportRouter.vue';
import IpStatisticsDrawer from '@/Pages/Statistics/IpAddress/IpStatisticsDrawer.vue';

const { auth: { user: { customer_uuid } } } = usePage().props
const route = useRoute()

const props = defineProps<{
  dateRange: DateRange;
  senders: SenderResource[];
  domains: PaginatedResponse<DomainResource>;
  domain: DomainResource;
  aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[];
  dailyStatistics: TimelineStatisticsResource[];
  reportCategory: SenderReportCategory;
}>()

const state = reactive<{
  category: SenderCategoryMenuBarCategory;
  aggregatedTimelineStatistics: SenderIpAddressStatisticsResource[];
  dateRange: [Date, Date];
  activeDomain: string;
}>({
  aggregatedTimelineStatistics: props.aggregatedTimelineStatistics,
  category: 'All',
  dateRange: [new Date(`${(props.dateRange.start_date)}`), new Date(`${(props.dateRange.end_date)}`)],
  activeDomain: props.domain.uuid,
});

provide('domain', props.domain);

const changeParams = (params: {
  domain?: string;
  customer?: string;
  start_date?: string;
  end_date?: string;
  reportCategory?: SenderReportCategory;
} = {}) => {
  router.get(route('ui.senders-statistics.index', {
    domain: params.domain ?? state.activeDomain,
    customer: params.customer ?? customer_uuid,
    start_date: params.start_date ?? format(state.dateRange[0], 'yyyy-LL-dd'),
    end_date: params.end_date ?? format(state.dateRange[1], 'yyyy-LL-dd'),
    report_category: params.reportCategory ?? props.reportCategory,
  }));
};

const updateCategory = (category: SenderCategoryMenuBarCategory) => {
  state.category = category;
  state.aggregatedTimelineStatistics = state.aggregatedTimelineStatistics.filter(
    statistic => category === 'All' || statistic.category === category,
  );
}

const updateSenderReportCategory = (reportCategory: SenderReportCategory) => {
  changeParams({ reportCategory });
}

const updateDomain = (domainUuid: string) => {
  changeParams({ domain: domainUuid });
}

const updateDateRange = ([startDate, endDate]: [Date, Date?]) => {
  // Will only have 2 dates after full range is selected
  if (!endDate) {
    return
  }

  changeParams({
    start_date: format(startDate, 'yyyy-LL-dd'),
    end_date: format(endDate, 'yyyy-LL-dd'),
  });
};
</script>

<template>
  <AppLayout :title="$t('senders.menu')">

    <div class="text-2xl font-semibold mb-8">
      {{ $t('senders.title', { domain: props.domain.domain_name }) }}
    </div>

    <div class="flex flex-col gap-8">
      <SenderReportsMenuBar
        @menu-item-changed="updateSenderReportCategory"
        :current-category="props.reportCategory"
      />
      <div class="flex flex-row gap-5">
        <Select
          filter
          v-model="state.activeDomain"
          optionValue="uuid"
          optionLabel="domain_name"
          :options="domains?.data ?? []"
          @update:modelValue="updateDomain($event)"
        />
        <DatePicker
          selectionMode="range"
          showIcon
          iconDisplay="input"
          v-model="state.dateRange"
          :maxDate="new Date()"
          hideOnRangeSelection
          @update:modelValue="updateDateRange($event)"
          pt:inputIconContainer:class="!-mt-3"
          dateFormat="yy-mm-dd"
          :fluid=true
        />
        <SenderCategoryMenubar
          @menu-item-changed="updateCategory"
          :current-category="state.category"
        />
      </div>

      <ReportRouter
        :sender-category="state.category"
        :reportCategory="props.reportCategory"
        :senders="senders"
        :aggregated-timeline-statistics="aggregatedTimelineStatistics"
        :daily-statistics="dailyStatistics"
        :domain="props.domain"
        :customer-uuid="customer_uuid"
      />
    </div>

    <IpStatisticsDrawer />
  </AppLayout>
</template>
