<script setup lang="ts" >
import Avatar from 'primevue/avatar';
import type { LogEvent } from '@/types/types.gen';

const { event } = defineProps<{
  event: LogEvent;
}>();

const icon = {
  created: 'pi pi-plus',
  updated: 'pi pi-pencil',
  deleted: 'pi pi-trash',
  restored: 'pi pi-restore',
}[event];

</script>

<template>
  <div class="absolute z-10 -mt-1 -ml-4">
    <Avatar
      rounded
      :icon="icon"
      size="small"
    />
  </div>
</template>
