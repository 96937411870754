<script setup lang="ts">
import ChartCard from './ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import BlockedVolumeChart from '@/Pages/Statistics/Sender/Charts/BlockedVolumeChart.vue';

const { groups } = defineProps<{
  groups: GroupedStatistics[];
  timelineStatistics: TimelineStatisticsResource[];
}>();

const { formatNumber } = useFormatters();

const blockedVolumeTotal = sumBy(groups, 'total_not_delivered');
</script>
<template>
  <ChartCard
    :title="$t('senders.listing.cards.blocked_volume')"
  >
    <template #lead>
      <h6 class="mt-2 text-2xl">{{ formatNumber(blockedVolumeTotal) }}</h6>
    </template>
    <template #graph>
      <BlockedVolumeChart class="h-32" :data="timelineStatistics"/>
    </template>
  </ChartCard>
</template>
