<script setup lang="ts">
import Card from 'primevue/card';
import FormGroup from '@/Components/FormGroup.vue';
import EmailsListBox from '@/Components/EmailsListBox.vue';
import { inject, reactive } from 'vue';
import type { MtaTlsForm } from '@/Pages/Domains/TlsRptSettings/types';
import type { DomainResource, TlsRptSetupInstructionsResource } from '@/types/types.gen';
import Divider from 'primevue/divider';
import TlsRecordSetupInstructions from '@/Pages/Domains/TlsRptSettings/Partials/Setup/TlsRecordSetupInstructions.vue';
import Button from 'primevue/button';

const form = <MtaTlsForm>inject('formState');

defineProps<{
  domain: DomainResource;
  tlsRptSetupInstruction: TlsRptSetupInstructionsResource;
}>();

const state = reactive({
  showInstructions: window.location.hash === '#tls-record',
});

const toggleInstructions = () => {
  state.showInstructions = !state.showInstructions;
  window.location.hash = state.showInstructions ? '#tls-record' : '';
}
</script>

<template>
    <div
      class="text-xl font-bold"
      v-text="$t('tls.title')"
    />
    <Card class="border border-surface">
      <template #content>
        <TlsRecordSetupInstructions
          v-if="state.showInstructions"
          @close="toggleInstructions"
          :tls-rpt-setup-instruction="tlsRptSetupInstruction"
          :domain="domain"
        />
        <div v-else>
          <div  class="mb-5 flex items-center justify-between">
            <div class="flex items-center">
              <i
                class="pi pi-verified mr-5 text-success-default !text-2xl"
              ></i>
              Verified - Last verified on 2021-03-01 at 20:02
            </div>
            <Button
              @click="toggleInstructions"
              text
              :label="$t('configurations.instructions.view')"
              icon="pi pi-angle-right"
              iconPos="right"
              severity="secondary"
            />
          </div>
          <Divider />
          <FormGroup :label="$t('tls.rua_emails.input')">
            <template v-slot:element="slotProps">
              <EmailsListBox
                :emails="form.tlsRptSettings.rua_emails"
                v-bind="slotProps"
                class="w-1/3"
              />
            </template>
          </FormGroup>
        </div>
      </template>
    </Card>
</template>
