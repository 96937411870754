<script setup lang="ts">
import Tag from 'primevue/tag';

const { result } = defineProps<{ result: string }>();

const knownResults: Record<string, string> = {
  'smg-policy-action': 'warn',
  'delivered': 'success',
  'spam': 'danger',
  'None, Accept': 'secondary',
};

const severity = knownResults[result] ?? 'secondary';

</script>

<template>
  <Tag
    class="capitalize"
    :value="result"
    :severity="severity"
  />
</template>
