<script setup lang="ts">
const { fields } = withDefaults(
  defineProps<{
    total: number;
    fields?: 1 | 3 | 5 | 10;
  }>(),
  { fields: 3 },
);

const gridClass = {
  'grid-cols-1': fields === 1,
  'grid-cols-3': fields === 3,
  'grid-cols-5': fields === 5,
  'grid-cols-10': fields === 10,
}

</script>

<template>
  <div class="grid gap-2" :class="gridClass">
    <template v-for="_ in Array(total)" :key="_" >
      <div v-for="_ in Array(fields)" :key="_" class="h-4 animate-pulse animate-duration-2000 rounded-lg bg-section-default" />
    </template>
  </div>
</template>
