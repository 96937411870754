export function isHighRisk(score: number) {
  return score < 50;
}

export function isModerateRisk(score: number) {
  return score < 80 && score >= 50;
}

export function isLowRisk(score: number) {
  return score >= 80;
}
