<script setup lang="ts">
import type { SenderIpAddressResource } from '@/types/types.gen';
import Button from 'primevue/button';
import IconField from 'primevue/iconfield';
import InputText from 'primevue/inputtext';
import CountryFlag from '@/Components/CountryFlag.vue';
import DataTable from 'primevue/datatable';
import { FilterMatchMode } from '@primevue/core/api';
import InputIcon from 'primevue/inputicon';
import Column from 'primevue/column';
import { reactive } from 'vue';
import TableLoading from '@/Components/TableLoading.vue';
import type { GroupedStatistics, StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';

const { statistics } = withDefaults(
  defineProps<{
    statistics: StatisticsTotals[];
    defaultSortField?: string | ((data: GroupedStatistics) => number);
  }>(),
  {
    defaultSortField: 'total_incidents',
  },
);

const { openModal } = useIpStatisticsModal();

const state = reactive({
  filters: { global: { value: '', matchMode: FilterMatchMode.CONTAINS } },
  loading: true,
})
</script>

<template>
    <DataTable
      :value="statistics"
      v-model:filters="state.filters"
      :sort-field="defaultSortField"
      :dt="{
        headerCell: {
          background: 'var(--p-section-background)'
        },
        row: {
          background: 'var(--p-section-background)'
        },
        header: {
          background: 'var(--p-section-background)'
        }
      }"
      :sort-order=-1
    >
      <template #empty>
        <TableLoading :total="statistics.length" :fields="10" />
      </template>
      <template #header>
        <div class="flex justify-between !bg-transparent">
          <IconField>
            <InputIcon>
              <i class="pi pi-search" />
            </InputIcon>
            <InputText
              v-model="state.filters.global.value"
              :placeholder="$t('tables.search')"
            />
          </IconField>

          <div class="flex gap-6 text-sm">
            <slot name="legend" />
          </div>
        </div>
      </template>

      <Column :header="$t('senders.listing.table.columns.country')" sort-field="country_name" :sortable="true">
        <template #body="{data}: {data: SenderIpAddressResource}">
          <CountryFlag class="text-lg" :code="data.country" :name="data.country_name" />
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.ip')" field="ip_address" :sortable="true">
        <template #body="{ data }: {data: GroupedStatistics}">
          <Button
            text
            :label="data.ip_address"
            @click="openModal(data)"
          />
        </template>
      </Column>
      <Column :header="$t('senders.listing.table.columns.host')" field="host" :sortable="true"></Column>

      <slot name="columns"/>

    </DataTable>
</template>
