<script setup lang="ts">
import Button from 'primevue/button';
import Card from 'primevue/card';
import type { CustomerResource, DomainResource, TimelineStatisticsResource } from '@/types/types.gen';
import Column from 'primevue/column';
import DataTable, { type DataTableSortEvent } from 'primevue/datatable';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';
import { useFormatters } from '@/Utils/Formatting';
import DomainComplianceChart from '@/Pages/Statistics/Dashboard/Charts/DomainComplianceChart.vue';
import { Link } from '@inertiajs/vue3';
import Legend from '@/Components/Legend.vue';
import { computed, ref } from 'vue';
import { orderBy } from 'lodash';

const { domains, statistics } = defineProps<{
  customer: CustomerResource;
  domains: DomainResource[];
  statistics: TimelineStatisticsResource[];
}>();

const formatter = useFormatters();

const domainStats = statistics.map((stat) => {
  const domain = domains.find(domain => domain.uuid === stat.domain_uuid);
  return domain ? { ...stat, ...domain } : stat;
});

const sortField = ref<DataTableSortEvent['sortField']>('total_incidents');
const sortOrder = ref<DataTableSortEvent['sortOrder']>(1);

const sortedData = computed(() => {
  const stats = orderBy(domainStats, [sortField.value], [sortOrder.value === -1 ? 'asc' : 'desc'])
  return stats.slice(0, 3);
});

const onSort = (event: DataTableSortEvent) => {
  sortField.value = event.sortField;
  sortOrder.value = event.sortOrder;
};

const authorisedSendersComplianceData = (row: TimelineStatisticsResource) => ({
  total_complete_failures: row.total_authorised_complete_failures,
  total_incidents: row.total_authorised_incidents,
});

const unAuthorisedSendersComplianceData = (row: TimelineStatisticsResource) => ({
  total_complete_failures: row.total_complete_failures - row.total_authorised_complete_failures,
  total_incidents: row.total_incidents - row.total_authorised_incidents,
});

</script>

<template>
  <Card>
    <template #content>
      <DataTable
        :value="sortedData"
        :sortField="sortField"
        :sortOrder="sortOrder"
        data-key="uuid"
        @sort="onSort"
      >
        <template #empty>
          {{ $t('tables.not_found') }}
        </template>

        <Column
          header-class="w-3/12"
        >
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.domain')"
            />
          </template>
          <template #body="{ data } ">
            <div class="flex items-center">
              <DomainAvatar size="xlarge" :domain="data" />
              <div class="flex h-full flex-col justify-between ml-4">
                <div class=" text-lg text-color mb-0.5">
                  {{ data.domain_name }}
                </div>
                <div>
                  <DmarcPolicyTag
                    :policy="data.policy"
                  />
                </div>
              </div>
            </div>
          </template>
        </Column>

        <Column
          :sortable="true"
          field="total_incidents"
          header-class="w-1/12"
        >
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.volume')"
            />
          </template>
          <template #body="{ data }">
            <div class="text-lg">
              {{ formatter.formatNumber(data.total_incidents) }}
            </div>
          </template>
        </Column>

        <Column header-class="w-3/12">
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.approved')"
            />
          </template>
          <template #body="{ data }">
            <div class="bg-section-default rounded w-full h-full p-3">

              <div class="flex items-center justify-between">
                <div>
                  <div class="text-xl" v-text="data.total_authorised_senders" />
                  <div class="text-md">Senders</div>
                </div>
                <div>
                  <div
                    class="text-xl"
                    v-text="formatter.formatNumber(data.total_authorised_incidents, '0.0a')"
                  />
                  <div class="text-md">Volume</div>

                </div>
                <DomainComplianceChart
                  v-if="data.total_authorised_incidents > 0"
                  size="small"
                  :data="authorisedSendersComplianceData(data)"
                />
              </div>
            </div>
          </template>
        </Column>

        <Column header-class="w-3/12">
          <template #header>
            <div class="font-medium text-xl"
               v-text="$t('dashboard.domains.table.unapproved')"
            />
          </template>
          <template #body="{ data }">
            <div class="bg-section-default rounded w-full h-full p-3">

              <div class="flex items-center justify-between">
                <div>
                  <div class="text-xl" v-text="data.total_unauthorised_senders" />
                  <div class="text-md">Senders</div>
                </div>
                <div>
                  <div
                    class="text-xl"
                    v-text="formatter.formatNumber(data.total_unauthorised_incidents, '0.0a')"
                  />
                  <div class="text-md">Volume</div>
                </div>
                <DomainComplianceChart
                  size="small"
                  :data="unAuthorisedSendersComplianceData(data)"
                />
              </div>
            </div>
          </template>
        </Column>
        <Column>
          <template #body="{ data }">
            <div class="flex flex-row-reverse">
              <Link :href="route('ui.senders-statistics.index', [data.customer_uuid, data.uuid])">
                <Button
                  link
                  icon="pi pi-chart-bar"
                  :label="$t('dashboard.domains.table.view')"
                />
              </Link>
            </div>
          </template>
        </Column>

      </DataTable>

      <div class="flex rounded-sm mt-4 items-center justify-between">
        <div class="flex gap-4 p-3 bg-section-background w-3/4">
          <Legend :icon-size="2" :text="$t('dashboard.domains.legend.compliant')" :icon-class="['bg-graphs-positive']" />
          <Legend :icon-size="2" :text="$t('dashboard.domains.legend.failures')" :icon-class="['bg-graphs-negative']" />
        </div>

        <Link :href="route('ui.domains.index', [customer.uuid])">
          <Button
            link
            icon="pi pi-angle-right"
            iconPos="right"
            size="large"
            :label="$t('dashboard.domains.legend.view_all')"
          />
        </Link>

      </div>
    </template>
  </Card>
</template>
