<script setup lang="ts">
import type { LogsResource } from '@/types/types.gen';
import LogIcon from '@/Pages/Logs/Partials/LogIcon.vue';
import { useFormatters } from '@/Utils/Formatting';
import { parseISO } from 'date-fns';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { log } = defineProps<{
  log: LogsResource;
}>();

const formatters = useFormatters();
const { translate } = useTranslate();

const formatDate = (log: LogsResource) => {
  return formatters.formatDate(parseISO(log.created_at));
}

const formatUser = (log: LogsResource) => {
  if (!log.user_uuid) {
    return translate('global.system');
  }
  return log?.user?.name ?? translate('global.deleted');
}
</script>

<template>
  <div>
    <slot name="icon" v-if="$slots.icon" />
    <LogIcon v-else :event="log.event" />

    <div class="ml-8">

      <div class="flex justify-between items-center">
        <h4 class="font-bold" v-if="$slots.title">
          <slot name="title"  />
        </h4>
        <div v-else>
          <h4
            class="font-bold"
            v-text="$t('logs.formatters.default.title', {
            title: log.subject_type,
            event: $t(`logs.events.${log.event}`)
          })"
          />
        </div>

        <span
          class="mt-1 block text-sm"
          v-text="formatDate(log)"
        />
      </div>

      <div class="text-sm mt-3 flex gap-2">
        <i class="pi pi-user" />
        <span v-text="formatUser(log)" />
      </div>

      <slot v-if="$slots.created && log.event === 'created'" name="created" />
      <div v-else-if="log.event === 'created'" class="mt-4 py-2 px-3 max-w-screen-sm gap-1 text-sm bg-section-default rounded-md">
        <div v-for="(attribute, key) in log.properties.attributes" :key="key">
          <div class="flex gap-3 items-center">
            <div class="basis-1/2" v-text="key + ':'" />
            <div class="basis-1/2" v-text="attribute" />
          </div>
        </div>
      </div>

      <slot v-if="$slots.updated && log.event === 'updated'" name="updated" />
      <div v-else-if="log.event === 'updated'" class="mt-4 py-2 px-3 flex flex-col gap-1 text-sm bg-section-default rounded-md">
        <div v-for="(attribute, key) in log.properties.attributes" :key="key">
          <div class="flex gap-3 items-center">
            <div class="basis-1/2" v-text="key + ':'" />
            <div class="basis-1/2 flex items-center">
              <span v-text="log.properties.old[key]" />
              <i class="pi pi-angle-right" />
              <span v-text="attribute" />
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
