<script setup lang="ts">
import type { TlsVolumeStatisticsResource } from '@/types/types.gen';
import type { ChartData } from 'chart.js';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { getCssVariable } from '@/Utils/Styling';
import { useFormatters } from '@/Utils/Formatting';
import LineChart from '@/Components/Charts/LineChart.vue';

const { data } = defineProps<{ data: TlsVolumeStatisticsResource[] }>();

const { translate } = useTranslate();
const { formatDate } = useFormatters();

const chartData: ChartData<'line'> = {
  labels: data.map(stat => formatDate(new Date(stat.date), 'MMM dd')),
  datasets: [
    {
      label: translate('dashboard.authorised-timeline.approved'),
      data: data.map(stats => stats.total_passes),
      borderColor: getCssVariable('--p-graphs-positive'),
      stack: 'Stack 0',
      borderWidth: 2,
    },
    {
      label: translate('dashboard.authorised-timeline.unapproved'),
      borderColor: getCssVariable('--p-graphs-negative'),
      data: data.map(stats => stats.total_failures),
      stack: 'Stack 0',
      borderWidth: 2,
    },
  ],
};
</script>

<template>
  <LineChart class="h-60" :data="chartData" />
</template>
