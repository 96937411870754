<script setup lang="ts">
import type { DomainResource } from '@/types/types.gen';
import Card from 'primevue/card';
import DomainPolicyChart from '@/Pages/Statistics/Dashboard/Charts/DomainOverviewChart.vue';
import { Link, usePage } from '@inertiajs/vue3';

const { domains } = defineProps<{
  domains: DomainResource[];
}>();

const { auth: { user: { customer_uuid } } } = usePage().props;

const totalReportingDomains
  = domains.filter(domain => domain.first_report_date !== null).length;

</script>

<template>
  <Card class="w-full">
    <template #content>
      <div class="flex justify-between">
        <div class="flex-col">

          <div
            class="text-xl font-semibold mb-1.5"
            v-text="$t('dashboard.domain-summary.header')"
          />

          <div class="text-2xl">
            {{ `${totalReportingDomains}  ${$t('global.reporting')}` }}
          </div>
          <div class="mb-1.5 mt-0.5">
            {{ `${domains.length} ${$t('global.total')}` }}
          </div>

          <Link
            :href="route('ui.domains.index', customer_uuid)"
            class="text-primary-default text-lg"
          >
            {{ $t('dashboard.domain-summary.action')}}
          </Link>
        </div>

        <DomainPolicyChart
          class="mt-2"
          :domains="domains"
        />

      </div>
    </template>
  </Card>
</template>
