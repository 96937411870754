<script setup lang="ts">
import type { DomainResource } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { computed, ref } from 'vue';
import updateDomain from '@/Pages/Domains/UpdateDomain';
import ExposureReportModal from '@/Pages/Domains/Exposure/Partials/ExposureReportModal.vue';
import Button from 'primevue/button';
import Menu from 'primevue/menu';

const { domain } = withDefaults(defineProps<{
  domain: DomainResource;
  severity: 'secondary' | 'info' | 'success' | 'warn' | 'danger' | 'contrast' | null | undefined;
  isText: boolean;
}>(), {
  isText: false,
});

const { translate } = useTranslate();

const emits = defineEmits<{
  (e: 'show-logs', domain: DomainResource): void;
}>();

const updateDomainTypeMenuItem = computed(() => {
  if (domain.type === 'parked') {
    return {
      command: () => updateDomain(domain, { type: 'active' }),
      color: 'text-green-600',
      label: translate('domains.management.menu.set_as_active'),
      icon: 'pi pi-play',
    };
  }

  return {
    command: () => updateDomain(domain, { type: 'parked' }),
    color: 'text-red-600',
    label: translate('domains.management.menu.set_as_parked'),
    icon: 'pi pi-pause',
  };
});

const menu = ref();
const showGenerateReportModal = ref(false);

const toggle = (event: Event) => {
  menu.value.toggle(event);
};
</script>

<template>
  <div>
    <Button
      type="button"
      text
      icon="pi pi-ellipsis-v"
      @click="toggle"
      size="small"
      rounded
      severity="secondary"
      aria-haspopup="true"
      aria-controls="domain_menu"
    />
    <Menu
      ref="menu"
      id="overlay_menu"
      :model="[{
          command: () => updateDomain(domain, { is_favorite: true }),
          label: $t('domains.management.menu.set_primary'),
          icon: 'pi pi-star'
        },{
          command: () => showGenerateReportModal = true,
          label: $t('domains.management.menu.exposure_report'),
          icon: 'pi pi-clock'
        },{
          label: $t('domains.management.menu.certificate'),
          icon: 'pi pi-shield'
        },{
          command: () => emits('show-logs', domain),
          label: $t('domains.management.menu.logs'),
          icon: 'pi pi-history'
        },
        updateDomainTypeMenuItem
      ]"
      :popup="true"
    >
      <template #item="{ item, props }">
        <a class="flex items-center" v-bind="props.action">
          <span :class="`${item?.icon} ${item?.color}`" />
          <span :class="item?.color">{{ item.label }}</span>
        </a>
      </template>
    </Menu>
    <ExposureReportModal
      v-if="showGenerateReportModal"
      @hide="showGenerateReportModal = false"
      :domain="domain"
    />
  </div>
</template>
