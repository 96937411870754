<script setup lang="ts">
import Index from '@/Pages/Domains/Listing/Index.vue';
import Form from '@/Pages/Domains/Listing/Partials/Form.vue';
import { useForm } from '@inertiajs/vue3';
import ResponseCallbacks from '@/Utils/ResponseCallbacks';
import { provide } from 'vue';
import { useRoute } from 'ziggy-js';
import { type CustomerResource, type DomainResource, DomainType } from '@/types/types.gen';
import type { DomainCreateForm } from '@/types/form';
import type { PaginatedResponse } from '@/types/http';

const { customer, domains } = defineProps<{
  customer: CustomerResource;
  domains: PaginatedResponse<DomainResource>;
}>();

const form = useForm<DomainCreateForm>({
  type: DomainType.ACTIVE,
  domain_name: '',
});

provide('form', form);

const route = useRoute();
function submit() {
  form.post(route('ui.domains.store', customer.uuid), ResponseCallbacks);
}
</script>

<template>
  <Index :customer="customer" :domains="domains" />
  <Form :customer="customer" @submit="submit" />
</template>
