<script setup lang="ts">
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import Slider from 'primevue/slider';
import AccordionPanel from 'primevue/accordionpanel';
import { inject, reactive } from 'vue';
import { type InertiaForm } from '@inertiajs/vue3';
import type { DmarcSettingsResource } from '@/types/types.gen';

const form: InertiaForm<DmarcSettingsResource> = inject('form')!;

const state = reactive({
  apply_same_policy: form.policy === form.subdomain_policy,
});

const toggleApplySamePolicy = () => {
  if (state.apply_same_policy) {
    form.subdomain_policy = form.policy;
  }
};
</script>

<template>
  <AccordionPanel value="0">
    <AccordionHeader>
      {{ $t('dmarc.policy.heading') }}
    </AccordionHeader>
    <AccordionContent>
      <div class="flex flex-col gap-7 px-5 py-1">
        <FormGroup
          :label="$t('dmarc.policy.input')"
          :error="form.errors.policy"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.policy"
              v-bind="slotProps"
              :allow-empty="false"
              :options="[
                { label: 'None', value: 'none' },
                { label: 'Quarantine', value: 'quarantine' },
                { label: 'Reject', value: 'reject' }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup :label="$t('dmarc.labels.diff_sub_domain')">
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="state.apply_same_policy"
              v-bind="slotProps"
              :allow-empty="false"
              @change="toggleApplySamePolicy"
              :options="[
                { label: $t('global.yes'), value: true },
                { label: $t('global.no'), value: false }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup
          v-if="!state.apply_same_policy"
          :label="$t('dmarc.subdomain_policy.input')"
          :error="form.errors.subdomain_policy"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.subdomain_policy"
              :allow-empty="false"
              v-bind="slotProps"
              :options="[
                { label: 'None', value: 'none' },
                { label: 'Quarantine', value: 'quarantine' },
                { label: 'Reject', value: 'reject' }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup
          :label="$t('dmarc.percentage.input')"
          :error="form.errors.policy_percentage"
        >
          <template v-slot:element="slotProps">
            <Slider
              v-bind="slotProps"
              v-model="form.policy_percentage"
              class="w-56 my-1"
            />
            {{ form.policy_percentage }}%
          </template>
        </FormGroup>
      </div>
    </AccordionContent>
  </AccordionPanel>
</template>
