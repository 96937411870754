<script setup lang="ts">
import {
  AlertDomainTypes,
  AlertFrequency,
  type AlertsResource,
  AlertType,
  type CustomerResource,
} from '@/types/types.gen';
import config, { type AlertConfig } from '@/Pages/Alerts/config';
import { type InertiaForm, Link } from '@inertiajs/vue3';

import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Card from 'primevue/card';

import { computed, inject, reactive } from 'vue';
import ActivityForm from '@/Pages/Alerts/Partials/ActivityForm.vue';
import EmailForm from '@/Pages/Alerts/Partials/EmailForm.vue';
import Button from 'primevue/button';
import VerificationOptionsPanel from '@/Pages/Alerts/Partials/Options/VerificationOptionsPanel.vue';
import FrequencyForm from '@/Pages/Alerts/Partials/FrequencyForm.vue';

const { customer, alert } = defineProps<{
  customer: CustomerResource;
  alert?: AlertsResource;
}>();

const emits = defineEmits<{
  (e: 'submit'): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;

const state = reactive({
  activePanel: alert ? 'email' : 'activity',
  requiredStepsComplete: alert !== undefined,
  activityDisabled: alert !== undefined,
  frequencyDisabled: true,
  emailDisabled: alert === undefined,
  showConditions: false,
  showFrequency: false,
});

const alertTypeConfig = computed((): AlertConfig => {
  return config.filter((alert: AlertConfig) => alert.type === form.type)[0];
});

const showVerificationConditions = computed(() => {
  return state.showConditions
    && (
      form.type === AlertType.DNS_DELEGATION_VERIFIED
      || form.type === AlertType.DNS_DELEGATION_VERIFICATION_CHANGE
      || form.type === AlertType.DOMAIN_SETTINGS_CHANGE
    )
});

const canSetFrequency = computed(() => {
  return form.type === AlertType.DMARC_REJECT_CERTIFICATE || form.type === AlertType.CUSTOMER_EXPOSURE_REPORT
});

const completeStep = (step: string) => {
  switch (step) {
    case 'activity':
    case 'conditions':
    case 'frequency':
      setAlertTypeDefaults();

      if (alertTypeConfig.value.hasConditions && step === 'activity') {
        state.showConditions = true;
        break;
      }

      if (canSetFrequency.value && step === 'activity') {
        state.activePanel = 'frequency';
        state.frequencyDisabled = false;
        state.requiredStepsComplete = false;
      } else {
        state.activePanel = 'email';
        state.emailDisabled = false;
        state.requiredStepsComplete = true;
      }
      break;
  }
};

const setAlertTypeDefaults = () => {
  switch (form.type) {
    case AlertType.DNS_DELEGATION_VERIFIED:
    case AlertType.DNS_DELEGATION_VERIFICATION_CHANGE:
    case AlertType.DOMAIN_SETTINGS_CHANGE:
      form.conditions = {
        statistics: null,
        domain_events: {
          types: [AlertDomainTypes.DMARC],
        },
      };
      break;
    case AlertType.DMARC_REJECT_CERTIFICATE:
      form.frequency = AlertFrequency.MONTHLY;
  }
}
</script>

<template>

    <Card>
      <template #content>
        <Accordion v-model:value="state.activePanel">
          <AccordionPanel value="activity" :disabled="state.activityDisabled">
            <AccordionHeader>
              <span class="flex items-center gap-2">
                <i class="pi pi-sliders-h"></i>
                {{ $t('alerts.edit.activity.title') }}
              </span>
            </AccordionHeader>
            <AccordionContent>
              <ActivityForm v-if="!state.showConditions" @complete-step="completeStep" />
              <VerificationOptionsPanel v-if="showVerificationConditions" @complete-step="completeStep" />

            </AccordionContent>
          </AccordionPanel>

          <AccordionPanel value="frequency" :disabled="state.frequencyDisabled">
            <AccordionHeader>
               <span class="flex items-center gap-2">
                <i class="pi pi-calendar"></i>
                {{ $t('alerts.edit.frequency.title') }}
              </span>
            </AccordionHeader>
            <AccordionContent>
              <p class="m-0">
                <FrequencyForm @complete-step="completeStep" />
              </p>
            </AccordionContent>
          </AccordionPanel>
          <AccordionPanel value="email" :disabled="state.emailDisabled">
            <AccordionHeader>
               <span class="flex items-center gap-2">
                <i class="pi pi-envelope"></i>
                {{ $t('alerts.edit.email.title') }}
              </span>
            </AccordionHeader>
            <AccordionContent>
              <EmailForm @complete-step="completeStep" />
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>

  <div class="flex gap-4 my-8 justify-end">
    <Link class="md:items-center flex" :href="route('ui.alerts.index', { customer: customer.uuid })" v-ripple :method="'get'">
      <div>{{ $t('alerts.edit.buttons.cancel') }}</div>
    </Link>
    <form @submit.prevent="emits('submit')">
      <Button
        :disabled="!state.requiredStepsComplete"
        severity="primary"
        :label="alert === undefined ? $t('global.create_and_activate') : $t('global.save')"
        type="submit"
      />
    </form>
  </div>

</template>
