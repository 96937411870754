<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import Chart from '@/Components/Charts/Chart.vue';
import { getCssVariable } from '@/Utils/Styling';

defineProps<{
  data: ChartData<'line'>;
}>();

const chartOptions: ChartOptions<'line'> = {
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  clip: false,
  scales: {
    x: {
      stacked: false,
      ticks: {
        display: true,
        color: getCssVariable('--p-form-field-color'),
      },
      grid: {
        display: true,
      },
      border: {
        display: false,
      },
    },
    y: {
      stacked: false,
      grid: {
        display: true,
      },
      ticks: {
        display: true,
        color: getCssVariable('--p-form-field-color'),
      },
      border: {
        display: false,
      },
    },
  },
};
</script>

<template>
  <Chart type="line" :data="data" :options="chartOptions"  />
</template>
