<script setup lang="ts">
import Button from 'primevue/button';
import type { SaveOrExitAction } from '@/Pages/Domains/types';
import { Link } from '@inertiajs/vue3';

defineProps<{
  cancelRoute?: string;
}>()

const emits = defineEmits<{
  (e: 'submit', action: SaveOrExitAction): void;
}>();

</script>
<template>
  <div class="mt-8 flex w-full items-center justify-between">
    <Button
      severity="secondary"
      text
      icon="pi pi-trash"
      :label="$t('global.delete')"
    />
    <div class="flex gap-4">
      <Link v-if="cancelRoute" :href="cancelRoute">
        <Button
          text
          :label="$t('global.cancel')"
          severity="secondary"
        />
      </Link>

      <Button
        severity="secondary"
        outlined
        :label="$t('global.save')"
        @click="emits('submit',  'save')"
        type="submit"
      />
      <Button
        severity="primary"
        :label="$t('global.save_exit')"
        @click="emits('submit', 'save_exit')"
        type="submit"
      />
    </div>
  </div>
</template>
