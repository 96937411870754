<script setup lang="ts">
import Drawer from 'primevue/drawer';
import { onMounted, ref, defineAsyncComponent } from 'vue';
import axios from 'axios';
import type { LogsResource } from '@/types/types.gen';
import DefaultLogFormatter from '@/Pages/Logs/Partials/LogFormatter.vue';

const { path } = defineProps<{
  path: string;
  header: string;
}>();

const emits = defineEmits<{
  (e: 'hide'): void;
}>()

const logs = ref<LogsResource[]>([]);
const visible = ref(true);

onMounted(() => {
  axios.get(path).then(response => logs.value = response.data);
});

const hideAfterClose = () => {
  setTimeout(() => emits('hide'), 400)
}

const getLogFormatter = (log: LogsResource) => {
  return defineAsyncComponent(() =>
    import(`./Partials/${log.subject_type.split('\\').pop()}LogFormatter.vue`)
      .catch(() => DefaultLogFormatter),
  )
}
</script>

<template>
  <Drawer
    v-model:visible="visible"
    @hide="hideAfterClose"
    :header="header"
    position="right"
    closeIcon="pi pi-sign-out"
    :pt="{
      root: {
        class: '!max-w-2xl !w-full !h-screen rounded'
      }
    }"
  >
    <div class="flex items-center justify-center px-6 w-full">
      <div class="space-y-12 border-l-2 border-surface mt-5 w-full" v-if="logs.length">
        <div v-for="(log, index) in logs" class="relative w-full" :key="index">
          <Component :is="getLogFormatter(log)" :log="log" />
        </div>
      </div>
      <div v-else v-text="$t('tables.not_found')" />
    </div>
  </Drawer>
</template>
