import { format, getLocaleFromPlatform } from 'numerable';
import type { NumerableLocale } from 'numerable/locale/types/numerable-locale';
import { formatDate as baseFormatDate } from 'date-fns';
import { type Locale } from 'date-fns/locale';
import * as locales from 'date-fns/locale';
import { capitalize, upperFirst } from 'lodash';

let numerableLocale: NumerableLocale;
let dateFnsLocale: Locale;

export const useFormatters = (locale?: string) => {
  if (!numerableLocale || (locale && numerableLocale.code !== locale)) {
    numerableLocale = getLocaleFromPlatform(locale ?? 'en-US');
  }

  const localeForDateFns = locale?.replace('-', '') ?? '';
  if (!dateFnsLocale || (locale && dateFnsLocale.code !== localeForDateFns)) {
    if (locale && Object.prototype.hasOwnProperty.call(locales, localeForDateFns)) {
      dateFnsLocale = (locales as Record<string, Locale>)[localeForDateFns];
    } else {
      dateFnsLocale = locales.enUS;
    }
  }

  const formatNumber = (value: string | number, formatString?: string): string => {
    return format(value, formatString, { locale: numerableLocale });
  }

  const formatPercentage = (value: string | number, formatString?: string): string => {
    return format(value, formatString ?? '0.0', { locale: numerableLocale }) + '%';
  }

  const formatDate = (value: Date, formatString?: string): string => {
    return baseFormatDate(value, formatString ?? 'yyyy-MM-dd', { locale: dateFnsLocale });
  }

  /**
   * Turns `FOOO-bar_bAZ` into `Foo bar baz`
   */
  const sentenceCase = (value: string) => capitalize(value.replace(/[\W\s_]+/g, ' ').trim());

  /**
   * Turns `FOOO- & bar_bAZ` into `Foo & Bar Baz`
   */
  const titleCase = (value: string) => value.replace(/[^\w\s&]+|_/g, ' ').split(' ').map(upperFirst).join(' ');

  return {
    formatDate,
    formatNumber,
    formatPercentage,
    titleCase,
    sentenceCase,
  };
}
