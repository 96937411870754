<script setup lang="ts" >
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';
import type { DomainResource, TlsRptSetupInstructionsResource } from '@/types/types.gen';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import Button from 'primevue/button';
import ShareSetupInstructions from '@/Pages/Domains/Partials/ShareSetupInstructions.vue';

defineProps<{
  domain: DomainResource | null;
  tlsRptSetupInstruction: TlsRptSetupInstructionsResource;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>()

</script>

<template>
  <div>
    <div class="mb-5 flex">
      <Button
        @click="emits('close')"
        class="pb-10"
        text
        :label="$t('global.back_to_settings')"
        icon="pi pi-angle-left"
        iconPos="left"
        severity="secondary"
      />
    </div>
    <SetupInstructionsCard
      :title="$t('configurations.tls-rpt.title')"
      :description="$t('configurations.tls-rpt.description')"
    >
      <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
        <SetupInstructions
          host="_smtp._tls"
          rd-type="CNAME"
          :value="tlsRptSetupInstruction.tls_rpt_record"
          show-copy-button
        />
      </div>
      <template #footer>
        <ShareSetupInstructions
          v-if="domain"
          :domain="domain"
        />
      </template>
    </SetupInstructionsCard>
  </div>
</template>
