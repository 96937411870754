<script setup lang="ts">
import type { DomainResource } from '@/types/types.gen';
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { countBy } from 'lodash';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import type { DoughnutChartSize } from '@/Components/types/types';

const { domains } = defineProps<{
  domains: DomainResource[];
  size?: DoughnutChartSize;
}>();

const policyCounts = countBy(domains, 'policy');

const totalRejects = domains.filter(domain => domain.policy === 'reject').length;

const enforcedPercent = Math.round((totalRejects / domains.length) * 100);

const chartData: ChartData<'doughnut'> = {
  labels: [
    'None',
    'Reject',
    'Quarantine',
  ],
  datasets: [
    {
      data: [
        policyCounts?.none ?? 0,
        policyCounts?.reject ?? 0,
        policyCounts?.quarantine ?? 0,
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-negative'),
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-warning'),
      ],
      borderWidth: 0,
    },
  ],
};
</script>

<template>
  <DoughnutChart :size="size" :data="chartData">
    <div
      class="font-bold"
      v-text="`${enforcedPercent}%`"
    />
    <div
      class="text-sm"
      v-text="$t('dashboard.domain-summary.enforce')"
    />
  </DoughnutChart>
</template>
