<script setup lang="ts">
import FileUpload from 'primevue/fileupload';
import Button from 'primevue/button';

const props = defineProps<{
  accept: string;
}>();

const emits = defineEmits(['upload']);
</script>

<template>
  <FileUpload
    v-bind="props"
    @uploader="emits('upload', $event)"
    :auto="true"
    customUpload
    :multiple="false"
    :accept="accept"
  >
    <template #header="{ chooseCallback, files }">
      <div class="flex gap-2">
        <Button
          v-if="!files.length"
          @click="chooseCallback()"
          severity="secondary"
          :label="$t('upload.add')"
          size="small"
          icon="pi pi-plus"
        />
      </div>
    </template>
    <template #empty>
      <slot name="empty" />
    </template>
  </FileUpload>
</template>
