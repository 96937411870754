<script setup lang="ts">
import FormGroup from '@/Components/FormGroup.vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { reactive, ref } from 'vue';
import type { DomainResource } from '@/types/types.gen';
import Select from 'primevue/select';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import DatePicker from 'primevue/datepicker';
import { subMonths } from 'date-fns';
import { useFormatters } from '@/Utils/Formatting';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const emits = defineEmits<{
  (e: 'hide'): void;
}>();

const state = reactive({
  expiry: 86400,
  dateRange: [subMonths(new Date(), 7), new Date()],
  domainReportUrl: null,
});

const isVisible = ref(true);

const generateExposureReportUrl = () => {
  const route = useRoute();
  const { formatDate } = useFormatters();

  axios
    .get(
      route('api.exposure-report.generate', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
        expiry: state.expiry,
        start_date: formatDate(state.dateRange[0]),
        end_date: formatDate(state.dateRange[1]),
      }),
    )
    .then((response) => {
      state.domainReportUrl = response.data.url;
    });
};

const toggleModel = () => isVisible.value = !isVisible.value;

</script>

<template>
  <div>
    <slot
      name="default"
      :toggle="toggleModel"
    />

    <Dialog
      v-model:visible="isVisible"
      modal
      @hide="emits('hide')"
      :header="$t('domains.report-modal.title')"
      class="w-2/6"
    >
      <div class="flex flex-col gap-7">
        <FormGroup
          v-if="!state.domainReportUrl"
          :label="$t('domains.report-modal.inputs.date_range')"
        >
          <template #element>
            <DatePicker
              selectionMode="range"
              showIcon
              v-model="state.dateRange"
              iconDisplay="input"
              hideOnRangeSelection
              pt:inputIconContainer:class="!-mt-3"
              dateFormat="yy-MM-dd"
              :fluid=true
            />
          </template>
        </FormGroup>

        <FormGroup
          v-if="!state.domainReportUrl"
          :label="$t('domains.report-modal.inputs.expire_link_in')"
        >
          <template #element>
            <Select
              class="w-1/3"
              v-model="state.expiry"
              :options="[
                { value: 21600, text: $t('domains.setup.options.6_hours') },
                { value: 43200, text: $t('domains.setup.options.12_hours') },
                { value: 86400, text: $t('domains.setup.options.1_day') },
                { value: 604800, text: $t('domains.setup.options.1_week') },
                { value: 2592000, text: $t('domains.setup.options.1_month') },
                { value: 16777215, text: $t('domains.setup.options.never') }
              ]"
              optionLabel="text"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <InputGroup v-if="state.domainReportUrl">
          <InputText :model-value="state.domainReportUrl" />
          <a :href="state.domainReportUrl" target="_blank">
            <Button icon="pi pi-external-link" severity="secondary" />
          </a>
        </InputGroup>
      </div>

      <template #footer>
        <div v-if="! state.domainReportUrl" class="flex flex-row-reverse items-center gap-2">
          <form @submit.prevent="generateExposureReportUrl">
            <Button :label="$t('domains.setup.action')" type="submit" />
          </form>
        </div>
      </template>
    </Dialog>
  </div>
</template>
