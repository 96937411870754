<script setup lang="ts">
import { ref, computed } from 'vue';

const { items, initialCount } = defineProps<{
  items: string[];
  initialCount: number;
}>();

const visibleCount = ref(initialCount);
const isShowingMore = ref(false);

const cleanedItems = items.filter(item => item);

const visibleItems = computed<string[]>(() => {
  return cleanedItems.slice(0, visibleCount.value);
});

const toggleShowMore = () => {
  visibleCount.value = isShowingMore.value
    ? initialCount
    : cleanedItems.length;
  isShowingMore.value = !isShowingMore.value;
};
</script>

<template>
  <div class="flex flex-wrap items-center" v-if="visibleItems.length">
    <div v-for="(item, index) in visibleItems" :key="index">
      <span v-if="index === visibleItems.length - 1">
        <span v-if="!isShowingMore && items.length > initialCount">...{{ item }}</span>
        <span v-else>{{ item }}</span>
      </span>
      <span v-else>{{ item }},</span>
    </div>
    <div
      v-if="items.length > initialCount"
      @click="toggleShowMore"
      class="ml-3 text-primary-default cursor-pointer"
      v-text="isShowingMore ? $t('global.show_less') : $t('global.show_more')"
    />
  </div>
</template>
