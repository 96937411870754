<script setup lang="ts">
import TabPanel from 'primevue/tabpanel';
import type { FailureReportResource } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useFormatters } from '@/Utils/Formatting';
import { parseISO } from 'date-fns';

const { report } = defineProps<{ report: FailureReportResource }>()

const { formatDate } = useFormatters();

const { translate } = useTranslate();

const keyProperties = [
  {
    value: formatDate(parseISO(report.arrival_date)),
    label: translate('failure-reports.key-properties.date'),
  }, {
    value: report.feedback_type,
    label: translate('failure-reports.key-properties.feedback_type'),
  }, {
    value: report.auth_failure,
    label: translate('failure-reports.key-properties.authentication_failure'),
  }, {
    value: report.delivery_result,
    label: translate('failure-reports.key-properties.delivery_results'),
  }, {
    value: report.source_ip,
    label: translate('failure-reports.key-properties.source_ip'),
  }, {
    value: report.incidents,
    label: translate('failure-reports.key-properties.incidents'),
  }, {
    value: report.original_mail_from,
    label: translate('failure-reports.key-properties.return_path'),
  }, {
    value: report.reporting_mta,
    label: translate('failure-reports.key-properties.reporting_mta'),
  }, {
    value: report.reported_domain,
    label: translate('failure-reports.key-properties.reporting_domains'),
  },
];

</script>

<template>
  <TabPanel value="0">
    <div class="flex flex-col mt-5 gap-2">
      <div v-for="(property, index) in keyProperties" class="flex" :key="index">
        <div class="w-3/6 font-semibold" v-text="property.label"/>
        <div v-text="property.value"/>
      </div>
    </div>
  </TabPanel>
</template>
