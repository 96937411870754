<script setup lang="ts">
import Button from 'primevue/button';
import { reactive } from 'vue';

defineProps<{
  rawRecord: string;
}>();

const state = reactive({
  isOpen: false,
});
</script>

<template>
  <div class="bg-section-background">
    <div >
      <Button
        :icon="state.isOpen ? 'pi pi-angle-up' : 'pi pi-angle-down'"
        iconPos="left"
        severity="secondary"
        text
        label="Use TXT Record Instead"
        @click="state.isOpen = !state.isOpen"
      />
    </div>
    <div v-if="state.isOpen" class="p-4">
      {{ rawRecord }}
    </div>
  </div>
</template>
