import type { SpfDirectiveResourceWithQualifier } from '@/Pages/Domains/SpfSettings/types';
import { SpfQualifier } from '@/Pages/Domains/SpfSettings/types';
import { SpfMechanism } from '@/types/types.gen';

export default {
  rawDirective(directive: SpfDirectiveResourceWithQualifier) {
    return (
      this.rawQualifier(directive)
      + directive.mechanism
      + this.rawValue(directive)
    );
  },

  rawQualifier(directive: SpfDirectiveResourceWithQualifier) {
    return directive.qualifier !== '+' ? directive.qualifier : '';
  },

  rawValue(directive: SpfDirectiveResourceWithQualifier) {
    return directive.value ? `:${directive.value}` : '';
  },

  humanDirective(directive: SpfDirectiveResourceWithQualifier) {
    const intro = this.getHumanDirectiveIntro(directive);

    switch (directive.mechanism) {
      case SpfMechanism.A:
      case SpfMechanism.MX:
        return intro + this.getHumanRecordsDirectiveBody(directive);
      case SpfMechanism.INCLUDE:
        return intro + this.getHumanIncludeDirectiveBody(directive);
      case SpfMechanism.IP4:
      case SpfMechanism.IP6:
        return intro + this.getHumanAddressDirectiveBody(directive);
      case SpfMechanism.EXISTS:
        return intro + this.getHumanExistsDirectiveBody(directive);
    }
  },

  getHumanDirectiveIntro(directive: SpfDirectiveResourceWithQualifier) {
    switch (directive.qualifier) {
      case SpfQualifier.FAIL:
        return 'Fail ';
      case SpfQualifier.SOFT_FAIL:
        return 'Soft Fail ';
      case SpfQualifier.NEUTRAL:
        return 'Be neutral on ';
      default:
        return '';
    }
  },

  getHumanRecordsDirectiveBody(directive: SpfDirectiveResourceWithQualifier) {
    let body = this.isAllowQualifier(directive) ? 'Allow ' : '';

    body += `${directive.mechanism.toUpperCase()} records for `;

    if (directive.value) {
      return body + directive.value;
    }
    return `${body}your own domain`;
  },

  getHumanIncludeDirectiveBody(directive: SpfDirectiveResourceWithQualifier) {
    const body = this.isAllowQualifier(directive) ? 'Include ' : 'including ';

    return body + directive.value?.toLowerCase();
  },

  getHumanAddressDirectiveBody(directive: SpfDirectiveResourceWithQualifier) {
    let body = this.isAllowQualifier(directive) ? 'Include ' : '';

    body += directive.mechanism === 'ip6' ? 'the IPv6 ' : 'the IPv4 ';
    body += directive.value?.search('/') !== -1 ? 'addresses in ' : 'address ';

    return body + directive.value;
  },

  getHumanExistsDirectiveBody(directive: SpfDirectiveResourceWithQualifier) {
    const body = this.isAllowQualifier(directive) ? 'Allow ' : '';

    return `${body}the exists macro ${directive.value}`;
  },

  uniqueDirectiveKey(directive: SpfDirectiveResourceWithQualifier, defaultValue: string) {
    return `${directive.mechanism}:${directive.value ? directive.value : defaultValue}`;
  },

  isAllowQualifier(directive: SpfDirectiveResourceWithQualifier) {
    return directive.qualifier === '+';
  },

  isMxMechanism(directive: SpfDirectiveResourceWithQualifier) {
    return directive.mechanism === 'mx';
  },

  isAMechanism(directive: SpfDirectiveResourceWithQualifier) {
    return directive.mechanism === 'a';
  },
};
