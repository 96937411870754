<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import type { BarChartSize } from '@/Components/types/types';
import Chart from '@/Components/Charts/Chart.vue';

const { size } = defineProps<{
  size?: BarChartSize;
  data: ChartData<'bar'>;
}>();

const classes = {
  medium: 'h-32',
}[size || 'medium'];

const chartOptions: ChartOptions<'bar'> = {
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    legend: {
      display: false,
    },
  },

  clip: false,

  scales: {
    x: {
      stacked: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};
</script>

<template>
  <Chart type="bar" :class="classes" :data="data" :options="chartOptions"  />
</template>
