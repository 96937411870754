<script setup lang="ts">
import ChartCard from './ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import DeliverabilityChart from '@/Pages/Statistics/Sender/Charts/DeliverabilityChart.vue';

const { data } = defineProps<{ data: StatisticsTotals[] }>();

const { formatNumber, formatPercentage } = useFormatters();

const totalAmount = sumBy(data, 'total_incidents');
const deliveredAmount = sumBy(data, 'total_delivered');
</script>
<template>
  <ChartCard
    :title="$t('senders.listing.cards.deliverability')"
  >
    <template #lead>
      <h6 class="mt-2 text-2xl">{{ formatNumber(totalAmount - deliveredAmount) }}</h6>
      <span class="text-lg">
        / {{ formatNumber(totalAmount)}}
      </span>
    </template>
    <template #graph>
      <DeliverabilityChart :data="data">
        <template #default>
          <div class="absolute top-1/2 w-full text-center">
            <h6 class="-mt-6 font-bold">
              {{ formatPercentage(100 - ((deliveredAmount / totalAmount) * 100)) }}
            </h6>
            <small class="mt-0">
              {{ $t('senders.listing.cards.deliverability') }}
            </small>
          </div>
        </template>
      </DeliverabilityChart>
    </template>
  </ChartCard>
</template>
