<script setup lang="ts">
import type {
  DomainResource,
  DomainSenderStatisticsResource,
  PartnerResource,
  TimelineStatisticsResource,
} from '@/types/types.gen';
import type { DomainScore } from '@/types/cyclops.types.gen';
import ExposureReportLayout from '@/Pages/Domains/Exposure/ExposureReportLayout.vue';
import ScoreCard from '@/Pages/Domains/Score/ScoreCard.vue';
import ExposureVolumeCard from '@/Pages/Domains/Exposure/Partials/ExposureVolumeCard.vue';
import type { DateRange, SenderComplianceStatistics } from '@/Pages/Statistics/Sender/types';
import ComplianceCard from '@/Pages/Statistics/Sender/Partials/ChartCards/ComplianceCard.vue';
import { createStatisticsTotals } from '@/Pages/Statistics/StatsHelpers';
import DeliverabilityCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverabilityCard.vue';
import DeliverableVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverableVolumeCard.vue';
import DomainSendersCard from '@/Pages/Statistics/Dashboard/Partials/SendersComplianceCard.vue';
import SenderComplianceCard from '@/Pages/Domains/Exposure/Partials/SenderComplianceCard.vue';

const { dailyVolume } = defineProps<{
  dateRange: DateRange;
  domain: DomainResource;
  domainSenders: DomainSenderStatisticsResource[];
  partner: PartnerResource;
  cyclopsScore: DomainScore;
  dailyVolume: TimelineStatisticsResource[];
  senderComplianceStatistics: SenderComplianceStatistics;
}>()

const totals = dailyVolume
  .map(dailyVolume => createStatisticsTotals(dailyVolume));

</script>

<template>
  <ExposureReportLayout :partner="partner" :domain="domain">
    <div class="flex gap-8">
      <div class="min-w-80 max-w-96">
        <ScoreCard
          class="w-full"
          :score="cyclopsScore"
        />
      </div>

      <div class="w-full flex flex-col gap-8">
        <ExposureVolumeCard
          :date-range="dateRange"
          :volume="dailyVolume"
        />

        <div class="flex gap-8">
          <ComplianceCard
            :data="totals"
          />

          <DeliverabilityCard
            :data="totals"
          />

          <DeliverableVolumeCard
            :timeline-statistics="dailyVolume"
          />
        </div>

        <DomainSendersCard
          :title="$t('exposure-report.senders.title')"
          :statistics="domainSenders"
        />

        <SenderComplianceCard
          :data="senderComplianceStatistics"
        />
      </div>
    </div>
  </ExposureReportLayout>
</template>
