<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';

const { data } = defineProps<{
  data: { approved: number; other: number };
}>();

const approvedPercentage = Math.round((data.approved / (data.approved + data.other)) * 100);

const { translate } = useTranslate();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('dashboard.sender-totals.approved'),
    translate('dashboard.sender-totals.unapproved'),
  ],
  datasets: [
    {
      data: [
        data.approved,
        data.other,
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
      ],
      borderWidth: 0,
    },
  ],
};
</script>

<template>
  <DoughnutChart :data="chartData" >
    <div
      class="font-bold"
      v-text="`${approvedPercentage}%`"
    />
    <div
      class="text-sm"
      v-text="$t('dashboard.sender-totals.approved')"
    />
  </DoughnutChart>
</template>
