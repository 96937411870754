<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { sumBy } from 'lodash';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';

const { data } = defineProps<{ data: StatisticsTotals[] }>();
const { translate } = useTranslate();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('senders.listing.labels.delivered'),
    translate('senders.listing.labels.overridden_as_delivered'),
    translate('senders.listing.labels.overridden_as_not_delivered'),
    translate('senders.listing.labels.failure'),
  ],
  datasets: [
    {
      data: [
        sumBy(data, 'total_delivered_forward'),
        sumBy(data, 'total_permitted'),
        sumBy(data, 'total_blocked'),
        sumBy(data, 'total_complete_failures'),
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-color1'),
        getCssVariable('--p-graphs-warning'),
        getCssVariable('--p-graphs-negative'),
      ],
      borderWidth: 0,
    },
  ],
};

</script>
<template>
  <DoughnutChart :data="chartData">
    <slot />
  </DoughnutChart>
</template>
