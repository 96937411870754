<script setup lang="ts">
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { reactive } from 'vue';
import FormGroup from '@/Components/FormGroup.vue';
import DeleteConfirmButton from '@/Components/DeleteConfirmButton.vue';

const props = defineProps<{
  items: string[];
  validate: (value: string) => boolean;
  placeholder: string;
  validationMessage: string;
  editLabel: string;
}>();

const state = reactive({
  editingIndex: -1,
  invalid: false,
  value: '',
  editingOriginalValue: '',
  items: props.items,
});

const emits = defineEmits<{
  (e: 'change', items: string[]): void;
}>()

const removeItem = (index: number) => {
  state.items.splice(index, 1);
  state.editingIndex = -1;
  emits('change', state.items);
};

const editItem = (value: string) => {
  state.invalid = false;

  if (!props.validate(value)) {
    state.invalid = true;
    return;
  }

  state.editingIndex = -1;
  emits('change', state.items);
};

const submit = () => {
  state.invalid = false;

  if (!props.validate(state.value)) {
    state.invalid = true;
    return;
  }

  state.items.push(state.value);
  emits('change', state.items);
  state.value = '';
};

const cancel = () => {
  state.invalid = false;
  state.items[state.editingIndex] = state.editingOriginalValue;
  state.editingIndex = -1;
  state.items = props.items;
}
</script>

<template>
  <section>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-1">
        <InputGroup>
          <InputText
            v-model.trim="state.value"
            :placeholder="placeholder"
          />
          <Button
             icon="pi pi-plus"
            :label="$t('global.add')"
            severity="primary"
            type="submit"
          />
        </InputGroup>
        <div
          v-if="state.invalid && state.editingIndex === -1"
          class="text-sm text-danger-default"
        >
          {{ validationMessage }}
        </div>
      </div>
    </form>
    <ul class="mt-3 flex flex-col gap-1">
      <li v-for="(item, index) in items"
        :key="index"
        class="group bg-section-default px-3"
      >
        <div class="flex items-center justify-between">
          <div v-if="state.editingIndex !== index">
            {{ item }}
          </div>
          <form
            v-else
            @submit.prevent="editItem(items[index])"
            class="w-full"
          >
            <div class="flex flex-col gap-1 m-5">
              <FormGroup :label="editLabel">
                <template #element>
                  <InputText
                    v-model.trim="state.items[index]"
                    :placeholder="placeholder"
                  />
                  <div
                    v-if="state.invalid"
                    class="text-sm text-danger-default"
                    v-text="validationMessage"
                  />
                  <div v-else
                       class="text-sm"
                       v-text="'E.g example.com'"
                  />
                  <div class="mt-5 flex justify-between">
                    <DeleteConfirmButton
                      size="small"
                      @delete="removeItem"
                    />
                    <div class="flex gap-4">
                      <Button
                        size="small"
                        class="mr-1"
                        text
                        severity="secondary"
                        :label="$t('global.cancel')"
                        @click="cancel"
                      />
                      <Button
                        type="submit"
                        size="small"
                        class="mr-1"
                        severity="secondary"
                        :label="$t('global.save')"
                      />
                    </div>
                  </div>
                </template>
              </FormGroup>
            </div>
          </form>
          <div
            v-if="state.editingIndex !== index"
            class="flex gap-4 opacity-0 transition-all duration-300 group-hover:opacity-100"
          >
            <Button
              @click="
                state.editingIndex = index;
                state.editingOriginalValue = items[index];
                state.invalid = false;
              "
              icon="pi pi-pencil"
              severity="contrast"
              text
              rounded
            />
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>
