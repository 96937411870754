<script setup lang="ts">
import { type AlertsResource } from '@/types/types.gen';
import { inject } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import RadioButton from 'primevue/radiobutton';
import DatePicker from 'primevue/datepicker';
import Button from 'primevue/button';
import { useFormatters } from '@/Utils/Formatting';

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;
const formatter = useFormatters();

const dateOptions = [
  'monthly',
  'two_monthly',
  'quarterly',
  'six_monthly',
  'Yearly',
]

const nextStep = () => {
  emits('completeStep', 'frequency');
};

</script>
<template>
  <div>
    <div>
      <div class="font-medium mb-4">{{ $t('alerts.edit.frequency.heading') }}</div>
      <DatePicker v-model="form.start_at" />

      <div class="mt-5">
        <div v-for="option in dateOptions" :key="option" class="flex items-center mb-2">
          <RadioButton v-model="form.frequency" :inputId="'id_' + option" name="dynamic" :value="option" />
          <label :for="'id_' + option" class="ml-2">{{ formatter.titleCase(option) }}</label>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="flex justify-end mr-5">
        <Button @click="nextStep()" severity="secondary" :label="$t('alerts.edit.buttons.next')" size="small" raised />
      </div>
    </div>

  </div>
</template>
