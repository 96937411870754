<script setup lang="ts">
import { type AlertsResource, type RecipientResource } from '@/types/types.gen';
import { computed, inject } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import EmailsListBox from '@/Components/EmailsListBox.vue';

const form: InertiaForm<AlertsResource> = inject('form')!;

const emailRecipients = computed(() => {
  return form.channels.mail.recipients.map(recipient => recipient.email);
});

const setRecipient = function (emails: string[]) {
  let channelRecipients: RecipientResource[] = [];
  emails.forEach(function (email: string) {
    channelRecipients.push({
      email: email,
      is_subscribed: true,
    })
  })
  form.channels.mail.recipients = channelRecipients;
};

</script>
<template>
  <div>
    <div class="font-medium mb-4">{{ $t('alerts.edit.email.heading') }}</div>

    <div>
      <FormGroup
        :label="$t('alerts.edit.email.recipients')"
      >
        <template #element>
          <EmailsListBox @change="setRecipient" class="w-1/3" :emails="emailRecipients" />
        </template>
      </FormGroup>
    </div>

    <div class="font-medium my-5">{{ $t('alerts.edit.email.customize') }}</div>

    <div class="mt-4">
      <div>
        <FormGroup :label="$t('alerts.edit.email.subject-tags')">
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.channels.mail.settings.enable_subject_tags"
              v-bind="slotProps"
              :allow-empty="false"
              :options="[
                { label: $t('global.off'), value: false },
                { label: $t('global.on') , value: true },
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>
      </div>
    </div>

  </div>
</template>
