<script setup lang="ts">
import type { AlertsResource, CustomerResource } from '@/types/types.gen';
import AppLayout from '@/Layouts/AppLayout.vue';
import { type InertiaForm, useForm } from '@inertiajs/vue3';

import { provide } from 'vue';
import AlertForm from '@/Pages/Alerts/Partials/AlertForm.vue';
import { useRoute } from 'ziggy-js';
import responseCallbacks from '@/Utils/ResponseCallbacks';

const { alert, customer } = defineProps<{
  alert: AlertsResource;
  customer: CustomerResource;
}>();

const form: InertiaForm<AlertsResource> = useForm(alert);
provide('form', form);

const submit = () => {
  const route = useRoute();

  form.put(
    route('ui.alerts.update', {
      customer: customer.uuid,
      alert: alert.uuid,
    }),
    responseCallbacks,
  );
};
</script>

<template>
  <AppLayout title="Alerts">
    <AlertForm @submit="submit" :customer="customer" :alert="alert"/>
  </AppLayout>
</template>
