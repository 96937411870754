<script setup lang="ts">
import { SenderCategory, type SenderTotalsResource } from '@/types/types.gen';
import Card from 'primevue/card';
import { Link, usePage } from '@inertiajs/vue3';
import SenderTotalsChart from '@/Pages/Statistics/Dashboard/Charts/SenderTotalsChart.vue';

const { totals } = defineProps<{
  totals: SenderTotalsResource[];
}>();

const { activeDomain } = usePage().props;

const aggregates = totals.reduce((acc, item) => {
  const key = item.category === SenderCategory.AUTHORISED_COMPLIANT
    ? 'approved'
    : 'other';

  acc[key] += item.total;
  return acc;
}, {
  approved: 0,
  other: 0,
});
</script>

<template>
  <Card class="w-full">
    <template #content>
      <div class="flex justify-between">
        <div class="flex-col">
          <div
            class="text-xl font-semibold mb-1.5"
            v-text="$t('dashboard.sender-totals.header')"
          />

          <div class="text-2xl">
            {{ `${aggregates.approved} ${$t('dashboard.sender-totals.approved')}` }}
          </div>
          <div class="mb-1.5 mt-0.5">
            {{ `${aggregates.other} ${$t('dashboard.sender-totals.unapproved')}` }}
          </div>

          <Link
            v-if="activeDomain?.uuid"
            :href="route('ui.senders-statistics.index', [ activeDomain.customer_uuid, activeDomain.uuid])"
            class="text-primary-default text-lg"
          >
            {{ $t('dashboard.sender-totals.action')}}
          </Link>
        </div>
        <SenderTotalsChart
          :data="aggregates"
        />
      </div>
    </template>
  </Card>
</template>
