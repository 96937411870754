import type { VisitOptions } from '@inertiajs/core';
// @ts-expect-error Genuinely cannot get TS to recognise the declaration in global.d.ts
import toasteventbus from 'primevue/toasteventbus';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const ResponseCallbacks: VisitOptions = {
  onSuccess: () => {
    const { translate } = useTranslate();
    toasteventbus.emit('add', {
      severity: 'success',
      summary: translate('global.form.success.title'),
      life: 5000,
    });
  },
  onError: () => {
    const { translate } = useTranslate();
    toasteventbus.emit('add', {
      severity: 'error',
      summary: translate('global.form.failed.title'),
      detail: translate('global.form.failed.description'),
      life: 5000,
    });
  },
};

export default ResponseCallbacks;
