<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import type { DoughnutChartSize } from '@/Components/types/types';

const { data } = defineProps<{
  data: { total_incidents: number; total_complete_failures: number };
  size: DoughnutChartSize;
}>();
const { translate } = useTranslate();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('dashboard.domains.legend.compliant'),
    translate('dashboard.domains.legend.failures'),
  ],
  datasets: [
    {
      data: [data.total_incidents, data.total_complete_failures],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
      ],
      borderWidth: 0,
    },
  ],
};

const compliance = data.total_incidents
  ? Math.round((1 - data.total_complete_failures / data.total_incidents) * 100)
  : 0;

</script>
<template>
  <DoughnutChart :size="size" :data="chartData">
    <span v-if="compliance">{{ compliance }}%</span>
  </DoughnutChart>
</template>
