<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="149"
    height="40"
    viewBox="0 0 149 40"
    fill="none"
  >
    <path
      d="M3.65848 12.8641C4.66457 12.2575 7.59617 10.7316 11.5772 10.7316C14.1718 10.7316 16.3188 11.3814 17.7773 11.9976L21.5032 9.62439C19.5488 8.63756 16.0251 7.26562 11.5772 7.26562C5.04486 7.26562 0.495821 10.2357 0 10.5727C0.00481379 14.7126 0.722068 18.6021 2.08437 21.9862L4.94377 20.1666C4.13986 17.9667 3.70181 15.4924 3.65367 12.8641H3.65848Z"
      fill="#0073EA"
    ></path>
    <path
      d="M19.2228 16.1559C18.8618 18.5002 18.1638 20.6567 17.1481 22.5245C15.7761 25.0469 13.8554 27.011 11.5785 28.2288C9.76371 27.2613 8.17516 25.8123 6.91395 23.9927L4.04492 25.8171C5.99451 28.8787 8.5747 31.2567 11.5785 32.7346C15.0059 31.0449 17.8846 28.1903 19.9016 24.4789C21.5816 21.3836 22.6358 17.7203 23.0065 13.7441L19.2276 16.151L19.2228 16.1559Z"
      fill="#041221"
    ></path>
    <path
      d="M31.084 23.9297L31.8686 23.1162C32.3596 24.3052 33.7797 25.2198 35.5753 25.2198C37.1397 25.2198 38.478 24.5651 38.478 22.967C38.4924 21.2869 36.9279 20.6997 35.3298 20.1124C33.6497 19.477 31.8879 18.8415 31.8879 16.8631C31.8879 14.7257 33.6353 13.7148 35.5608 13.7148C37.289 13.7148 38.6272 14.4177 39.3974 15.313L38.6465 16.0784C38.0929 15.2938 37.0483 14.6728 35.5608 14.6728C34.237 14.6728 33.0143 15.3082 33.0143 16.7764C33.0143 18.1628 34.367 18.7019 35.8545 19.2555C37.6356 19.9391 39.6092 20.6612 39.6092 22.9092C39.6092 25.1572 37.8137 26.2692 35.5608 26.2692C33.539 26.2692 31.8398 25.2583 31.0888 23.9201L31.084 23.9297Z"
      fill="#041221"
    ></path>
    <path
      d="M46.9455 14.8557V19.4914H51.7449V20.4878H46.9455V25.1235H52.4284V26.12H45.8335V13.8785H52.4284V14.8557H46.9455Z"
      fill="#041221"
    ></path>
    <path
      d="M68.6172 13.8785V26.12H67.6208L59.8513 15.7414V26.12H58.7393V13.8785H59.7358L67.5197 24.257V13.8785H68.6172Z"
      fill="#041221"
    ></path>
    <path
      d="M75.3131 13.8785H78.5624C81.9417 13.8785 84.6807 16.6175 84.6807 19.9968C84.6807 23.3761 81.9369 26.1152 78.5624 26.1152H75.3131V13.8737V13.8785ZM78.5431 25.1235C81.3496 25.1235 83.5687 22.8562 83.5687 20.0016C83.5687 17.1471 81.3496 14.8605 78.5431 14.8605H76.4058V25.1235H78.5431Z"
      fill="#041221"
    ></path>
    <path
      d="M102.891 13.8785V26.12H101.799V15.5296L96.9029 22.8562L92.041 15.5296V26.12H90.929V13.8785H92.0891L96.9029 21.0414L101.75 13.8785H102.891Z"
      fill="#041221"
    ></path>
    <path
      d="M117.468 23.458H110.892L109.862 26.12H108.654L113.564 13.8785H114.786L119.696 26.12H118.488L117.458 23.458H117.468ZM117.092 22.5096L114.17 15.0338L111.267 22.5096H117.092Z"
      fill="#041221"
    ></path>
    <path
      d="M133.363 26.1152H132.121L129.102 20.536H126.57V26.1152H125.458V13.8737H129.719C131.577 13.8737 133.079 15.3756 133.079 17.253C133.079 18.9185 131.837 20.2712 130.224 20.483L133.358 26.1152H133.363ZM129.69 19.6021C131.013 19.6021 132.072 18.5431 132.072 17.2193C132.072 15.8955 130.994 14.8509 129.69 14.8509H126.57V19.6021H129.69Z"
      fill="#041221"
    ></path>
    <path
      d="M138.504 19.9968C138.504 16.5213 141.002 13.7148 144.478 13.7148C145.816 13.7148 147.039 14.1866 148.001 14.9857V16.3384C147.005 15.2793 145.816 14.7546 144.478 14.7546C141.575 14.7546 139.548 17.0701 139.548 19.992C139.548 22.914 141.57 25.215 144.478 25.215C145.816 25.215 147.039 24.6903 148.001 23.6457V24.9839C147.039 25.783 145.816 26.274 144.478 26.274C141.002 26.274 138.504 23.4676 138.504 19.992V19.9968Z"
      fill="#041221"
    ></path>
  </svg>
</template>
