<script setup lang="ts">
import type {
  DomainResource,
  MtaStsSettingResource, MtaStsSetupInstructionsResource,
  TlsRptSettingResource, TlsRptSetupInstructionsResource,
} from '@/types/types.gen';
import DomainSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import EnableMtaSettings from '@/Pages/Domains/TlsRptSettings/Partials/EnableMtaSettings.vue';
import EnableTlsRptSettings from '@/Pages/Domains/TlsRptSettings/Partials/EnableTlsRptSettings.vue';
import DisableMtaTlsSettings from '@/Pages/Domains/TlsRptSettings/Partials/DisableMtaTlsSettings.vue';
import TlsRptSettingsForm from '@/Pages/Domains/TlsRptSettings/Partials/TlsRptSettingsForm.vue';
import SaveAndExit from '@/Components/SaveAndExit.vue';
import { provide, reactive } from 'vue';
import MtaStsRecordForm from '@/Pages/Domains/TlsRptSettings/Partials/MtaStsRecordForm.vue';
import axios from 'axios';
import { useToast } from 'primevue/usetoast';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useRoute } from 'ziggy-js';
import MtaStsPolicyForm from '@/Pages/Domains/TlsRptSettings/Partials/MtaStsPolicyForm.vue';
import { router } from '@inertiajs/vue3';
import type { SaveOrExitAction } from '@/Pages/Domains/types';

const { domain, mtaStsSettings, tlsRptSettings } = defineProps<{
  domain: DomainResource;
  mtaStsSettings: MtaStsSettingResource;
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
  tlsRptSettings: TlsRptSettingResource;
  tlsRptSetupInstruction: TlsRptSetupInstructionsResource;
}>();

const formState = reactive({
  mtaStsSettings,
  tlsRptSettings,
});

provide('formState', formState);

const { translate } = useTranslate();
const toast = useToast();
const route = useRoute();

const submit = async (action: SaveOrExitAction) => {
  try {
    await Promise.all([
      axios.put(route('api.mta-settings.update', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
      }), formState.mtaStsSettings),

      axios.put(route('api.tls-settings.update', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
      }), formState.tlsRptSettings),
    ]);

    toast.add({ severity: 'success', summary: translate('global.form.success.title'), life: 5000 });

    if (action === 'save_exit') {
      router.visit(useRoute()('ui.domains.index', [domain.customer_uuid]));
    }
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: translate('global.form.failed.title'),
      detail: translate('global.form.failed.description'),
      life: 5000,
    })
  }
};
</script>

<template>
  <DomainSettingsLayout
    :title="$t('dmarc.title')"
    :domain="domain"
    :tab="DomainSettingsTab.TLS"
  >
    <div class="mt-10 flex flex-col gap-2">
      <TlsRptSettingsForm
        v-if="tlsRptSettings.enable_tls_rpt"
        :tls-rpt-setup-instruction="tlsRptSetupInstruction"
        :domain="domain"
      />
      <EnableTlsRptSettings
        v-else
        :domain="domain"
      />
    </div>
    <div class="mt-10 flex flex-col gap-2">
      <MtaStsRecordForm
        v-if="mtaStsSettings.is_record_enabled"
        :mta-sts-setup-instruction="mtaStsSetupInstruction"
        :domain="domain"
      />
      <EnableMtaSettings
        v-else
        :domain="domain"
      />
      <MtaStsPolicyForm
        v-if="mtaStsSettings.is_record_enabled"
        :mta-sts-setup-instruction="mtaStsSetupInstruction"
        :domain="domain"
      />
    </div>

    <DisableMtaTlsSettings
      class="mt-10"
      v-if="tlsRptSettings.enable_tls_rpt || mtaStsSettings.is_record_enabled"
      :domain="domain"
      :tls-rpt-settings="tlsRptSettings"
      :mta-sts-settings="mtaStsSettings"
    />

  <div v-if="!tlsRptSettings.enable_tls_rpt && !mtaStsSettings.is_record_enabled">
      <div
        class="mt-8 text-lg font-bold"
        v-text="$t('mta.info.title')"
      />
      <div
        class="mt-2"
        v-text="$t('mta.info.description')"
      />
  </div>

    <template #footer>
      <SaveAndExit
        :cancel-route="route('ui.domains.index', [domain.customer_uuid])"
        @submit="submit"
      />
    </template>
  </DomainSettingsLayout>
</template>
