<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Column from 'primevue/column';
import { Link } from '@inertiajs/vue3';
import Tag from 'primevue/tag';
import type { PaginatedResponse } from '@/types/http';
import type {
  CustomerResource,
  DomainResource,
} from '@/types/types.gen';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';
import DomainsSettingsMenu from '@/Pages/Domains/Partials/DomainsSettingsMenu.vue';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import InputIcon from 'primevue/inputicon';
import IconField from 'primevue/iconfield';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from '@primevue/core/api';
import { reactive } from 'vue';
import { route } from 'ziggy-js';
import LogsDrawer from '@/Pages/Logs/LogsDrawer.vue';
import Breadcrumb from 'primevue/breadcrumb';
import ProtectionScoreBadge from '@/Pages/Domains/Partials/ProtectionScoreBadge.vue';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';

defineProps<{
  customer: CustomerResource;
  domains: PaginatedResponse<DomainResource>;
}>();

const state = reactive({
  logs: {
    visible: false,
    path: '',
  },
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const openLogsDrawer = (domain: DomainResource) => {
  state.logs = {
    visible: true,
    path: route('api.logs.domain', [
      domain.customer_uuid,
      domain.uuid,
    ]),
  };
}
</script>

<template>
  <AppLayout :title="$t('domains.menu')">
    <Breadcrumb
      :home="{
        icon: 'pi pi-home'
      }"
      :model="[{ label: $t('domains.menu') }]"
    />
    <LogsDrawer
      @hide="state.logs.visible = false"
      v-if="state.logs.visible"
      :path="state.logs.path"
      :header="$t('logs.drawer.header', {
        type: $t('domains.input')
      })"
    />
    <Card class="w-full">
      <template #content>
        <DataTable
          v-model:filters="state.filters"
          :value="domains.data"
          :global-filter-fields="['domain_name']"
          data-key="uuid"
          paginator
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :sort-order=1
          sort-field="domain_name"
        >
          <template #empty>{{ $t('tables.not_found') }}</template>
          <template #header>
            <div class="flex justify-between">
              <IconField>
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  :placeholder="$t('tables.search')"
                  v-model="state.filters.global.value"
                />
              </IconField>
              <Link :href="route('ui.domains.create', [customer.uuid])" preserve-state>
                <Button :label="$t('global.add_new')" icon="pi pi-plus" />
              </Link>
            </div>
          </template>
          <Column
            sortable
            field="domain_name"
            :header="$t('users.name.title')"
            filterField="name"
            headerClass="w-3/6"
          >
            <template #body="{ data }">
              <div class="flex items-center">
                <DomainAvatar :domain="data" />
                <div class="ml-4 font-medium leading-6 text-color">
                  {{ data.domain_name }}
                </div>
              </div>
            </template>
          </Column>

          <Column :header="$t('domains.score.title')" :sortable="true" field="score">
            <template #body="{ data }">
              <ProtectionScoreBadge
                :score="data.score"
              />
            </template>
          </Column>

          <Column :header="$t('domains.type.title')" sortable field="type">
            <template #body="{ data }">
              <Tag
                class="capitalize"
                :value="data.type"
                :severity="data.type === 'parked' ? 'secondary' : 'success'"
              />
            </template>
          </Column>

          <Column
            :header="$t('dmarc.policy.title')"
            field="policy"
            sortable
          >
            <template #body="{ data }">
              <DmarcPolicyTag
                :policy="data.policy"
              />
            </template>
          </Column>

          <Column>
            <template #body="{ data }">
              <div class="flex flex-row-reverse items-center">
                <DomainsSettingsMenu
                  @show-logs="openLogsDrawer"
                  :key="data"
                  :is-text="true"
                  severity="contrast"
                  :domain="data"
                />
                <Link
                  :href="
                  route('ui.dmarc-settings.edit', {
                    customer: data.customer_uuid,
                    domain: data.uuid
                  })
                "
                >
                  <Button
                    text
                    v-tooltip.left="$t('domains.settings')"
                    icon="pi pi-pencil"
                  />
                </Link>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </AppLayout>
</template>
