<script setup lang="ts">
import Tag from 'primevue/tag';
import type { DmarcPolicy } from '@/types/types.gen';

const { policy } = defineProps<{
  policy: DmarcPolicy;
}>();

const severity = {
  none: 'danger',
  quarantine: 'warn',
  reject: 'success',
}[policy];

</script>

<template>
  <Tag
    class="capitalize"
    :value="policy"
    :severity="severity"
  />
</template>
