<script setup lang="ts">

const { score } = defineProps<{
  score: number;
}>();

const classes = ((score: number) => {
  if (score >= 80) {
    return 'bg-success-background text-success-foreground';
  } else if (score >= 50) {
    return 'bg-warn-background text-warn-foreground';
  } else {
    return 'bg-danger-background text-danger-foreground';
  }
})(score);

</script>

<template>
  <div
    class="rounded-full w-8 h-8 flex justify-center items-center text-sm font-medium"
    :class="classes"
  >
    <div v-text="score" />
  </div>
</template>
