<script setup lang="ts">
import ShowRawRecordButton from '@/Pages/Domains/DmarcSettings/Partials/ShowRawRecordButton.vue';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import type {
  CnameResource,
  SpfSetupInstructionsResource,
} from '@/types/types.gen';
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';

defineProps<{
  dnsRecord: CnameResource | null;
  setupInstructions: SpfSetupInstructionsResource;
}>();

const rdType = 'SPF';
</script>

<template>
  <SetupInstructionsCard
    :title="rdType"
    :description="$t('configurations.spf.description')"
  >
    <div v-if="dnsRecord">
      <div class="mb-1 mt-6 font-bold">
        {{ $t('configurations.instructions.remove', { rdType }) }}
      </div>
      <SetupInstructions
        host="@"
        :rd-type="dnsRecord.rdtype!"
        :value="dnsRecord.entries[0]"
        :show-copy-button="false"
      />
    </div>

    <div class="mb-1 mt-6 font-bold">
      <div v-if="dnsRecord">
        {{ $t('configurations.instructions.new', { rdType }) }}
      </div>
      <div v-else>
        {{ $t('configurations.instructions.add', { rdType }) }}
      </div>
    </div>
    <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
      <SetupInstructions
        host="@"
        rd-type="TXT"
        :value="`v=spf1 redirect=${setupInstructions.spf_host}`"
        show-copy-button
      />
      <ShowRawRecordButton :raw-record="setupInstructions.spf_raw_record" />
    </div>
    <div class="mt-2 text-sm">
      {{ $t('configurations.dmarc.warning') }}
    </div>

    <template #footer>
      <slot name="footer" />
    </template>
  </SetupInstructionsCard>
</template>
